import {
  Modal,
  ModalContent,
  FloraButton,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalTrigger,
} from '@grupoboticario/flora-react';

type ModalProps = {
  trigger: any;
  submit: any;
  submitLabel: string;
  onCancel?: any;
  header: string;
  body: any;
  size?: 'small' | 'standard' | 'large';
  submitDisable?: boolean;
  darkButton?: 'submit' | 'cancel';
};

export default function CreateModal({
  trigger,
  submit,
  submitLabel,
  onCancel,
  header,
  body,
  size,
  submitDisable,
  darkButton,
}: Readonly<ModalProps>) {
  return (
    <Modal>
      <ModalTrigger>{trigger}</ModalTrigger>
      <ModalContent size={size ?? 'small'}>
        <ModalCloseButton />
        <ModalHeader>{header}</ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <ModalCloseButton asChild>
            <FloraButton size="small" surfaceColor={darkButton === 'cancel' ? undefined : 'dark'} onClick={onCancel}>
              Cancelar
            </FloraButton>
          </ModalCloseButton>
          <ModalCloseButton asChild>
            <FloraButton
              size="small"
              surfaceColor={darkButton === 'submit' ? undefined : 'dark'}
              onClick={submit}
              disabled={submitDisable}
            >
              {submitLabel}
            </FloraButton>
          </ModalCloseButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
