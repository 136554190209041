import { useAssistantMailingStore } from '@/store/assistant';
import {
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  FloraInput,
  FloraButton as Button,
  Box,
  FloraTypography,
} from '@grupoboticario/flora-react';
import { ChangeEvent, useEffect, useState } from 'react';
import { SelectDropdown, useEvents } from '@/shared';
import {
  operationOptions,
  recadastroOptions,
  riskAnalysisOptions,
  situationOptions,
} from './constants';
import { removeNonNumericAndSemicolonChars } from '@/utils';

const filterInputValue = (id: string, filterValue: string[], stripNonNumeric = false): string => {
  const filteredValues =
    !!filterValue &&
    filterValue
      .flatMap((inputValue) => (inputValue.startsWith(`${id}:`) ? [inputValue.split(':')[1]] : []))
      .join(';');

  return stripNonNumeric ? removeNonNumericAndSemicolonChars(filteredValues) : filteredValues;
};

const singleSelectValue = (idFilter: string, filterValue: string[]) =>
  filterValue?.filter((value) => value.startsWith(idFilter));

const boxInputCssProps = {
  '& label': {
    paddingTop: '$3',
    fontWeight: '',
  },
  '& span': {
    fontSize: '$auxiliar',
  },
};

const inputCssProps = { height: '$14', fontSize: '$auxiliar' };

export const AssistantFilterDrawer = () => {
  const events = useEvents();
  const { assistantFilterOpen, setAssistantFilterOpen, tableMailing, setAssistantFilter } =
    useAssistantMailingStore((state) => state);
  const globalFilter = tableMailing.tableState.getState()?.globalFilter;
  const [filterValue, setFilterValue] = useState<string[]>(globalFilter || []);

  useEffect(() => {
    setFilterValue(globalFilter);
  }, [globalFilter]);

  const hasFilter = filterValue?.length > 0;
  const hasFilterGlobal = globalFilter?.length > 0;

  const handleSelectValue = (event: ChangeEvent<HTMLInputElement>, idFilter?: string) => {
    const { value } = event.target;
    if (Array.isArray(value)) {
      return setFilterValue([value].flat());
    }
    return setFilterValue((prev) => {
      const updatedFilter = prev.filter((updatedValue) => !updatedValue.startsWith(idFilter));
      return [...updatedFilter, value];
    });
  };

  const onInputTextChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = evt.target;
    const formattedValue = value.split(';').map((part) => `${id}:${part}`);
    setFilterValue((prev) => {
      const updatedFilter = prev.filter((updatedValue) => !updatedValue.startsWith(`${id}:`));
      return [...updatedFilter, ...formattedValue];
    });
  };

  const cleanFilters = () => {
    setAssistantFilter([]);
    setFilterValue([]);
  };

  const submitFilter = () => {
    const formattedFilter = filterValue.filter((entry) => entry.split(':')[1].trim() !== '');
    setAssistantFilter(formattedFilter);
    setAssistantFilterOpen(false);
    events.interactionFilters(filterValue);
    tableMailing.tableState.setPageIndex(0);
  };

  return (
    <Drawer
      open={assistantFilterOpen}
      onOpenChange={() => {
        setAssistantFilterOpen(false);
      }}
    >
      <DrawerContent side='right'>
        <DrawerCloseButton />
        <DrawerHeader
          css={{
            borderBottom: '1px solid $nonInteractivePredominant',
          }}
        >
          <FloraTypography
            fontSize='title'
            viewport='desktop'
            fontWeight='regular'
            as='h3'
            data-testid='drawer-header-title'
          >
            Filtros
          </FloraTypography>
        </DrawerHeader>
        <DrawerBody css={{ height: '100%' }}>
          <SelectDropdown
            selectDropdownItem={riskAnalysisOptions}
            onChange={handleSelectValue}
            value={filterValue}
            id='risk'
            labelText='Nível de risco'
            multiple
          />
          <SelectDropdown
            selectDropdownItem={situationOptions}
            onChange={handleSelectValue}
            value={filterValue}
            id='situation'
            labelText='Situação'
            multiple
          />
          <SelectDropdown
            selectDropdownItem={operationOptions}
            onChange={handleSelectValue}
            value={filterValue}
            id='operation'
            labelText='Operação'
            multiple
          />
          <SelectDropdown
            selectDropdownItem={recadastroOptions}
            onChange={(e) => handleSelectValue(e, 'recadastro')}
            value={singleSelectValue('recadastro', filterValue)}
            id='recadastro'
            labelText='Recadastro'
            placeholder='Sim, Não'
          />
          <Box css={boxInputCssProps}>
            <FloraInput
              id='cpCode'
              label='Código Franquia (CS)'
              placeholder='Digite o código'
              onChange={onInputTextChange}
              value={filterInputValue('cpCode', filterValue ?? [], true)}
              data-testid='input-cp-code'
              css={inputCssProps}
            />
          </Box>
          <Box css={{ ...boxInputCssProps, marginTop: '$4' }}>
            <FloraInput
              id='label'
              label='Campanha'
              placeholder='Digite o nome da campanha'
              onChange={onInputTextChange}
              value={filterInputValue('label', filterValue ?? [])}
              data-testid='input-label-code'
              css={inputCssProps}
            />
          </Box>
        </DrawerBody>
        <DrawerFooter
          css={{
            borderTop: '1px solid $nonInteractiveAltAuxiliar',
          }}
        >
          <Button
            hierarchy='secondary'
            onClick={() =>
              hasFilter || hasFilterGlobal ? cleanFilters() : setAssistantFilterOpen(false)
            }
            css={{ width: '50%' }}
          >
            {hasFilter || hasFilterGlobal ? 'Limpar filtros' : 'Cancelar'}
          </Button>

          <Button
            disabled={!hasFilter}
            data-testid='btn-apply-filter'
            onClick={submitFilter}
            css={{ width: '50%' }}
          >
            Aplicar filtros
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
