import { OrderIncrementFormData } from '@/types';
import * as yup from 'yup';

const REQUIRED_ERROR_MESSAGE = 'Esse campo é obrigatório.';
const MIN_CHECKBOX_ERROR_MESSAGE = 'Pelo menos 1 item deve ser selecionado.';

export const orderIncrementFormDataSchema: yup.ObjectSchema<OrderIncrementFormData> = yup.object({
  hasOrderProductConditions: yup.boolean().optional(),
  orderProductConditions: yup.string().when('hasOrderProductConditions', {
    is: true,
    then: schema => schema.required(REQUIRED_ERROR_MESSAGE),
    otherwise: schema => schema.optional(),
  }),
  categories: yup
    .array()
    .min(1, MIN_CHECKBOX_ERROR_MESSAGE)
    .when('hasCategories', {
      is: false,
      then: schema => schema.min(0),
    }),
  hasCategories: yup.boolean().required(),
  note: yup.string().optional(),
});
