import { percentNoFraction } from '@/shared/format';
import { Box, Flex, Text } from '@grupoboticario/flora-react';

export const ProgressBar = ({ value }) => {
  return (
    <Flex direction="row" align="center" justify="right" gap="$3">
      {value ? (
        <>
          <Box
            css={{
              height: 6,
              width: '68px',
              background: `linear-gradient(to right, #011E38 ${value}%, #00000016 ${value}%)`,
              borderRadius: 3,
            }}
          />
          <Text css={{ fontSize: '$bodySmall', width: '32px', textAlign: 'right' }}>
            {percentNoFraction.format(value / 100)}
          </Text>
        </>
      ) : (
        <Text css={{ fontSize: '$bodySmall', width: '32px', textAlign: 'right' }}>-</Text>
      )}
    </Flex>
  );
};
