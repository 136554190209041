import { Flex, FloraButton } from '@grupoboticario/flora-react';
import { TrashIcon, ArrowLeftIcon } from '@grupoboticario/flora-react-icons';

export function RupturedProductConfirmDeletion({
  deleteRecommendation,
  chatId,
  copilotId,
  setConfirmDelete,
}: {
  deleteRecommendation: ({ chatId, copilotId }: { chatId: string; copilotId: string }) => void;
  chatId: string;
  copilotId: string;
  setConfirmDelete: (value: boolean) => void;
}) {
  return (
    <Flex direction="column" wrap="wrap" gapY="$2" css={{ marginTop: '$4' }}>
      <FloraButton
        size="small"
        hierarchy="primary"
        styleSemantic="warning"
        has="iconRight"
        icon={<TrashIcon />}
        isFull
        onClick={() => deleteRecommendation({ chatId, copilotId })}
      >
        Descartar recomendação
      </FloraButton>
      <FloraButton
        size="small"
        hierarchy="secondary"
        styleSemantic="neutral"
        has="iconLeft"
        icon={<ArrowLeftIcon />}
        isFull
        onClick={() => setConfirmDelete(false)}
      >
        Voltar para a recomendação
      </FloraButton>
    </Flex>
  );
}
