import { saveAndContinueAction, toggleInteraction, trackerFn } from '../functions';
import { InteractionDetail, EventName } from '../types';

const event_name = EventName.INTERACTION_CENTRAIS_SERVICO_INCREMENTO_PEDIDO;

export const orderIncrementEvents = () => {
  return {
    toggleAddProductsPermission: (isEnable: boolean) =>
      trackerFn({
        event_name,
        cd_interaction_detail: toggleInteraction(isEnable, 'permissao-adicionar-produtos'),
      }),
    toggleIncrementRequirement: (isEnable: boolean) =>
      trackerFn({
        event_name,
        cd_interaction_detail: toggleInteraction(isEnable, 'obrigatorio-incrementar'),
      }),
    toggleMinimumValue: (isEnable: boolean) =>
      trackerFn({
        event_name,
        cd_interaction_detail: toggleInteraction(isEnable, 'valor-minimo'),
      }),
    inputMinimumValue: (value: string) => {
      trackerFn({
        event_name,
        cd_interaction_detail: `${InteractionDetail.input}:valor-minimo-${value}`,
      });
    },
    toggleMaximumValue: (isEnable: boolean) =>
      trackerFn({
        event_name,
        cd_interaction_detail: toggleInteraction(isEnable, 'valor-maximo'),
      }),
    inputMaximumValue: (value: string) => {
      trackerFn({
        event_name,
        cd_interaction_detail: `${InteractionDetail.input}:valor-maximo-${value}`,
      });
    },
    toggleSpecificProducts: (isEnable: boolean) =>
      trackerFn({
        event_name,
        cd_interaction_detail: toggleInteraction(isEnable, 'restricao-obrigatoriedade-produtos-especificos'),
      }),
    inputSpecificProducts: (skus: string) => {
      trackerFn({
        event_name,
        cd_interaction_detail: `${InteractionDetail.input}:produtos-especificos-${skus}`,
      });
    },
    toggleSpecificProductCategory: (isEnable: boolean) =>
      trackerFn({
        event_name,
        cd_interaction_detail: toggleInteraction(isEnable, 'categoria-produtos-especifica'),
      }),

    selectSpecificProductCategory: (categoryName: string) => {
      trackerFn({
        event_name,
        cd_interaction_detail: `${InteractionDetail.click}:categoria-produtos-especifica-${categoryName}`,
      });
    },
    orderIncrementSaveAndContinue: () => saveAndContinueAction({ event_name }),
  };
};
