import { Box, styled, useMaxWidth } from "@grupoboticario/flora-react";
import { UserMenu } from "./user-menu";
import { MenuDesktop } from "./menu-desktop";
import { MenuMobile } from "./menu-mobile/menu-mobile";
import { NotificationMenu } from "./NotificationMenu";
import { MenuProvider } from "./menu";

const RootContainer = styled(Box, {
  display: "flex",
  position: "fixed",
  top: 0,
  right: 0,
  left: 0,
  "@mobile": {
    width: "$20",
    height: "100%",
    top: "auto",
    left: "auto",
    right: "auto",
  },
});

const NavContainer = styled(Box, {
  position: "relative",
  display: "flex",
  padding: "$0 $4",
  backgroundColor: "$backgroundAltPrimary",
  flexDirection: "row",
  justifyContent: "space-between",
  flex: 1,
  height: 64,
  "@mobile": {
    padding: "$6 $0",
    flexDirection: "column",
    height: "100%",
    flex: "1 1 auto",
  },
});

const NavItemsContainer = styled(Box, {
  display: "flex",
  flexDirection: "row",
  gap: "$10",
  "@mobile": {
    flexDirection: "column",
  },
});

const NavUserAndControl = styled(Box, {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "stretch",
  gap: "$2",

  "@mobile": {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "stretch",
  },
});

export default function Root({ user }) {
  const isMobile = useMaxWidth("519px");

  return (
    <RootContainer>
      <NavContainer as="nav">
        <MenuProvider>
          <NavItemsContainer>
            {isMobile ? <MenuMobile /> : <MenuDesktop />}
          </NavItemsContainer>
        </MenuProvider>
        <NavUserAndControl>
          <NotificationMenu />
          <UserMenu user={user} />
        </NavUserAndControl>
      </NavContainer>
    </RootContainer>
  );
}
