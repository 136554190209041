import { Flex, FloraTypography, CSS } from '@grupoboticario/flora-react';
import { ExclamationMarkHexagonFillIcon } from '@grupoboticario/flora-react-icons';

interface FormInvalidMessageProps {
  message: string;
  css?: CSS;
}
export const FormInvalidMessage = ({ message, css }: FormInvalidMessageProps) => (
  <Flex
    align="center"
    css={{
      '& svg': {
        scale: '0.7',
        color: '$1',
      },
      ...css,
    }}
  >
    <ExclamationMarkHexagonFillIcon />
    <FloraTypography fontSize="exceptionsAuxiliar">{message}</FloraTypography>
  </Flex>
);
