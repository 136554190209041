import { FilterTagList } from '@/shared';
import { useAssistantMailingStore } from '@/store/assistant';
import { useShallow } from 'zustand/react/shallow';
import { formatAssistantFilterTag } from './functions/formatAssistantFilterTag';

export const AssistantFilter = () => {
  const { setAssistantFilter, assistantFilter } = useAssistantMailingStore(
    useShallow((state) => state)
  );

  return (
    <FilterTagList
      filterTagResult={formatAssistantFilterTag(assistantFilter)}
      onChangeFilter={setAssistantFilter}
    />
  );
};
