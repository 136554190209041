import { PickUpStore, PickUpStoreFormData, SavePickUpStoreRequestType } from '@/types';
import { getValueByCondition } from '@/utils';

const allowPickUpStoreDisabledPayload = (note: string): PickUpStore => ({
  allowPickUpStore: false,
  hasIdentificationRequirements: false,
  hasProofOfAddress: false,
  hasScheduleForPickUp: false,
  hasThirdPartySupport: false,
  hasMinimumPickUpPeriod: false,
  hasMaximumPickUpPeriod: false,
  note,
});

const allowPickUpStoreEnabledPayload = (formData: PickUpStoreFormData): PickUpStore => {
  const {
    pickUpStoreInfo,
    hasIdentificationRequirements,
    hasProofOfAddress,
    hasScheduleForPickUp,
    hasThirdPartySupport,
    hasMinimumPickUpPeriod,
    hasMaximumPickUpPeriod,
  } = formData;
  return {
    allowPickUpStore: true,
    pickUpStoreInfo: pickUpStoreInfo ? pickUpStoreInfo : undefined,
    hasIdentificationRequirements,
    identificationRequirements: getValueByCondition(hasIdentificationRequirements, formData.identificationRequirements),
    hasProofOfAddress,
    proofOfAddress: getValueByCondition(hasProofOfAddress, formData.proofOfAddress),
    proofOfAddressSpecifications: getValueByCondition(hasProofOfAddress, formData.proofOfAddressSpecifications),
    hasScheduleForPickUp,
    scheduleForPickUpNote: getValueByCondition(hasScheduleForPickUp, formData.scheduleForPickUpNote),
    hasThirdPartySupport,
    thirdPartySupportInfo: getValueByCondition(hasThirdPartySupport, formData.thirdPartySupportInfo),
    hasMinimumPickUpPeriod,
    minimumPickUpPeriodQty: getValueByCondition(hasMinimumPickUpPeriod, formData.minimumPickUpPeriodQty),
    minimumPickUpPeriodInfo: getValueByCondition(hasMinimumPickUpPeriod, formData.minimumPickUpPeriodInfo),
    hasMaximumPickUpPeriod,
    maximumPickUpPeriodQty: getValueByCondition(hasMaximumPickUpPeriod, formData.maximumPickUpPeriodQty),
    maximumPickUpPeriodInfo: getValueByCondition(hasMaximumPickUpPeriod, formData.maximumPickUpPeriodInfo),
    note: formData.note,
  };
};

export const formatPickUpStoreFormData = (formData: PickUpStoreFormData, id: string): SavePickUpStoreRequestType => ({
  _id: id,
  step: '4',
  pickUpStore: formData.allowPickUpStore
    ? allowPickUpStoreEnabledPayload(formData)
    : allowPickUpStoreDisabledPayload(formData.note),
});
