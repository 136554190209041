import { Flex, styled } from '@grupoboticario/flora-react'

const afterLine = {
  '&::after': {
    content: '',
    position: 'absolute',
    width: '100%',
    height: '1px',
    bottom: '-24px',
    backgroundColor: '$nonInteractiveOutline',
  },
}

const BoxNewOrder = styled(Flex, {
  position: 'relative',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$2',
  width: '100%',
  backgroundColor: '$backgroundPrimary',
  borderRadius: '$medium',
  padding: '$4 !important',
  marginBottom: '$12',

  variants: {
    show: {
      true: {
        display: 'flex',
      },
      false: {
        display: 'none',
      },
    },
  },

  '@tablet': {
    flexDirection: 'row',
    gap: '$6',
    padding: '$10 !important',
  },

  ...afterLine,
})

const ContainerIcon = styled(Flex, {
  alignItems: 'center',
  justifyContent: 'center',
  width: '$18',
  height: '$18',
  backgroundColor: '$backgroundTertiary',
  borderRadius: '50%',
})

const ContainerInfo = styled(Flex, {
  flexDirection: 'column',
  textAlign: 'center',
  gap: '$2',

  '@tablet': {
    textAlign: 'left',
  },

  button: {
    '@tablet': {
      width: 'fit-content',
    },
  },
})

const OrderMfeContainer = styled('div', {
  position: 'relative',
  backgroundColor: '$backgroundPrimary',
  borderRadius: '$medium',

  variants: {
    show: {
      true: {
        width: '100%',
        minHeight: '600px',
        marginBottom: '$12',

        ...afterLine,
      },
      false: {},
    },
  },

  '*': {
    fontFamily: '$primary !important',
  },
})

export { afterLine, BoxNewOrder, ContainerIcon, ContainerInfo, OrderMfeContainer }
