import { memo } from 'react';
import { Chat, Tag } from '@/shared/types/interfaces';
import { ChatItemReseller } from './ChatItemReseller';
import { ChatItemIr } from './ChatItemIr';

export type ChatItemProps = {
  chat: Chat;
  selected: boolean;
  tabIndex?: number;
  tags: Array<Tag>;
  onClick: () => void;
};

export const ChatItem = memo(({ chat, selected, tabIndex, tags, onClick }: ChatItemProps) => {
  switch (chat.account.type) {
    case 'ir':
      return <ChatItemIr chat={chat} selected={selected} tabIndex={tabIndex} tags={tags} onClick={onClick} />;
    case 'reseller':
      return <ChatItemReseller chat={chat} selected={selected} tabIndex={tabIndex} tags={tags} onClick={onClick} />;
  }
});
