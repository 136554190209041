enum PromotionsSlug {
  BOT = 'bot',
  OUI = 'oui',
  QDB = 'qdb',
  EUD = 'eud',
  MLT = 'mlt',
}

enum PromotionsColors {
  bot = '#00A775',
  oui = '#971B2F',
  eud = '#613A53',
  qdb = '#D43578',
  mlt = '#7E15E3',
}

export { PromotionsSlug, PromotionsColors }
