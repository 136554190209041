import { authService } from "@vdi/auth-service";

export async function createTeam(groupName: string, owners: string[]) {
  const { token } = await authService.getTokenOrRedirect();
  const url = `${process.env.API_BASE_URL}/groups`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ name: groupName, owners }),
  });

  if (!response.ok) {
    throw new Error(`request response status error: ${response.status}`);
  }

  return response.json();
}
