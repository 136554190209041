import { useState } from 'react'

import { AddressStep } from '../address-step'
import { SearchStep } from '../search-step'

import { Container } from './consult-similar-drawer.styles'

import { SelectedAddressStateProps } from './consult-similar-drawer.types'

const ConsultSimilarDrawer = (): JSX.Element => {
  const [step, setStep] = useState<string | null>('address')
  const [selectedAddress, setSelectedAddress] = useState<SelectedAddressStateProps>({
    id: null,
    type: '',
    name: '',
  })

  return (
    <Container>
      <AddressStep
        changeStep={setStep}
        setSelectedAddress={setSelectedAddress}
        show={Boolean(step === 'address')}
      />

      <SearchStep
        changeStep={setStep}
        selectedAddress={selectedAddress}
        show={Boolean(step === 'search')}
      />
    </Container>
  )
}

export { ConsultSimilarDrawer }
