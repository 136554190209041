import { DashboardGridProps } from '@/shared/components/DashboardGrid/DashboardGrid';

export const gridSettings: DashboardGridProps['css'] = {
  '@mobile': {
    gridTemplateAreas: `
    "total-base total-base"
    "total-base-actives total-base-actives"
    "total-base-activity total-base-activity"
    "active-base active-base"
    "active-base-actives active-base-actives"
    "active-base-activity active-base-activity"
    "multi-brand-base multi-brand-base"
    "multi-brand-actives multi-brand-actives"
    "digital-channel-penetration digital-channel-penetration"
    "er-penetration er-penetration"
    "net-additions net-additions"
    "loss loss"
    "churn churn"
    `,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@tablet': {
    gridTemplateAreas: `
    "total-base total-base-actives"
    "total-base-activity active-base"
    "active-base-actives active-base-activity"
    "multi-brand-base multi-brand-actives"
    "digital-channel-penetration er-penetration"
    "net-additions loss"
    "churn churn"
    `,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@desktop': {
    gridTemplateAreas: `
    "total-base total-base total-base-actives total-base-actives total-base-activity total-base-activity"
    "active-base active-base active-base-actives active-base-actives active-base-activity active-base-activity"
    "multi-brand-base multi-brand-base multi-brand-base multi-brand-actives multi-brand-actives multi-brand-actives"
    "digital-channel-penetration digital-channel-penetration digital-channel-penetration er-penetration er-penetration er-penetration"
    "net-additions net-additions loss loss churn churn"
    `,
    gridTemplateColumns: 'repeat(6, 1fr)',
  },
};

export const revenueGridSettings: DashboardGridProps['css'] = {
  '@mobile': {
    gridTemplateAreas: `
     "revenue-gmv revenue-gmv"
     "revenue-rbv revenue-rbv"
     "revenue-pvr revenue-pvr"
     "productivity productivity"
     "average-order average-order"
     "upa upa"
    `,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@tablet': {
    gridTemplateAreas: `
     "revenue-gmv revenue-gmv"
     "revenue-rbv revenue-rbv"
     "revenue-pvr revenue-pvr"
     "productivity average-order"
     "upa upa"
    `,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@desktop': {
    gridTemplateAreas: `
      "revenue-gmv revenue-gmv revenue-rbv revenue-rbv revenue-pvr revenue-pvr"
      "productivity productivity average-order average-order upa upa"
    `,
    gridTemplateColumns: 'repeat(6, 1fr)',
  },
};
