import { classificationLevelLabelMap } from '@/shared/format';
import { Box, Text } from '@grupoboticario/flora-react';

export function InactivityLevelName({ level }) {
  const levelBackgroundColor = {
    I1: '#20AE43',
    I2: '#6CD115',
    I3: '#A7B820',
    I4: '#E29500',
    I5: '#FF5501',
    I6: '#FF0801',
    I0: '#49B1BF',
    I7: '#000000',
  };
  return (
    <Box css={{ width: '120px' }}>
      <Text
        css={{
          position: 'relative',
          paddingLeft: level === 'Total' ? 0 : '$4',
          fontSize: '$bodySmall',
          fontVariant: 'tabular-nums',

          ...(level !== 'Total' && {
            '&::before': {
              position: 'absolute',
              backgroundColor: levelBackgroundColor[level] || 'rgba(102, 102, 102, 1)',
              content: "''",
              width: '5px',
              height: '22px',
              borderRadius: '4px',
              display: 'inline-block',
              top: '-2px',
              left: 0,
            },
          }),
        }}
      >
        {classificationLevelLabelMap[level] || level}
      </Text>
    </Box>
  );
}
