import {
  HttpClient,
  HttpMethod,
  type HttpConfig,
  type IMonitoringClient,
} from '@grupoboticario/vdi-mfe-utils'

import { authService } from '@vdi/auth-service'

import { env } from '@shared/env'

import { type API } from './api.types'

const makeHttpConfig = (monitor: IMonitoringClient): HttpConfig => ({
  baseUrl: env.API_BASE_URL,
  timeout: +env.API_TIMEOUT,
  async getHeaders() {
    const { token } = await authService.getTokenOrRedirect()
    return {
      authorization: `Bearer ${token}`,
      'content-type': 'application/json',
    }
  },
  onError({ error }) {
    const err = error as unknown as { message: string }
    monitor.error(new Error(err.message), err)
  },
})

const makeApiClient = (monitor: IMonitoringClient): API => {
  const http = HttpClient.create(makeHttpConfig(monitor))

  return {
    // Resellers
    getResellers: (params: Record<string, string>) =>
      http.mutate({
        method: HttpMethod.Post,
        endpoint: '/resellers',
        body: params,
      }),
    updateResellerAddInfo: ({ resellerId, info } = {}) =>
      http.mutate({
        method: HttpMethod.Patch,
        endpoint: `/resellers/${resellerId}/additional-information`,
        body: {
          additionalInformation: info,
        },
      }),
    getResellerGeneralData: ({ resellerId } = {}) =>
      http.query({
        endpoint: `/resellers/${resellerId}`,
      }),
    getMLDLink({ resellerId } = {}) {
      return http.query({
        endpoint: `/resellers/mld/${resellerId}`,
      })
    },
    getResellerComplementData: ({ resellerId } = {}) =>
      http.query({
        endpoint: `/resellers/${resellerId}/additional-information`,
      }),
    getResellerComplementDataV2: ({ resellerId } = {}) =>
      http.query({
        endpoint: `/v2/resellers/${resellerId}/additional-information`,
      }),
    getResellerOrderHistory: ({ resellerId, page, limit, ...filters } = {}) =>
      http.query({
        endpoint: `/resellers/${resellerId}/orders`,
        params: {
          ...filters,
          page,
          limit,
        },
      }),
    getResellerFidelity: ({ resellerId } = {}) =>
      http.query({
        endpoint: `/resellers/${resellerId}/my-club-tier`,
      }),
    getResellerFidelityMovement: ({ resellerId, page, limit, filter } = {}) =>
      http.query({
        endpoint: `/resellers/${resellerId}/my-club-extract`,
        params: {
          filter: filter,
          page,
          limit,
        },
      }),
    getResellerOrderDetail: ({ resellerId, orderNumber } = {}) =>
      http.query({
        endpoint: `/resellers/${resellerId}/order-detail/${orderNumber}`,
      }),
    getResellerCredit: ({ resellerId } = {}) =>
      http.query({
        endpoint: `/resellers/${resellerId}/credits`,
      }),
    getResellerCCRMovements: ({ resellerId } = {}) =>
      http.query({
        endpoint: `/resellers/${resellerId}/ccr-movement`,
      }),
    getTopPurchasedProducts: ({ resellerId } = {}) =>
      http.query({
        endpoint: `/resellers/${resellerId}/top-purchased-products`,
      }),
    getResellerTitles: ({ page, limit, resellerId } = {}) =>
      http.query({
        endpoint: `/resellers/${resellerId}/payment-titles`,
        params: {
          page,
          limit,
        },
      }),
    getResellerTitleLink: ({ titleId } = {}) =>
      http.query({
        endpoint: `/resellers/payment-billet-link/${titleId}`,
      }),

    // Attendance
    createAttendance: (body) =>
      http.mutate({
        endpoint: '/attendances',
        method: HttpMethod.Post,
        body,
      }),
    getAttendances: () =>
      http.query({
        endpoint: '/attendances',
      }),

    // Franchise
    getParentCode: ({ franchiseId } = {}) =>
      http.query({
        endpoint: `/franchises/${franchiseId}`,
      }),

    // Sectors
    getSectors(params) {
      return http.query({
        endpoint: '/sectors',
        params,
      })
    },

    getOrderHistorical({ resellerId, orderNumber } = {}) {
      return http.query({
        endpoint: `/resellers/${resellerId}}/order-historical/${orderNumber}`,
      })
    },

    getOrderGifts({ resellerId, orderNumber } = {}) {
      return http.query({
        endpoint: `/resellers/${resellerId}}/order-gifts/${orderNumber}`,
      })
    },

    getOrderCustomFields({ resellerId, orderNumber } = {}) {
      return http.query({
        endpoint: `/resellers/${resellerId}}/order-custom-fields/${orderNumber}`,
      })
    },

    getDistributionCenter({ resellerId } = {}) {
      return http.query({
        endpoint: `/resellers/${resellerId}/distribution-center`,
      })
    },

    getSearchProduct({ resellerId, ...params } = {}) {
      return http.query({
        endpoint: `/resellers/${resellerId}/search-product`,
        params,
      })
    },
  }
}

export { makeApiClient, makeHttpConfig }
