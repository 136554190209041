import { Flex, FloraTypography } from '@grupoboticario/flora-react';
import { CheckSingleIcon, TagFullIcon } from '@grupoboticario/flora-react-icons';
import { MenuItem } from '@szhsin/react-menu';
import { ChatTagType } from './ChatTagsMenu';
import { EditTagMenu } from './EditTag';

type TagItemProps = {
  tag: ChatTagType;
  setNewTag: any;
  setTagList: any;
  availableColors: string[];
  handleSelected: (tag: ChatTagType) => void;
};

export function TagItem({ tag, setNewTag, setTagList, availableColors, handleSelected }: Readonly<TagItemProps>) {
  return (
    <Flex key={tag.id} direction="row" justify="center" align="center">
      <MenuItem
        value={tag}
        onClick={e => {
          e.syntheticEvent.stopPropagation();
          handleSelected(tag);
          e.keepOpen = true;
        }}
      >
        <Flex direction="row" justify="left" css={{ minWidth: '20px' }}>
          {tag.selected && <CheckSingleIcon color="$9" size="15" />}
        </Flex>
        <Flex direction="row" justify="left" css={{ width: '165px' }}>
          <FloraTypography
            fontSize="exceptionsAuxiliar"
            as="span"
            css={{ marginBottom: '3px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
          >
            {tag.name}
          </FloraTypography>
        </Flex>
        <TagFullIcon size="15" css={{ color: `${tag.color}` }} />
      </MenuItem>
      <EditTagMenu tag={tag} setNewTag={setNewTag} setTagList={setTagList} colors={availableColors} />
    </Flex>
  );
}
