import { DashboardGrid, IndicatorCard, Table } from '@/shared/components';
import { gridSettings } from './grid-settings';
import { currencyNoFraction, decimalNoFraction, decimalOnePlace, setDisplayedValue } from '@/shared/format';
import { ChartDonnut, ColumnChart } from './components';
import { useGetDashboardData } from '@/shared/hooks';
import { DollarSignCircleIcon, LineChartIcon } from '@grupoboticario/flora-react-icons';

const channelTitle = 'Receita GMV por meio de Captação';
const brandTitle = 'Receita GMV por marcas';

export function RevenueDashboard() {
  const { data, isLoading } = useGetDashboardData({ queryKey: 'revenue', path: '/revenue/dashboard' });

  return (
    <DashboardGrid css={gridSettings}>
      <IndicatorCard
        title="GMV"
        tooltip="Valor total dos pedidos realizados pelos revendedores. O valor é composto pela Receita GMV."
        mainValue={setDisplayedValue(data?.revenueGmv.realized, currencyNoFraction)}
        icon={<DollarSignCircleIcon />}
        isLoading={isLoading}
        gridArea="revenue-gmv"
      />
      <IndicatorCard
        title="Receita bruta do varejo"
        tooltip="Valor total dos pedidos realizados pelos revendedores. O valor é composto pela Receita Bruta de Vendas (RBV)."
        mainValue={setDisplayedValue(data?.revenueRbv.realized, currencyNoFraction)}
        icon={<DollarSignCircleIcon />}
        isLoading={isLoading}
        gridArea="revenue-rbv"
      />
      <IndicatorCard
        title="Receita liquida"
        tooltip="Valor total pago pelos pedidos realizados pelos revendedores. O valor é composto pelo Preço de Venda ao Revendedor (PVR), desconsiderando valor de comissão e créditos residuais."
        mainValue={setDisplayedValue(data?.revenuePvr.realized, currencyNoFraction)}
        icon={<DollarSignCircleIcon />}
        isLoading={isLoading}
        gridArea="revenue-pvr"
      />
      <IndicatorCard
        title="Quantidade de pedidos"
        tooltip="Quantidade total de pedidos realizados pelos revendedores."
        mainValue={setDisplayedValue(data?.orderVolume.value, decimalNoFraction)}
        isLoading={isLoading}
        gridArea="volume-orders"
      />
      <IndicatorCard
        title="Repique"
        tooltip="Quantidade média de pedidos válidos realizados pelo mesmo revendedor."
        mainValue={setDisplayedValue(data?.repeatedResellers.value, decimalOnePlace)}
        isLoading={isLoading}
        gridArea="repeated-resellers"
      />
      <IndicatorCard
        title="Pedidos cancelados"
        tooltip="Quantidade de pedidos realizados por revendedores que foram cancelados."
        mainValue={setDisplayedValue(data?.canceledOrders.value, decimalNoFraction)}
        isLoading={isLoading}
        gridArea="canceled-orders"
      />
      <IndicatorCard
        title="Pedidos em carteira"
        tooltip="Quantidade de pedidos realizados por revendedores ainda não faturados (em processamento)."
        mainValue={setDisplayedValue(data?.invoicingOrders.value, decimalNoFraction)}
        isLoading={isLoading}
        gridArea="invoice-orders"
      />
      <IndicatorCard
        title="Pedido médio"
        tooltip="Valor Médio da Receita GMV gerada por pedido válido."
        mainValue={setDisplayedValue(data?.averageTicket?.average, currencyNoFraction)}
        auxiliarText={
          data?.averageTicket?.totalOrders !== undefined
            ? `referente à ${decimalNoFraction.format(data?.averageTicket?.totalOrders)} pedidos`
            : '-'
        }
        isLoading={isLoading}
        gridArea="average-order"
      />
      <IndicatorCard
        title="Unidades por ativo (UPA)"
        tooltip="Quantidade média de itens comprados por Revendedor ativo no ciclo."
        mainValue={setDisplayedValue(data?.unitiesPerActive?.realized, decimalNoFraction)}
        isLoading={isLoading}
        gridArea="upa"
      />
      <IndicatorCard
        title="Reais por ativo"
        tooltip="Valor Médio da Receita GMV gerada por revendedor ativo."
        mainValue={setDisplayedValue(data?.productivity.realized, currencyNoFraction)}
        icon={<LineChartIcon />}
        auxiliarText={
          data?.productivity.actives !== undefined
            ? `referente à ${decimalNoFraction.format(data?.productivity.actives)} REs ativos`
            : '-'
        }
        isLoading={isLoading}
        gridArea="productivity"
      />

      <Table
        title="Receita GMV por nível de inatividade"
        tooltipText="Receita GMV gerada pelos revendedores classificados nos níveis de inatividade, detalhada por marca ou por meios de captação."
        isInactivityTable
        channelTitle={channelTitle}
        brandTitle={brandTitle}
        format={currencyNoFraction.format}
        isLoading={isLoading}
        data={data?.revenuePerInactivityLevel}
      />
      <Table
        title="Receita GMV por faixa do Meu Clube Multi"
        tooltipText="Receita GMV gerada pelos revendedores classificados nas diferentes faixa do programa de fidelidade Meu CLube Multi, detalhada por marca ou por meios de captação."
        format={currencyNoFraction.format}
        channelTitle={channelTitle}
        brandTitle={brandTitle}
        isLoading={isLoading}
        data={data?.revenuePerMeuClubeGBLevel}
      />
      <ChartDonnut gridArea="donut-chart" data={data?.revenueTopCategories} isLoading={isLoading} />
      <ColumnChart gridArea="column-chart" data={data?.revenuePerBrand} isLoading={isLoading} />
    </DashboardGrid>
  );
}
