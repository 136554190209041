import { CustomWindow, GtmEvent } from '../types';

interface trackerFnProps {
  cd_interaction_detail: string;
  event_name?: string;
  cd_error_message?: string;
}

const tracker = {
  track(params: GtmEvent) {
    const win: CustomWindow = window;

    if (Array.isArray(win.dataLayer)) {
      win.dataLayer.push(params);
    }
  },
};

export const trackerFn = ({ event_name, cd_interaction_detail, cd_error_message }: trackerFnProps): void => {
  tracker.track({
    event: 'analytics-event',
    event_parameters: {
      event_name,
      ...(cd_interaction_detail && { cd_interaction_detail }),
      ...(cd_error_message && { cd_error_message }),
    },
  });
};
