import { AssistantTeamStore, BaseIrs, FilterOptions, MailingStoreControlStateProps } from '@/types';
import { StateCreator } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export const controlMailingSlice: StateCreator<
  MailingStoreControlStateProps,
  [['zustand/devtools', never]],
  [['zustand/persist', controlFilter: { controlFilter: FilterOptions }]],
  MailingStoreControlStateProps
> = persist(
  (set) => ({
    baseIrsData: [] as BaseIrs[],
    sideSheetFilterOpen: false,
    sideSheetTeamsOpen: false,
    controlFilter: undefined as FilterOptions,
    assistantTeam: undefined as AssistantTeamStore[],
    totalSelectedIrs: '0',
    setBaseIrsData: (data: BaseIrs[]) => {
      set({ baseIrsData: data });
    },
    setSideSheetFilterOpen: (status: boolean) => {
      set({ sideSheetFilterOpen: status });
    },
    setSideSheetTeamsOpen: (status: boolean) => {
      set({ sideSheetTeamsOpen: status });
    },
    setControlFilter: (filter: FilterOptions) => {
      set({ controlFilter: filter });
    },
    setAssistantTeam: (data: AssistantTeamStore[]) => {
      set({ assistantTeam: data });
    },
    setTotalSelectedIrs: (total: string) => {
      set({ totalSelectedIrs: total });
    },
  }),
  {
    name: 'control',
    storage: createJSONStorage(() => sessionStorage),
    partialize: (state) => {
      return {
        controlFilter: state.controlFilter,
        assistantTeam: state.assistantTeam,
        totalSelectedIrs: state.totalSelectedIrs,
      };
    },
  }
);
