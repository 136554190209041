import { DashboardGrid, Table, IndicatorCard } from '@/shared/components';
import { useGetDashboardData } from '@/shared/hooks';
import { gridSettings } from './grid-settings';
import { decimalNoFraction, percent, setDisplayedValue } from '@/shared/format';
import {
  CheckSingleIcon,
  CheckDoubleIcon,
  StoreIcon,
  TwoPeopleIcon,
  SmartphoneStrokeIcon,
} from '@grupoboticario/flora-react-icons';

const channelTitle = 'Penetração por meio de captação';
const brandTitle = 'Penetração por marca';

export function ActivityDashboard() {
  const { data, isLoading } = useGetDashboardData({ queryKey: 'activity', path: '/activity/dashboard' });

  return (
    <DashboardGrid css={gridSettings}>
      <IndicatorCard
        title="Base total"
        tooltip="Quantidade de revendedores que fizeram, pelo menos, um pedido em até 6 ciclos, incluindo Inícios e Reinícios."
        mainValue={setDisplayedValue(data?.totalBase?.value, decimalNoFraction)}
        icon={<TwoPeopleIcon />}
        isLoading={isLoading}
        gridArea="total-base"
      />
      <IndicatorCard
        title="Ativos da base total"
        tooltip="Quantidade de revendedores que possuem pedido no ciclo corrente, considerando inícios e reinícios."
        mainValue={setDisplayedValue(data?.totalBaseActives?.value, decimalNoFraction)}
        icon={<CheckSingleIcon />}
        isLoading={isLoading}
        gridArea="total-base-actives"
      />
      <IndicatorCard
        title="Atividade da base total"
        tooltip="Percentual de revendores únicos da Base Total que fizeram pedido no ciclo corrente, considerando Inícios e Reinícios."
        mainValue={setDisplayedValue(data?.totalBaseActivity?.percentage, percent)}
        icon={<CheckDoubleIcon />}
        isLoading={isLoading}
        gridArea="total-base-activity"
      />
      <IndicatorCard
        title="Base ativa"
        tooltip="Quantidade de revendedores que fizeram pedido em até 6 ciclos, excluindo os Inicios e Reinícios."
        mainValue={setDisplayedValue(data?.activeBase?.value, decimalNoFraction)}
        icon={<TwoPeopleIcon />}
        isLoading={isLoading}
        gridArea="active-base"
      />
      <IndicatorCard
        title="Ativos da base ativa"
        tooltip="Quantidade de revendedores que possuem pedido no ciclo corrente, desconsiderando Inícios e Reinícios."
        mainValue={setDisplayedValue(data?.activeBaseActives?.value, decimalNoFraction)}
        icon={<CheckSingleIcon />}
        isLoading={isLoading}
        gridArea="active-base-actives"
      />
      <IndicatorCard
        title="Atividade da base ativa"
        tooltip="Percentual de revendores únicos da Base Ativa que fizeram pedido no ciclo corrente, desconsiderando Inícios e Reinícios."
        mainValue={setDisplayedValue(data?.activeBaseActivity?.percentage, percent)}
        icon={<CheckDoubleIcon />}
        isLoading={isLoading}
        gridArea="active-base-activity"
      />
      <IndicatorCard
        title="Ativas multimarcas"
        tooltip="Quantidade de revendedores que tiveram pedido com itens de mais de uma marca."
        mainValue={setDisplayedValue(data?.multiBrandActives?.value, decimalNoFraction)}
        icon={<CheckSingleIcon />}
        isLoading={isLoading}
        gridArea="multi-brand-actives"
      />
      <IndicatorCard
        title="Penetração nos canais digitais"
        tooltip="Percentual de revendedores ativos que tiveram pelo menos um pedido realizado nos canais digitais."
        mainValue={setDisplayedValue(data?.digitalChannelsPenetration?.percentage, percent)}
        icon={<SmartphoneStrokeIcon />}
        isLoading={isLoading}
        gridArea="digital-channel-penetration"
      />
      <IndicatorCard
        title="Penetração nos ER"
        tooltip="Percentual de revendedores ativos que tiveram pelo menos um pedido realizado no Espaço do Revendedor."
        mainValue={setDisplayedValue(data?.erChannelsPenetration?.percentage, percent)}
        icon={<StoreIcon />}
        isLoading={isLoading}
        gridArea="er-penetration"
      />
      <Table
        title="Ativos por nível de inatividade"
        tooltipText="Quantidade de revendedores Ativos, classificados nos diferentes níveis de inatividade, detalhada por marca ou meios de captação."
        isInactivityTable
        channelTitle={channelTitle}
        brandTitle={brandTitle}
        format={decimalNoFraction.format}
        isLoading={isLoading}
        data={data?.activesPerInactivityLevel}
        isActivityDashboard
      />
      <Table
        title="Ativos por Meu Clube Multi"
        tooltipText="Quantidade de revendedores Ativos, classificados nas diferentes faixa do programa de fidelidade Meu CLube Multi, detalhada por marca ou meios de captação."
        channelTitle={channelTitle}
        brandTitle={brandTitle}
        format={decimalNoFraction.format}
        isLoading={isLoading}
        data={data?.activesPerMeuClubeGb}
        isActivityDashboard
      />
    </DashboardGrid>
  );
}
