import { saveAndContinueAction, trackerFn } from '../functions';
import { EventName } from '../types';

interface SuccessErrorTrackMessageProps {
  eventName: string;
  errorMessage?: string;
}

const franchiseEventsTracker = ({ eventName, errorMessage }: SuccessErrorTrackMessageProps): void => {
  const eventSuffix = errorMessage ? 'erro' : 'sucesso';
  trackerFn({
    event_name: eventName,
    cd_interaction_detail: `${eventName}_${eventSuffix}`,
    cd_error_message: errorMessage,
  });
};

export const franchiseEvents = () => {
  return {
    inputCsCode: (errorMessage?: string) =>
      franchiseEventsTracker({ eventName: EventName.CALLBACK_DADOS_CADASTRAIS_CODIGO_CS, errorMessage }),
    inputUf: (errorMessage?: string) =>
      franchiseEventsTracker({ eventName: EventName.CALLBACK_DADOS_CADASTRAIS_ESTADO, errorMessage }),
    saveAndContinue: () =>
      saveAndContinueAction({ event_name: EventName.INTERACTION_CENTRAIS_SERVICO_DADOS_CADASTRAIS }),
  };
};
