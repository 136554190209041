import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useReducer,
  useState,
} from 'react'

import { MetaState } from '@shared/api'

import { Step } from '../activation-register.types'
import { copyStep, dispatchStep, registerStep } from '../config/steps'

const RegisterProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [list, setList] = useState([
    copyStep('done'),
    dispatchStep('current'),
    registerStep('await'),
  ])

  return (
    <RegisterContext.Provider value={{ dispatch, state }}>
      <RegisterStepsContext.Provider value={{ list, setList }}>
        {children}
      </RegisterStepsContext.Provider>
    </RegisterContext.Provider>
  )
}

const initialState: RegisterState = {
  id: '',
  text: '',
  resellers: [],
  cycles: [],
  sectors: [],
  status: MetaState.Idle,
}

const RegisterContext = createContext<RegisterContextProps>({
  dispatch() {},
  state: initialState,
})

const RegisterStepsContext = createContext<RegisterStepsContextProps>({
  setList() {},
  list: [],
})

const reducer = (state: RegisterState, action: RegisterAction): RegisterState => {
  switch (action.type) {
    case 'change-reason':
      return { ...state, ...action.payload }
    case 'change-resellers':
      return {
        ...state,
        ...action.payload,
      }
    case 'change-status':
      return { ...state, status: action.payload }
    case 'reset-state':
      return initialState
    default:
      return state
  }
}

interface RegisterState {
  text: string
  id?: string
  resellers: number[]
  cycles: string[]
  sectors: string[]
  status: MetaState
  success?: boolean
}

type RegisterAction =
  | {
      type: 'change-reason'
      payload: { text: string; id?: string; success: boolean }
    }
  | {
      type: 'change-resellers'
      payload: {
        resellers: number[]
        cycles: string[]
        sectors: string[]
      }
    }
  | {
      type: 'change-status'
      payload: MetaState
    }
  | {
      type: 'reset-state'
    }

interface RegisterContextProps {
  state: RegisterState
  dispatch: Dispatch<RegisterAction>
}

interface RegisterStepsContextProps {
  list: Step[]
  setList: Dispatch<SetStateAction<Step[]>>
}

const useRegisterContext = (): RegisterContextProps => {
  return useContext(RegisterContext)
}

const useRegisterStepsContext = (): RegisterStepsContextProps => {
  return useContext(RegisterStepsContext)
}

export { RegisterContextProps, RegisterProvider, useRegisterContext, useRegisterStepsContext }
