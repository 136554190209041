import { useQuery } from "@tanstack/react-query";
import { fetchAccount } from "@api/Account/fetchAccount";

interface Account {
  id: string;
  name: string;
  code: string;
  type: number;
}

export function useFetchAccount(userId: string) {
  return useQuery<Account[]>({
    queryKey: ["account", "user", userId],
    queryFn: async () => {
      const accounts = await fetchAccount(userId);
      const account = accounts[0];

      return account;
    },
  });
}
