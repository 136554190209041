import { Delivery } from './serviceCenter';
import { DeliveryThirdPartyObservation } from './serviceEnum';

export type DeliveryFormData = Delivery;

export const deliveryThirdPartiesCheckboxValues = [
  {
    label: 'Necessário assinar o contrato',
    id: DeliveryThirdPartyObservation.CONTRATO_ASSINADO,
    value: DeliveryThirdPartyObservation.CONTRATO_ASSINADO,
  },
  {
    label: 'Somente a partir da segunda compra',
    id: DeliveryThirdPartyObservation.APOS_SEGUNDA_COMPRA,
    value: DeliveryThirdPartyObservation.APOS_SEGUNDA_COMPRA,
  },
  {
    label: 'Apresentar documento original com foto do titular',
    id: DeliveryThirdPartyObservation.DOCUMENTO_TITULAR,
    value: DeliveryThirdPartyObservation.DOCUMENTO_TITULAR,
  },
  {
    label: 'Apresentar cópia do documento com foto do titular',
    id: DeliveryThirdPartyObservation.COPIA_DOCUMENTO_TITULAR,
    value: DeliveryThirdPartyObservation.COPIA_DOCUMENTO_TITULAR,
  },
  {
    label: 'Apresentar termo de autorização de retirada',
    id: DeliveryThirdPartyObservation.TERMO_AUTORIZACAO_RETIRADA,
    value: DeliveryThirdPartyObservation.TERMO_AUTORIZACAO_RETIRADA,
  },
];
