import { Team } from "@shared/types";
import { ReactNode, createContext, useContext, useState } from "react";

export interface UserDataSelected {
  id: string;
  name: string;
  email: string;
  groups: Team[];
}

interface SearchDropDownUserContextData {
  options: string[];
  setOptions: (options: string[]) => void;
  selectedUsers: UserDataSelected[];
  setSelectedUsers: (users: UserDataSelected[]) => void;
}

interface SearchDropDownUserContextProps {
  children: ReactNode;
}

const SearchDropdownUserContext = createContext<SearchDropDownUserContextData>(
  {} as SearchDropDownUserContextData,
);

export function SearchDropdownUserProvider({
  children,
}: SearchDropDownUserContextProps) {
  const [options, setOptions] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<UserDataSelected[]>([]);

  return (
    <SearchDropdownUserContext.Provider
      value={{ options, setOptions, selectedUsers, setSelectedUsers }}
    >
      {children}
    </SearchDropdownUserContext.Provider>
  );
}

export function useSearchDropdownUser() {
  return useContext(SearchDropdownUserContext);
}
