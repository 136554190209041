import { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

import { Button, Flex, Tag } from "@grupoboticario/flora-react";
import { Table } from "@grupoboticario/flora-team-react-table";
import {
  CrossCircleIcon,
  ThreePeopleIcon,
} from "@grupoboticario/flora-react-icons";

import { TableCell, TableHead, TableRoot } from "@shared/styles";
import { Loading, FeedbackMessage, Paginate } from "@shared/components";
import { useSearch } from "@shared/components/Search/useSearch";
import { usePaginateCursor } from "@shared/hooks";

import { useUserList } from "./useUserList";
import { UserStatusSwitch } from "./components/UserStatusSwitch";

export function UserList() {
  const {
    page,
    direction,
    setNextPage,
    setPreviousPage,
    DefaultValueCursorReset,
  } = usePaginateCursor();
  const { searchTerm } = useSearch();

  const {
    data: users,
    isError,
    isLoading,
    isPlaceholderData,
    isFetching,
    refetch,
  } = useUserList(searchTerm, { cursor: page, direction });
  const dateFormatter = useMemo(() => new Intl.DateTimeFormat("pt-BR"), []);

  const hasMoreThanOnePage = !(
    !users?.meta?.hasNextPage && !users?.meta?.hasPrevPage
  );

  useEffect(() => {
    DefaultValueCursorReset();
  }, [searchTerm]);

  if (isLoading || isFetching || isPlaceholderData) {
    return (
      <Loading
        title="Carregando as informações"
        subtitle="Por favor, aguarde um instante."
      />
    );
  }

  if (isError) {
    return (
      <FeedbackMessage
        icon={<CrossCircleIcon color="$nonInteractiveAuxiliar" size={40} />}
        title="Falha ao carregar a lista de usuários"
        subtitle="Não foi possível carregar as informações"
        button={<Button onClick={() => refetch()}>Tentar novamente</Button>}
      />
    );
  }

  return (
    <Flex
      css={{
        flexDirection: "column",
        alignItems: "center",
        gap: "$6",
        height: "100%",
      }}
    >
      {users.data?.length === 0 ? (
        <FeedbackMessage
          icon={<ThreePeopleIcon color="$nonInteractiveAuxiliar" size={40} />}
          title="Nenhum usuário encontrado"
          subtitle="Tente ajustar os filtros de busca ou adicionar novos usuários."
        />
      ) : (
        <>
          <TableRoot className="fade-in">
            <thead>
              <Table.Row>
                <TableHead align="left">Cód. VD+</TableHead>
                <TableHead align="left">Nome</TableHead>
                <TableHead align="left">E-mail</TableHead>
                <TableHead align="left">Data de cadastro</TableHead>
                <TableHead align="left">Último acesso</TableHead>
                <TableHead align="left">Perfil</TableHead>
                <TableHead align="left">Status</TableHead>
              </Table.Row>
            </thead>
            <tbody>
              {users.data?.map((user) => {
                const userHasProfile = user.profiles?.length;

                const remainingProfiles = userHasProfile
                  ? user.profiles.length - 1
                  : 0;

                return (
                  <Table.Row key={user.id}>
                    <TableCell align="left">
                      <Link to={`/usuarios/${user.id}`}>
                        {user.extras.geraID}
                      </Link>
                    </TableCell>
                    <TableCell align="left" css={{ width: "30%" }}>
                      <Link to={`/usuarios/${user.id}`}>{user.name}</Link>
                    </TableCell>
                    <TableCell align="left" css={{ width: "20%" }}>
                      <Link to={`/usuarios/${user.id}`}>{user.email}</Link>
                    </TableCell>
                    <TableCell align="left">
                      <Link to={`/usuarios/${user.id}`}>
                        {user.createdAt
                          ? dateFormatter.format(new Date(user.createdAt))
                          : "--"}
                      </Link>
                    </TableCell>
                    <TableCell align="left">
                      <Link to={`/usuarios/${user.id}`}>
                        {user.lastLoginAt
                          ? dateFormatter.format(new Date(user.lastLoginAt))
                          : "--"}
                      </Link>
                    </TableCell>
                    <TableCell align="left">
                      <Link to={`/usuarios/${user.id}`}>
                        {userHasProfile ? (
                          <>
                            <Tag
                              variant="primary"
                              css={{
                                backgroundColor: "$backgroundAltPrimary",
                                fontSize: "$exceptionsRestricted",
                                fontWeight: "$regular",
                              }}
                              size={"medium"}
                            >
                              {user.profiles[0].name}
                            </Tag>
                            {remainingProfiles > 0 && (
                              <Tag
                                variant="primary"
                                css={{
                                  backgroundColor: "$backgroundAltPrimary",
                                  fontSize: "$exceptionsRestricted",
                                  fontWeight: "$regular",
                                }}
                                size={"medium"}
                              >
                                +{remainingProfiles}
                              </Tag>
                            )}
                          </>
                        ) : (
                          "--"
                        )}
                      </Link>
                    </TableCell>
                    <TableCell align="left" css={{ width: "11rem" }}>
                      <UserStatusSwitch
                        isActive={user.active}
                        userId={user.id}
                      />
                    </TableCell>
                  </Table.Row>
                );
              })}
            </tbody>
          </TableRoot>
          {hasMoreThanOnePage && (
            <Paginate
              nextPageDisabled={isPlaceholderData || !users.meta.hasNextPage}
              previousPageDisabled={!users.meta.hasPrevPage}
              meta={{
                next: () => {
                  setNextPage(users.meta.next);
                },
                previous: () => {
                  setPreviousPage(users.meta.previous);
                },
              }}
            />
          )}
        </>
      )}
    </Flex>
  );
}
