import { Flex } from '@grupoboticario/flora-react';

import { RupturedSimilarProduct } from './similar-product';
import { useState } from 'react';
import useRateCopilot from './hooks/useRateCopilot';
import { Copilots } from './types';
import { useMutation } from '@tanstack/react-query';
import { authService } from '@vdi/auth-service';
import { useAccountStore, useContactStore } from '@/shared/state';
import { SendMessageData, SendMessageResponse } from '@/shared/services/api/instant-messenger/service';
import { events } from '@/shared/services/events';
import TurndownService from 'turndown';
import { uploadMedia } from '@/shared/services/api/instant-messenger/service/uploadMedia';
import { RupturedProductRequested } from './ruptured-product-requested';
import { RupturedProductConfirmDeletion } from './ruptured-product-confirm-deletion';
import { sendErrorToNewRelic } from '@/shared/functions/newRelic';

export const htmlToMarkdown = (html: string): string => {
  const turndownService = new TurndownService();

  // Configure turndown options if needed
  turndownService.addRule('emphasis', {
    filter: ['em'],
    replacement: content => `_${content}_`,
  });

  turndownService.addRule('strong', {
    filter: ['strong'],
    replacement: content => `*${content}*`,
  });

  turndownService.addRule('strikethrough', {
    filter: ['u'],
    replacement: content => `~~${content}~~`,
  });

  return turndownService.turndown(html);
};

function getFileNameFromUrl(url: string) {
  const urlObj = new URL(url);
  const pathname = urlObj.pathname;
  const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
  return fileName;
}

async function getImageFileFromUrl(imageUrl: string, fileName: string): Promise<File> {
  try {
    const response = await fetch(imageUrl);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const blob = await response.blob();
    const file = new File([blob], fileName, { type: blob.type });
    return file;
  } catch (error) {
    events.error({ description: 'Erro ao enviar imagem de produto similar. Faço o envio manualmente.' });
    sendErrorToNewRelic(error, { imageUrl });
  }
}

export function RupturedProductItem({
  chatId,
  deleteRecommendation,
  rupture,
  copilotId,
  parentCopilotId,
  ruptureRecommendedMessage,
  ruptureRecommendedMessageFeedback,
}: {
  chatId: string;
  deleteRecommendation: ({ chatId, copilotId }: { chatId: string; copilotId: string }) => void;
  rupture: Copilots[number]['ruptura'];
  copilotId: Copilots[number]['id'];
  parentCopilotId: Copilots[number]['parentId'];
  ruptureRecommendedMessage: Copilots[number]['message'];
  ruptureRecommendedMessageFeedback: Copilots[number]['feedback'];
}) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [recommendedImageIsPending, setRecommendedImageIsPending] = useState(false);
  const { account } = useAccountStore();
  const { contact } = useContactStore();

  const { mutate: sendFeedback } = useRateCopilot({ copilotId });

  const {
    mutateAsync: asyncSendMessage,
    isPending: recommendedMessageIsPending,
    isSuccess: recommendedMessageSent,
  } = useMutation({
    mutationKey: [chatId, 'sendMessage'],
    mutationFn: async ({ payload }: { payload: SendMessageData }): Promise<SendMessageResponse> => {
      const { token } = await authService.getTokenOrRedirect();
      const res = await fetch(`${process.env.API_URL}/v1/accounts/${account?.id}/messages/${contact?.id}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...payload }),
      });
      return await res.json();
    },
    onSuccess(data, variables) {
      const now = new Date().toISOString();
      events.emit('MESSAGE_SENT', {
        account,
        chat: data.chat,
        contact,
        message: {
          id: data.id,
          ...variables.payload,
          contact: 'reseller',
          createdAt: now,
          from: 'vdi',
          status: 'stored',
          to: 'reseller',
          updatedAt: now,
        },
      });

      if (ruptureRecommendedMessageFeedback !== 0) {
        setTimeout(() => {
          deleteRecommendation({ chatId, copilotId });
        }, 5000);
      }
    },
  });

  async function sendNow() {
    if (rupture?.similar?.images?.extraLarge) {
      try {
        setRecommendedImageIsPending(true);

        const imageRawFile = await getImageFileFromUrl(
          rupture.similar.images.extraLarge,
          getFileNameFromUrl(rupture.similar.images.extraLarge),
        );
        const { id } = await uploadMedia({ accountId: account.id, file: imageRawFile });
        await asyncSendMessage({
          payload: {
            provider: 'whatsapp',
            type: 'image',
            image: {
              id,
            },
          },
        });
      } catch (error) {
        sendErrorToNewRelic(error);
      } finally {
        setRecommendedImageIsPending(false);
      }

      return await asyncSendMessage({
        payload: {
          provider: 'whatsapp',
          type: 'text',
          text: { body: ruptureRecommendedMessage },
        },
      });
    }

    return await asyncSendMessage({
      payload: {
        provider: 'whatsapp',
        type: 'text',
        text: { body: ruptureRecommendedMessage },
      },
    });
  }

  return (
    <Flex
      direction="column"
      css={{ backgroundColor: '$backgroundPrimary', margin: '$2', padding: '$4', borderRadius: '$medium' }}
    >
      <RupturedProductRequested
        rupturedProduct={rupture.product}
        confirmDelete={confirmDelete}
        setConfirmDelete={setConfirmDelete}
      />

      {confirmDelete ? (
        <RupturedProductConfirmDeletion
          deleteRecommendation={deleteRecommendation}
          chatId={chatId}
          copilotId={copilotId}
          setConfirmDelete={setConfirmDelete}
        />
      ) : (
        <>
          <RupturedSimilarProduct
            similarProduct={rupture.similar}
            ruptureRecommendedMessage={ruptureRecommendedMessage}
            recommendedMessageSent={recommendedMessageSent}
            ruptureRecommendedMessageFeedback={ruptureRecommendedMessageFeedback}
            sendFeedback={sendFeedback}
            chatId={chatId}
            copilotId={copilotId}
            parentCopilotId={parentCopilotId}
            sendNow={sendNow}
            recommendedMessageIsPending={recommendedMessageIsPending || recommendedImageIsPending}
          />
        </>
      )}
    </Flex>
  );
}
