import { useFeatureFlag } from 'configcat-react'
import React, { Fragment, Suspense } from 'react'

import { CSS } from '@grupoboticario/flora'

import { LoadingState } from '@shared/components'

import { OrderHistory } from './order-history'

import { afterLine } from './order-mfe/order-mfe.styles'

const OrderMfe = React.lazy(() => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - O Stargate precisa dessa função
  window.getRandomString = () => Math.random().toString(36).substring(7)
  window.history.replaceState(null, '', '/ativacao/atendimento/departamentos')
  return import('./order-mfe').catch(() => import('./order-mfe/order-mfe-error'))
})

interface OrdersProps {
  resellerId: string
}

const Orders: React.FC<OrdersProps> = ({ resellerId }) => {
  const { value: showOrderMfe } = useFeatureFlag('showOrderMfe', false)

  const loadingStyle: CSS = {
    position: 'relative',
    minHeight: '180px',
    backgroundColor: '$backgroundPrimary',
    borderRadius: '$medium',
    marginBottom: '$12',

    ...afterLine,
  }

  return (
    <Fragment>
      {showOrderMfe && (
        <Suspense fallback={<LoadingState css={loadingStyle} />}>
          <OrderMfe />
        </Suspense>
      )}

      <OrderHistory resellerId={resellerId} />
    </Fragment>
  )
}

export { Orders }
