import { useCallback, useEffect, useMemo } from 'react';
import { Box, Flex, Toaster } from '@grupoboticario/flora-react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { events, saveServiceCenter } from '@/api';
import { useServiceCenterForm } from '@/context';
import { LockAndLimit, LockAndLimitFormData } from '@/types';
import {
  FormWrapper,
  FormFieldset,
  InputSwitch,
  FormCheckbox,
  TogglableContent,
  InputNote,
  FormFooter,
} from '@/components';
import { formatLockAndLimitData, normalizeLockAndLimitFormData } from '@/components/lock-and-limit-form/utils';
import { lockAndLimitFormSchema } from '@/components/lock-and-limit-form/validators';
import { useEvents } from '@/event-tracker';
import { LockAndLimitInteraction } from '@/event-tracker/events';

const generateDefaultValues = (): LockAndLimitFormData => ({
  hasBlockAfterFirstOrder: false,
  allowToInformAvailableLimit: false,
});

const handleLockAndLimitSubmit = async (data: LockAndLimit, csId?: string) => {
  if (csId === undefined) {
    throw new Error('Service Center ID não pode ser nulo');
  }

  const formattedData = formatLockAndLimitData(data, csId);
  return saveServiceCenter(formattedData);
};

const LockAndLimitForm = (): JSX.Element => {
  const eventTracker = useEvents();
  const { state, goToNextStep, setLockAndLimit, isEditMode } = useServiceCenterForm();

  const defaultValues = useMemo(
    () => state.formData.lockAndLimit ?? generateDefaultValues(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const methods = useForm<LockAndLimitFormData>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(lockAndLimitFormSchema),
    mode: 'onBlur',
    defaultValues,
  });

  const {
    formState: { isDirty, isSubmitting },
  } = methods;

  const shouldSkipRequestOnSubmit = useMemo(
    () => !isDirty && isEditMode && !!state.formData.orderIncrement,
    [isDirty, isEditMode, state.formData.orderIncrement],
  );

  useEffect(() => {
    events.emit('UPDATE_CS_DATA', {
      isSubmitting,
    });
  }, [isSubmitting]);

  const onSubmitLockAndLimitData = useCallback(
    async (data: LockAndLimitFormData) => {
      try {
        eventTracker.lockAndLimitFormEventsSaveAndContinue();
        if (shouldSkipRequestOnSubmit) {
          goToNextStep();
          return;
        }

        const response = await handleLockAndLimitSubmit(data, state.formData.id);

        const normalizedFormData = normalizeLockAndLimitFormData(response.lockAndLimit);

        setLockAndLimit(normalizedFormData);

        goToNextStep();
      } catch (e) {
        Toaster.notify({
          kind: 'error',
          description: 'Um erro ocorreu ao salvar esta etapa. Por favor, tente novamente mais tarde.',
          origin: 'right-top',
          duration: 60000,
          button: 'Fechar',
          closeWhenClick: true,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [goToNextStep, setLockAndLimit, shouldSkipRequestOnSubmit, state.formData.id],
  );

  const hasBlockAfterFirstOrderValue = methods.watch('hasBlockAfterFirstOrder');

  return (
    <FormProvider {...methods}>
      <Box as="form" onSubmit={methods.handleSubmit(onSubmitLockAndLimitData)}>
        <FormWrapper gap="$2" css={{ marginTop: '$6' }}>
          <FormFieldset hasDivider={false}>
            <InputSwitch
              label="Bloquear cadastro após primeiro pedido?"
              registerName="hasBlockAfterFirstOrder"
              onText="Sim"
              offText="Não"
              onCheckedChange={isEnable => {
                eventTracker.togglePickUpStoreEvents({
                  isEnable,
                  value: LockAndLimitInteraction.hasBlockAfterFirstOrder,
                });
              }}
            />
            <Flex direction="column" gap="$4" css={{ paddingTop: '$4' }}>
              <FormCheckbox
                label="Desbloqueio após pagamento do primeiro pedido"
                registerName="unlockAfterPayingFirstOrder"
                id="unlock-paying-first-order"
                disabled={!hasBlockAfterFirstOrderValue}
                onClick={e => {
                  eventTracker.togglePickUpStoreEvents({
                    isEnable: (e.target as HTMLInputElement).checked,
                    value: LockAndLimitInteraction.unlockAfterPayingFirstOrder,
                  });
                }}
              />
            </Flex>
          </FormFieldset>

          <FormFieldset>
            <TogglableContent
              title="Permite informar limite de crédito disponível?"
              switchRegisterName="allowToInformAvailableLimit"
              switchEventTracker={isEnable => {
                eventTracker.togglePickUpStoreEvents({
                  isEnable,
                  value: LockAndLimitInteraction.allowToInformAvailableLimit,
                });
              }}
            />
          </FormFieldset>

          <FormFieldset>
            <InputNote registerName="note" id="note" />
          </FormFieldset>
        </FormWrapper>
        <FormFooter />
      </Box>
    </FormProvider>
  );
};

export { LockAndLimitForm };
