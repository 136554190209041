import { Flex, styled } from '@grupoboticario/flora-react'

const MIN_HEIGHT_SEARCH_CONTENT = 'calc(100vh - 82px - 250px)'

const emptyStateFixCSS = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  minHeight: MIN_HEIGHT_SEARCH_CONTENT,
  maxInlineSize: 'none !important',

  h4: {
    color: '$nonInteractivePredominant',
  },

  '> p': {
    width: '310px',
    color: '$nonInteractiveAuxiliar',
  },
}

const Form = styled('form', {
  paddingTop: '$6',
})

const Header = styled(Flex, {
  flexDirection: 'column',
  backgroundColor: '$backgroundPrimary',
  padding: '$4',
})

const inputNumberFixCSS = {
  '-moz-appearance': 'textfield',

  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
}

const SelectedAddress = styled(Flex, {
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  paddingBottom: '$6',

  '&::after': {
    content: '',
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '1px',
    backgroundColor: '$backgroundTertiary',
  },
})

export {
  emptyStateFixCSS,
  Form,
  Header,
  inputNumberFixCSS,
  MIN_HEIGHT_SEARCH_CONTENT,
  SelectedAddress,
}
