import {
  BankSlipPaymentFormData,
  CashPaymentFormData,
  CreditCardPaymentFormData,
  DebitCardPaymentFormData,
  DepositAndTransferPaymentFormData,
  OnlineCardPaymentFormData,
  PixPaymentFormData,
  PaymentMethodsFormData,
  SavePaymentMethodsRequestType,
  AcceptsInstallmentPayments,
  BankSlipPayment,
  CashPayment,
  CreditCardPayment,
  DebitCardPayment,
  DepositAndTransferPayment,
  InstallmentCondition,
  InstallmentConditionData,
  OnlineCardPaymentType,
  PaymentLocation,
  PixKey,
  PixPayment,
  RequestPaymentVoucher,
  SendPaymentVoucher,
} from '@/types';
import { getValueByCondition, removeNonDigits, formatCurrencyStringToNumber } from '@/utils';

const formatPaymentVoucherData = (data: RequestPaymentVoucher) => {
  const formattedWhatsAppVoucher = data.sendTo?.includes(SendPaymentVoucher.WHATSAPP)
    ? removeNonDigits(data.paymentVoucherWhatsapp ?? '')
    : undefined;
  const formattedEmailVoucher = data.sendTo?.includes(SendPaymentVoucher.EMAIL) ? data.paymentVoucherEmail : undefined;

  return data.requestsPaymentVoucher
    ? {
        ...data,
        paymentVoucherWhatsapp: formattedWhatsAppVoucher,
        paymentVoucherEmail: formattedEmailVoucher,
      }
    : ({
        sendTo: [],
        specifications: [],
        paymentVoucherEmail: undefined,
        paymentVoucherWhatsapp: undefined,
      } as RequestPaymentVoucher);
};

const formatInstallmentOptions = (conditions: InstallmentConditionData[] | undefined) => {
  if (conditions === undefined || conditions.length === 0) {
    return undefined;
  }

  return conditions.map(condition => ({
    ...condition,
    value:
      condition.condition === InstallmentCondition.INDEPENDENT || condition.value === undefined
        ? undefined
        : formatCurrencyStringToNumber(condition.value),
  }));
};

const formatInstallmentConditionsData = ({
  hasInstallmentOptions,
  installmentOptions,
  hasMinimumInstallmentValue,
  minimumInstallmentValue,
}: AcceptsInstallmentPayments) => {
  return {
    installmentOptions: getValueByCondition(hasInstallmentOptions, formatInstallmentOptions(installmentOptions)),
    minimumInstallmentValue: getValueByCondition(
      hasMinimumInstallmentValue && minimumInstallmentValue !== undefined,
      formatCurrencyStringToNumber(minimumInstallmentValue),
    ),
  };
};

const formatPaymentLocations = ({ paymentLocations }: PaymentLocation) => ({
  paymentLocations: getValueByCondition(!!paymentLocations?.length, paymentLocations),
});
const formatBankSlipFormData = (data: BankSlipPaymentFormData): BankSlipPayment => {
  return data.isEnabled
    ? {
        ...data,
        ...formatInstallmentConditionsData(data),
      }
    : {
        isEnabled: false,
        hasInstallmentOptions: false,
        hasMinimumInstallmentValue: false,
      };
};

const formatCreditCardPaymentFormData = (data: CreditCardPaymentFormData): CreditCardPayment => {
  return data.isEnabled
    ? {
        ...data,
        ...formatInstallmentConditionsData(data),
        ...formatPaymentLocations(data),
      }
    : {
        isEnabled: false,
        hasInstallmentOptions: false,
        hasMinimumInstallmentValue: false,
      };
};

const formatCashOrDebitCardPaymentFormData = (
  data: CashPaymentFormData | DebitCardPaymentFormData,
): CashPayment | DebitCardPayment => {
  return data.isEnabled ? { ...data, ...formatPaymentLocations(data) } : { isEnabled: false };
};

const formatDepositAndTransferPaymentFormData = (
  data: DepositAndTransferPaymentFormData,
): DepositAndTransferPayment => {
  const formattedDepositAndTransferPaymentData = {
    accountNumber: removeNonDigits(data.accountNumber ?? ''),
    cpfCnpj: removeNonDigits(data.cpfCnpj ?? ''),
    ...formatPaymentVoucherData(data),
  };

  return data.isEnabled
    ? {
        ...data,
        ...formattedDepositAndTransferPaymentData,
      }
    : { isEnabled: false, requestsPaymentVoucher: false };
};

const formatOnlineCardPaymentFormData = (data: OnlineCardPaymentFormData): OnlineCardPaymentType => {
  return data.isEnabled ? { ...data } : { isEnabled: false };
};

const formatPixPaymentFormData = (data: PixPaymentFormData): PixPayment => {
  const pixKeyTypeMap = {
    'cpf-cnpj': removeNonDigits(data.cpfCnpjValue ?? ''),
    'phone-number': removeNonDigits(data.phoneNumberValue ?? ''),
    email: data.emailValue,
    random: data.randomValue,
  };

  return data.isEnabled
    ? {
        ...data,
        ...formatPaymentVoucherData(data),
        pixKeyValue: pixKeyTypeMap[data.keyType as PixKey],
      }
    : { isEnabled: false, requestsPaymentVoucher: false };
};

export const formatPaymentMethodsFormData = (
  formData: PaymentMethodsFormData,
  id: string,
): SavePaymentMethodsRequestType => ({
  _id: id,
  step: '7',
  paymentMethods: {
    bankSlipPayment: formatBankSlipFormData(formData.bankSlipPayment),
    cashPayment: formatCashOrDebitCardPaymentFormData(formData.cashPayment),
    creditCardPayment: formatCreditCardPaymentFormData(formData.creditCardPayment),
    debitCardPayment: formatCashOrDebitCardPaymentFormData(formData.debitCardPayment),
    depositAndTransferPayment: formatDepositAndTransferPaymentFormData(formData.depositAndTransferPayment),
    onlineCardPayment: formatOnlineCardPaymentFormData(formData.onlineCardPayment),
    pixPayment: formatPixPaymentFormData(formData.pixPayment),
  },
});
