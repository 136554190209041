import { Flex } from '@grupoboticario/flora-react';

import { useWatch } from 'react-hook-form';
import { DropdownItemsProps, ControlledSelect, FormInput, FormFieldset, InputNote } from '@/components/shared';
import { PixKey, pixKeyTypeValueLabelMap } from '@/types';
import { formatCpfCnpjInputMask, formatRandomPixKeyMask } from '@/utils';
import { PaymentHeader, PaymentVoucher } from '@/components/payment-methods-form';

import { useEvents } from '@/event-tracker';
import { PaymentMethodsInteraction } from '@/event-tracker/events';

interface DropdownItem extends DropdownItemsProps {
  value: PixKey;
}

const dropdownItems: DropdownItem[] = [
  {
    key: 'pix-cpf-cnpj',
    value: PixKey.CPF_CNPJ,
    name: pixKeyTypeValueLabelMap[PixKey.CPF_CNPJ],
  },
  {
    key: 'pix-phone-number',
    value: PixKey.PHONE_NUMBER,
    name: pixKeyTypeValueLabelMap[PixKey.PHONE_NUMBER],
  },
  {
    key: 'pix-email',
    value: PixKey.EMAIL,
    name: pixKeyTypeValueLabelMap[PixKey.EMAIL],
  },
  {
    key: 'pix-random',
    value: PixKey.RANDOM,
    name: pixKeyTypeValueLabelMap[PixKey.RANDOM],
  },
];

const PixPayment = (): JSX.Element => {
  const eventTracker = useEvents();
  const keyType: PixKey = useWatch({ name: 'pixPayment.keyType' });
  const pixKeyValue = useWatch({ name: 'pixPayment.cpfCnpjValue' });

  const keyTypeToInputMap = {
    'cpf-cnpj': {
      id: 'pix-cpf-cnpj-value',
      labelText: 'CPF / CNPJ:',
      registerName: 'pixPayment.cpfCnpjValue',
      placeholder: 'Somente números',
      mask: formatCpfCnpjInputMask(pixKeyValue),
    },
    email: {
      id: 'pix-email-value',
      labelText: 'E-mail:',
      registerName: 'pixPayment.emailValue',
      placeholder: 'exemplo@email.com.br',
    },
    'phone-number': {
      id: 'pix-celular-value',
      labelText: 'Celular:',
      registerName: 'pixPayment.phoneNumberValue',
      placeholder: '(00) 00000-0000',
      mask: '(xx) xxxxx-xxxx',
    },
    random: {
      id: 'pix-random-value',
      labelText: 'Aleatória:',
      registerName: 'pixPayment.randomValue',
      placeholder: 'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA',
      mask: (value: string) => formatRandomPixKeyMask(value),
    },
  };

  return (
    <Flex direction="column">
      <PaymentHeader />
      <ControlledSelect
        registerName="pixPayment.keyType"
        dropdownItems={dropdownItems}
        id="pixPayment-keyType"
        labelText="Tipo de chave:"
        optionalText="Selecione"
        onChange={e => eventTracker.pixPaymentKeyType(e.target.value)}
      />
      {keyTypeToInputMap[keyType] && <FormInput key={keyTypeToInputMap[keyType].id} {...keyTypeToInputMap[keyType]} />}
      <PaymentVoucher parentRegisterName="pixPayment" trackerEventName={PaymentMethodsInteraction.pixPaymentSelect} />
      <FormFieldset>
        <InputNote registerName="pixPayment.note" id="pixPaymentNote" />
      </FormFieldset>
    </Flex>
  );
};

export { PixPayment };
