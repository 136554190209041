import { useMemo } from 'react';
import { ChatTagType } from '../components/ChatTagsMenu/ChatTagsMenu';

const TAG_COLORS = [
  '#FF01B1', // pink
  '#FF0801', // red
  '#C70039', // crimson
  '#FF5501', // orange
  '#E29500', // gold
  '#FFD700', // yellow
  '#A7B820', // olive
  '#6CD115', // lime
  '#20AE43', // green
  '#856F00', // brown
  '#1FA685', // teal
  '#00FFFF', // cyan
  '#49B1BF', // blue
  '#4682B4', // steel blue
  '#1B57CE', // dark blue
  '#7E15E3', // purple
  '#800080', // Purple
  '#581845', // dark magenta
  '#2F4F4F', // dark slate gray
  '#D3D3D3', // light gray
];

function checkAvaliableTagColors(tagList: any): string[] {
  if (!tagList) {
    return TAG_COLORS;
  }
  const usedColors = tagList?.map(tag => tag.color);
  return TAG_COLORS.filter(color => !usedColors?.includes(color));
}

export function useAvailableColors(tagList: ChatTagType[]) {
  const availableColors = useMemo(() => checkAvaliableTagColors(tagList), [tagList]);
  return availableColors;
}
