import { Flex } from '@grupoboticario/flora-react';
import { FormFieldset, InputNote } from '@/components/';
import { InstallmentOptions, PaymentHeader, PaymentLocations } from '@/components/payment-methods-form/';
import { useEvents } from '@/event-tracker';
import { CreditCardPaymentInteraction, PaymentMethodsInteraction } from '@/event-tracker/events';

export const CreditCardPayment = () => {
  const eventTracker = useEvents();
  return (
    <Flex direction="column">
      <PaymentHeader />
      <PaymentLocations
        parentRegisterName="creditCardPayment"
        trackerEventName={PaymentMethodsInteraction.creditCardPayment}
      />
      <InstallmentOptions
        parentRegisterName="creditCardPayment"
        acceptInstallmentsLabel="Aceita parcelamento?"
        eventTrackerInstallmentOption={isEnable => {
          eventTracker.togglePaymentMethodsEvents({
            isEnable,
            value: CreditCardPaymentInteraction.paymentNote,
          });
        }}
        eventTrackerMinimumInstallmentValue={isEnable => {
          eventTracker.togglePaymentMethodsEvents({
            isEnable,
            value: CreditCardPaymentInteraction.minimumInstallmentValue,
          });
        }}
      />
      <FormFieldset>
        <InputNote registerName="creditCardPayment.note" id="creditCardPaymentNote" />
      </FormFieldset>
    </Flex>
  );
};
