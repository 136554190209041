import { useEvents } from '@/shared';
import { useAssistantMailingStore } from '@/store/assistant';
import {
  Grid,
  Flex,
  Pagination,
  FloraTypography as Text,
  Dropdown,
  DropdownItem,
} from '@grupoboticario/flora-react';
import { ChangeEvent } from 'react';
import { useShallow } from 'zustand/react/shallow';

const displayTotalResults = (page: number, limit: number) => {
  const endTotalResults = (page + 1) * limit;
  return endTotalResults - (limit - 1);
};

type PageMetaType = {
  totalDocs?: number;
};

export const TableFooter = () => {
  const events = useEvents();
  const { tableState } = useAssistantMailingStore(useShallow((state) => state.tableMailing));
  const { pageIndex, pageSize } = tableState?.getState().pagination;
  const initialTotalResults = displayTotalResults(pageIndex, pageSize);
  const totalVisibleRecords = tableState.getRowModel().rows.length;
  const hasPagination = totalVisibleRecords > 1;
  const totalRecords: PageMetaType = tableState.options.meta;
  const resultText = totalRecords.totalDocs === 1 ? 'resultado' : 'resultados';

  const onChangePagination = (page: number) => {
    tableState.resetRowSelection();
    tableState.setPageIndex(page);
    events.interactionClickPagination();
  };

  const onChangePageSize = (e: ChangeEvent<HTMLInputElement>) => {
    tableState.setPageSize(Number(e.target.value));
    tableState.resetRowSelection();
    tableState.setPageIndex(0);
  };

  return (
    <Grid
      templateColumns='repeat(3, auto)'
      align='center'
      css={{
        padding: '$5 $6',
      }}
    >
      <Text
        fontSize='bodySmallShort'
        data-testid='page-results'
        color='$nonInteractivePredominant'
        css={{
          gridColumn: !hasPagination ? 'span 2' : 'unset',
        }}
      >
        {`Exibindo ${initialTotalResults} - ${totalVisibleRecords} de ${totalRecords.totalDocs} ${resultText}`}
      </Text>

      {hasPagination && (
        <Flex justify='center'>
          <Pagination
            css={{
              padding: '$2',
            }}
            itemsPerPage={pageSize}
            onChange={onChangePagination}
            page={pageIndex}
            totalRecords={totalRecords.totalDocs}
          />
        </Flex>
      )}
      <Flex justify='end'>
        <Dropdown
          id='dropdown-1'
          as='div'
          labelText='Linhas'
          value={pageSize}
          onChange={onChangePageSize}
          css={{
            width: '100px',
            '&  div': {
              minHeight: 'auto',
            },
          }}
        >
          <DropdownItem value={10}>10</DropdownItem>
          <DropdownItem value={50}>50</DropdownItem>
          <DropdownItem value={100}>100</DropdownItem>
        </Dropdown>
      </Flex>
    </Grid>
  );
};
