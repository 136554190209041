import { FloraButton, FloraInput, FloraTypography } from '@grupoboticario/flora-react';
import { PencilIcon, TrashIcon } from '@grupoboticario/flora-react-icons';
import CreateModal from '@/shared/components/Modal';
import { TagColorsContainer } from './TagColorsContainer';
import { useEditTag } from '../../hooks/useEditTag';
import { ChatTagType } from './ChatTagsMenu';

export type NewTagType = {
  name: string;
  color: string;
  description: string;
};

type TagItemProps = {
  tag: ChatTagType;
  setNewTag: any;
  setTagList: any;
  colors: string[];
};

export function EditTagMenu({ tag, setNewTag, setTagList, colors }: TagItemProps) {
  const {
    confirmingDelete,
    setConfirmingDelete,
    selectedName,
    selectedColor,
    setSelectedColor,
    handleSubmit,
    handleNameChange,
  } = useEditTag(tag, setTagList, setNewTag);

  const editingColors = [tag.color, ...colors];

  return (
    <CreateModal
      trigger={
        <FloraButton
          size="small"
          hierarchy="tertiary"
          css={{ margin: '0px 5px' }}
          has="iconOnly"
          icon={<PencilIcon size="15" color={tag.userId ? '$9' : '$disabled'} css={{ cursor: 'pointer' }} />}
          onClick={e => {
            e.stopPropagation();
          }}
          disabled={!tag.userId}
        />
      }
      submit={() => handleSubmit()}
      darkButton={confirmingDelete ? 'cancel' : 'submit'}
      onCancel={() => setConfirmingDelete(false)}
      submitLabel="Confirmar"
      header={confirmingDelete ? 'Apagar etiqueta' : 'Editar etiqueta'}
      body={
        confirmingDelete ? (
          <FloraTypography fontSize="bodySmallStandard" fontWeight="medium" css={{ marginTop: '$7' }}>
            Ao apagar uma etiqueta, você irá removê-la de todos os contatos que a possuem e também da lista de
            etiquetas. <br />
            Essa ação é{' '}
            <FloraTypography as="span" fontSize="bodySmallStandard" fontWeight="bold">
              irreversível
            </FloraTypography>
            .<br />
            Você tem certeza de que deseja apagar esta etiqueta?
          </FloraTypography>
        ) : (
          <>
            <FloraInput
              id="new-tag-name"
              label="Novo nome da etiqueta:"
              value={selectedName}
              placeholder={tag.name}
              labelPosition="external"
              onChange={e => handleNameChange(e.target.value)}
              required={true}
            />
            <TagColorsContainer colors={editingColors} color={selectedColor} setColor={setSelectedColor} />
            <FloraButton
              size="small"
              hierarchy="tertiary"
              disabled={!tag.userId}
              onClick={() => setConfirmingDelete(true)}
              has="iconLeft"
              icon={<TrashIcon size="15" />}
              styleSemantic="warning"
              isFull="true"
            >
              Apagar etiqueta
            </FloraButton>
          </>
        )
      }
    />
  );
}
