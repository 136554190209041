import { ChangeEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button, Flex, Input, Toaster } from "@grupoboticario/flora-react";
import { CheckCircleIcon } from "@grupoboticario/flora-react-icons";

import { NewMemberList, UserDataSelected } from "@features/NewMemberList";
import {
  SearchDropdownUser,
  useSearchDropdownUser,
} from "@features/SearchDropdownUser";
import { SearchProvider } from "@shared/components/Search/useSearch";
import { useCreateTeam } from "./useCreateTeam";
import { Team } from "@shared/types";

export function TeamForm() {
  const navigate = useNavigate();

  const [groupName, setGroupName] = useState<string>("");
  const groupNameIsEmpty = groupName === "";

  const { selectedUsers, setSelectedUsers } = useSearchDropdownUser();

  const { mutate: createTeam, isPending } = useCreateTeam();

  function handleGroupNameChange(event: ChangeEvent<HTMLInputElement>) {
    setGroupName(event.target.value);
  }

  function handleRemoveUserFromList(user: UserDataSelected) {
    const removeSelectedUser = selectedUsers.filter(
      (selectedUser) => selectedUser.id !== user.id,
    );

    setSelectedUsers(removeSelectedUser);
  }

  function handleAddNewTeam() {
    const selectedUsersId = selectedUsers.map((user) => user.id);

    createTeam(
      { groupName, owners: selectedUsersId },
      {
        onSuccess: (team: Team) => {
          Toaster.notify({
            kind: "success",
            description: "Equipe criada com sucesso!",
            origin: "right-top",
            Icon: <CheckCircleIcon />,
          });

          setTimeout(() => {
            navigate(`/equipes/${team.id}`);
          }, 3000);
        },
        onError: () => {
          Toaster.notify({
            kind: "error",
            description: "Erro ao criar equipe",
            origin: "right-top",
          });
        },
      },
    );
  }

  return (
    <Flex
      direction={"column"}
      gap={"$6"}
      css={{ width: "100%", maxWidth: "50rem" }}
    >
      <Input
        id="name"
        name="groupName"
        value={groupName}
        onChange={handleGroupNameChange}
        label="Nome:"
        placeholder="digite o nome da equipe"
        status={groupNameIsEmpty ? "error" : "success"}
        helperText="Nome da equipe é obrigatório"
        required
      />
      <SearchProvider>
        <SearchDropdownUser placeholder="Digite o nome ou e-mail ou código VD+ do responsável para buscar" />
      </SearchProvider>
      <NewMemberList
        newMembers={selectedUsers}
        onRemove={handleRemoveUserFromList}
      />
      <Flex gap={"$4"} justify={"center"}>
        <Button
          as={Link}
          to="/equipes/"
          hierarchy={"secondary"}
          css={{ padding: "$4 $22" }}
        >
          Cancelar
        </Button>
        <Button
          hierarchy={"primary"}
          disabled={selectedUsers.length === 0 || groupNameIsEmpty}
          css={{ padding: "$4 $22" }}
          onClick={() => handleAddNewTeam()}
          isLoading={isPending}
        >
          Criar equipe
        </Button>
      </Flex>
    </Flex>
  );
}
