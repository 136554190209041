import { Button, Flex, Text } from "@grupoboticario/flora-react";
import { useTeam } from "./useTeam";
import { Link, useParams } from "react-router-dom";
import {
  BoxContent,
  CardBox,
  CardInfo,
  TeamNameContainer,
  TeamRoleContainer,
  TextRole,
  TextRoleEmail,
  TextRoleLabel,
} from "./TeamInfo.styles";

export function TeamInfo() {
  const { teamId } = useParams();
  const { data: teamData } = useTeam(teamId);
  return (
    teamData && (
      <>
        <TeamNameContainer data-testid="team-info-container">
          <Text
            css={{
              color: "$nonInteractivePredominant",
              fontSize: "$bodyLarge",
              fontWeight: "$medium",
            }}
          >
            {teamData?.name}
          </Text>
          <Button
            css={{ display: "none" }}
            as={Link}
            to={`/equipes/${teamId}/editar`}
            hierarchy="secondary"
            styleSemantic="neutral"
          >
            Editar informações gerais
          </Button>
        </TeamNameContainer>

        <TeamRoleContainer>
          <CardInfo>
            <CardBox>
              <BoxContent>
                <TextRoleLabel>Responsável</TextRoleLabel>
                <Flex gap="$4" direction="column">
                  {teamData.responsibles.map((responsible) => (
                    <Flex key={responsible.id} direction="column">
                      <TextRole>{responsible?.name ?? "--"}</TextRole>
                      <TextRoleEmail>{responsible?.email ?? ""}</TextRoleEmail>
                    </Flex>
                  ))}
                </Flex>
              </BoxContent>
            </CardBox>
          </CardInfo>
        </TeamRoleContainer>
      </>
    )
  );
}
