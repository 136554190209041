import { Box, Flex, FloraTypography as Text, TagProps } from '@grupoboticario/flora-react';
import { FilterTag } from '../tags';
import { useAssistantMailingStore } from '@/store/assistant';

interface FilterTagResultType {
  name: string;
  key: string;
}

interface FilterTagProps {
  filterTagResult: FilterTagResultType[];
  onChangeFilter?: (filters: string[]) => void;
  variant?: TagProps['variant'];
}

export const FilterTagList = ({ filterTagResult, onChangeFilter, variant }: FilterTagProps) => {
  const { tableState } = useAssistantMailingStore((state) => state.tableMailing);
  const handleDelete = (filterToDelete: FilterTagResultType) => {
    if (!onChangeFilter && !!filterTagResult) {
      return;
    }
    const updatedFilter = filterTagResult
      .filter((filter: FilterTagResultType) => filter.key !== filterToDelete.key)
      .map((filter) => filter.key);
    onChangeFilter(updatedFilter);
    tableState?.setPageIndex(0);
  };

  const uniqueFilterTagResult = filterTagResult?.reduce((acc, current) => {
    const filter = acc.find((item) => item.name === current.name);
    return filter ? acc : acc.concat([current]);
  }, []);

  return (
    <Flex css={{ width: '100%' }} justify='space-between'>
      <Box>
        {!!filterTagResult?.length ? (
          <>
            <Text
              as='p'
              fontSize='exceptionsAuxiliar'
              color='$nonInteractiveAuxiliar'
              css={{ paddingBottom: '$2', fontWeight: '500' }}
            >
              Filtros aplicados
            </Text>
            <Flex align='center' gap='$2' wrap='wrap'>
              {uniqueFilterTagResult.map((filter: FilterTagResultType) => (
                <FilterTag
                  key={filter.key}
                  filterName={filter.name}
                  handleDelete={() => handleDelete(filter)}
                  variant={variant}
                />
              ))}
            </Flex>
          </>
        ) : null}
      </Box>
    </Flex>
  );
};
