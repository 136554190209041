import InfiniteScroll from 'react-infinite-scroll-component';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useReadAllNotificationsFromAGroup } from '@vdi/navbar';
import { Loading } from '@/shared/components';
import { Account, Chat, ChatMessage, Reseller } from '@/shared/types/interfaces';
import { getChats } from '@/shared/services/api/instant-messenger/service/getChats';
import { events } from '@/shared/services/events';
import { ChatItem, ChatsListWrapper } from './components';
import {
  MessageReactionReceived,
  MessageReceived,
  MessageSent,
  ServiceRequestReceived,
} from '@/shared/services/api/instant-messenger/types';
import { useFetchTagList } from './hooks';
import { useFixedScroll } from '@/shared/hooks';
import { useChatStore, useContactStore, useAccountStore } from '@/shared/state';
import { sendErrorToNewRelic } from '@/shared/functions/newRelic';
import { useIsFetching } from '@tanstack/react-query';

export const ChatList = () => {
  /**
   * Mutate function que vem do navbar para marcar todas as notificações de um grupo como lidas
   */
  const { mutate: readAllNotificationsFromAGroup } = useReadAllNotificationsFromAGroup();

  /**
   * Estados globais
   */
  const { account } = useAccountStore();
  const { chat, setChat } = useChatStore();
  const { setContact } = useContactStore();

  /**
   * Requests
   */
  const { data: tags, isLoading: isLoadingTags } = useFetchTagList({ enabled: account?.type === 'reseller' });
  const isFetchingAccounts = useIsFetching({ queryKey: ['accounts'] });

  /**
   * Estados locais
   */
  const [chats, setChats] = useState<Array<Chat>>([]);
  const [nextPageId, setNextPageId] = useState<string | undefined>();
  const [isLoadingChats, setIsLoadingChats] = useState(false);
  const isFirstFetch = useRef(true);
  const chatListEl = useRef<HTMLDivElement>(null);

  const fetchData = useCallback(async () => {
    try {
      setIsLoadingChats(true);
      const res = await getChats(nextPageId);
      setChats(prevChats => [...prevChats, ...res.data]);
      setNextPageId(res.meta?.next);
    } catch (error) {
      events.error({ description: 'Erro ao consultar suas conversas' });
      sendErrorToNewRelic(error);
    } finally {
      isFirstFetch.current = false;
      setIsLoadingChats(false);
    }
  }, [nextPageId]);

  /**
   * Carregamento inicial de chats do usuário
   */
  useEffect(() => {
    fetchData();
  }, []);

  useFixedScroll({ el: chatListEl, hasMore: nextPageId, fetchData });

  function receiveMessageHandler(event: MessageReceived<ChatMessage> | MessageSent, chatStatus?: Chat['status']): Chat {
    const status: Chat['status'] = chatStatus || (event.chat.id !== chat?.id ? 'unread' : 'read');
    const receivedChat = {
      id: event.chat.id,
      account: event.account,
      contact: event.contact,
      message: event.message,
      status,
    };

    setChats(prevChats => {
      const chatIndex = prevChats.findIndex(c => c.id === event.chat.id);
      const updatedChats =
        chatIndex === -1
          ? [receivedChat, ...prevChats]
          : [receivedChat, ...prevChats.slice(0, chatIndex), ...prevChats.slice(chatIndex + 1)];
      return updatedChats;
    });

    return receivedChat;
  }

  /**
   * Recebimento de mensagens em tempo real
   * Atualiza o item da lista com a informação mais recente
   */
  useEffect(() => {
    function handleMessageReceived(event: MessageReceived<ChatMessage>) {
      receiveMessageHandler(event);

      // Seleciona o próprio contato para atualizar o campo de janela WhatsApp
      if (chat?.id === event.chat.id) {
        setContact(event.contact);
      }
    }

    function handleMessageSent(event: MessageSent) {
      const eventChat = receiveMessageHandler(event, 'read');

      // Caso seja enviada uma mensagem para um contato que ainda não está com o chat ativo, setamos o chat
      if (chat?.id !== eventChat.id) {
        setChat(eventChat);
      }
    }

    function handleReactionReceived(event: MessageReactionReceived) {
      if (!event.message.reaction.emoji) {
        return;
      }

      receiveMessageHandler({
        ...event,
        message: {
          type: 'reaction',
          createdAt: event.message.reaction.date,
          emoji: event.message.reaction.emoji,
        },
      });
    }

    function handleServiceRequest(event: ServiceRequestReceived) {
      receiveMessageHandler({
        ...event,
        message: {
          type: 'service-request',
          createdAt: event.service.createdAt,
        },
      });
    }

    events.on('MESSAGE_RECEIVED', handleMessageReceived);
    events.on('MESSAGE_REACTION_RECEIVED', handleReactionReceived);
    events.on('MESSAGE_SENT', handleMessageSent);
    events.on('SERVICE_REQUEST_RECEIVED', handleServiceRequest);
    return () => {
      events.off('MESSAGE_RECEIVED', handleMessageReceived);
      events.off('MESSAGE_REACTION_RECEIVED', handleReactionReceived);
      events.off('MESSAGE_SENT', handleMessageSent);
      events.off('SERVICE_REQUEST_RECEIVED', handleServiceRequest);
    };
  }, [chats, chat?.id]);

  function patchGroupOfMessagesAsRead(chatStatus: Chat['status'], accountId: Account['id'], vdId: Reseller['vdId']) {
    if (chatStatus === 'read') {
      return;
    }

    const CONTACT_NOTIFICATION_GROUP = `${accountId}-${vdId}`;
    readAllNotificationsFromAGroup({ groupId: CONTACT_NOTIFICATION_GROUP });
  }

  function setChatAsRead(chat: Chat) {
    setChats(prevChats => prevChats.map(c => (c.contact.id === chat.contact.id ? { ...c, status: 'read' } : c)));
  }

  const onChatItemClick = useCallback(
    (c: Chat) => {
      if (c.id !== chat?.id) {
        setChatAsRead(c);
        patchGroupOfMessagesAsRead(c.status, c.account.id, c.contact.vdId);
        setContact(c.contact);
        setChat(c);
      }
    },
    [chat?.id],
  );

  if ((isLoadingChats && isFirstFetch.current) || isLoadingTags || isFetchingAccounts) {
    return <Loading css={{ width: '100%', height: '100%' }} />;
  }

  return (
    <ChatsListWrapper id="reseller-list" role="listbox" aria-label="results list" ref={chatListEl}>
      <InfiniteScroll
        dataLength={chats.length}
        next={fetchData}
        hasMore={nextPageId !== undefined}
        loader={<Loading css={{ width: '100%', margin: '$4 0' }} />}
        scrollableTarget="reseller-list"
        hasChildren
      >
        {chats.map((c, index) => (
          <ChatItem
            key={`${c.account.id}_${c.contact.id}`}
            chat={c}
            tabIndex={index}
            selected={c.id === chat?.id}
            tags={tags?.data || []}
            onClick={() => {
              onChatItemClick(c);
            }}
          />
        ))}
      </InfiniteScroll>
    </ChatsListWrapper>
  );
};
