import { Flex, FloraTypography } from '@grupoboticario/flora-react';
import { FormCheckbox, FormFieldset, InputSwitch, InputNote } from '@/components/';
import { InstallmentOptions, PaymentHeader } from '@/components/payment-methods-form/';
import { useFormContext } from 'react-hook-form';
import { CheckboxDataType } from '@/components/pick-up-store-form/constants/checkboxValues';
import { useEvents } from '@/event-tracker';
import { BankSlipPaymentInteraction, PaymentMethodsInteraction } from '@/event-tracker/events';

const checkboxes: CheckboxDataType[] = [
  {
    label: 'A vista',
    id: 'pagamento-a-vista',
    value: 'bankSlipPayment.hasPaymentCash',
  },
  {
    label: '30 dias',
    id: 'pagamento-30-dias',
    value: 'bankSlipPayment.hasThirtyDaysPayment',
  },
];

const BankSlipPayment = () => {
  const eventTracker = useEvents();
  const { getFieldState, formState } = useFormContext();
  const fieldState = getFieldState('bankSlipPayment', formState);

  return (
    <Flex direction="column">
      <PaymentHeader />
      <Flex direction="column" gap="$4">
        {checkboxes.map(cb => (
          <FormCheckbox
            key={cb.id}
            label={cb.label}
            registerName={`${cb.value}`}
            id={cb.id}
            data-testid={cb.id}
            onClick={e => {
              eventTracker.togglePaymentMethodsEvents({
                isEnable: (e.target as HTMLInputElement).checked,
                value: `${PaymentMethodsInteraction.bankSlipPayment}-${cb.id}`,
              });
            }}
          />
        ))}
        {fieldState.invalid && (
          <FloraTypography fontSize="exceptionsAuxiliar" fontWeight="bold" color="$1">
            {fieldState.error?.message}
          </FloraTypography>
        )}
      </Flex>

      <FormFieldset>
        <InputSwitch
          label="Consome o crédito disponível?"
          registerName="bankSlipPayment.consumesAvailableCredit"
          onText="Sim"
          offText="Não"
          onCheckedChange={isEnable => {
            eventTracker.togglePaymentMethodsEvents({
              isEnable,
              value: BankSlipPaymentInteraction.consumesAvailableCredit,
            });
          }}
        />
      </FormFieldset>

      <InstallmentOptions
        parentRegisterName="bankSlipPayment"
        acceptInstallmentsLabel="Outras opções de parcelamento?"
        eventTrackerInstallmentOption={isEnable => {
          eventTracker.togglePaymentMethodsEvents({
            isEnable,
            value: BankSlipPaymentInteraction.note,
          });
        }}
        eventTrackerMinimumInstallmentValue={isEnable => {
          eventTracker.togglePaymentMethodsEvents({
            isEnable,
            value: BankSlipPaymentInteraction.minimumInstallmentValue,
          });
        }}
      />

      <FormFieldset>
        <InputNote registerName="bankSlipPayment.note" id="bankSlipPaymentNote" />
      </FormFieldset>
    </Flex>
  );
};

export { BankSlipPayment };
