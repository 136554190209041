import { Search } from "@shared/components/Search";
import { useSearch } from "@shared/components/Search/useSearch";
import { useSearchUser } from "./useSearchUser";
import { useEffect } from "react";
import { useSearchDropdownUser } from "./useSearchDropdownUser";

interface SearchDropdownUserProps {
  placeholder: string;
}

export function SearchDropdownUser({ placeholder }: SearchDropdownUserProps) {
  const { searchTerm } = useSearch();

  const { options, setOptions, selectedUsers, setSelectedUsers } =
    useSearchDropdownUser();

  const { data: searchUserData, isLoading } = useSearchUser(searchTerm);

  function handleSelectOption(option: string) {
    if (!option) {
      return;
    }

    const email = option.split(" - ")[1];

    const selectedUser = searchUserData.data.find(
      (user) => user.email === email,
    );

    if (!selectedUser) {
      return;
    }

    setSelectedUsers([...selectedUsers, selectedUser]);
  }

  useEffect(() => {
    function setDataOptions() {
      if (searchUserData?.data?.length > 0) {
        const newOptions = searchUserData.data.map(
          (option) => `${option.name} - ${option.email}`,
        );

        setOptions(newOptions);
      }
    }

    setDataOptions();
  }, [searchUserData, setOptions]);

  return (
    <Search
      placeholder={placeholder}
      onSelectOption={handleSelectOption}
      options={options}
      isLoading={isLoading}
    />
  );
}
