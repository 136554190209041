import { fetcher } from '@/shared/services/api/fetcher';
import { TagResponse } from './postTagOnList';

type UpdateTagData = {
  name: string;
  description?: string;
  color: string;
};

export async function updateTag(id: string, body: UpdateTagData): Promise<TagResponse> {
  const res = await fetcher.patch(`v1/tags/${id}`, {
    json: body,
  });
  return res.json();
}
