import { authService } from '@vdi/auth-service';
import { styled, Container, FloraTypography } from '@grupoboticario/flora-react';
import { useEffect } from 'react';
import { sendErrorToNewRelic } from '@/shared/functions/newRelic';

const AppContainerWrapper = styled(Container, {
  maxWidth: '100%',
  minHeight: '100dvh',
  backgroundColor: '$backgroundPrimary',
  padding: 0,
}) as typeof Container;

export function NoAccountSet() {
  useEffect(() => {
    sendErrorToNewRelic(new Error('no-account-set'), { vdId: authService.userData.id });
  }, []);

  return (
    <AppContainerWrapper
      css={{ display: 'flex', flexFlow: 'column nowrap', alignItems: 'center', justifyContent: 'center' }}
    >
      <FloraTypography as="p" fontSize="title" fontWeight="medium">
        Conta não configurada
      </FloraTypography>
      <FloraTypography as="p" fontSize="bodyLargeStandard" fontWeight="medium">
        Entre em contato pelo canal do Slack #fale-com-vdi para configurar a sua conta.
      </FloraTypography>
      <FloraTypography
        as="p"
        fontSize="exceptionsAuxiliar"
        fontWeight="medium"
        css={{ textAlign: 'center', marginTop: '$4' }}
      >
        Envie o código abaixo para o time de suporte. <br />
        <span style={{ fontFamily: 'monospace', userSelect: 'all' }}>no-account-set_{authService.userData.id}</span>
      </FloraTypography>
    </AppContainerWrapper>
  );
}
