import { DashboardGrid, DashboardSectionTitle, IndicatorCard, Table } from '@/shared/components';
import { useGetDashboardData } from '@/shared/hooks';
import { gridSettings, gridSettingsDigitalBase } from './grid-settings';
import { ArrowDownIcon, CrossCircleIcon, SquarePlusMinusIcon, TwoPeopleIcon } from '@grupoboticario/flora-react-icons';
import { decimalNoFraction, percent, setDisplayedValue } from '@/shared/format';

export function BaseProfileDashboard() {
  const { data, isLoading } = useGetDashboardData({ queryKey: 'base', path: '/base/dashboard' });

  return (
    <>
      <DashboardGrid css={gridSettings}>
        <IndicatorCard
          title="Base total"
          tooltip="Quantidade de revendedores que fizeram, pelo menos, um pedido em até 6 ciclos, incluindo Inícios e Reinícios."
          mainValue={setDisplayedValue(data?.baseProfile?.totalBase?.value, decimalNoFraction)}
          icon={<TwoPeopleIcon />}
          isLoading={isLoading}
          gridArea="total-base"
        />
        <IndicatorCard
          title="Base disponível"
          tooltip="Quantidade de revendedores da Base Total que não estão bloqueados sistemicamente."
          mainValue={setDisplayedValue(data?.baseProfile?.available?.value, decimalNoFraction)}
          isLoading={isLoading}
          gridArea="available"
        />
        <IndicatorCard
          title="Adimplente"
          tooltip="Quantidade de revendedores da Base Total que não estão bloqueados sistemicamente e possuem crédito liberado."
          mainValue={setDisplayedValue(data?.baseProfile?.settled?.value, decimalNoFraction)}
          isLoading={isLoading}
          gridArea="settled"
        />
        <IndicatorCard
          title="Inadimplente"
          tooltip="Quantidade de revendedores da Base Total que não estão bloqueados sistemicamente e não possuem crédito liberado."
          mainValue={setDisplayedValue(data?.baseProfile?.overdue?.value, decimalNoFraction)}
          isLoading={isLoading}
          gridArea="overdue"
        />
        <IndicatorCard
          title="Base indisponível"
          tooltip="Quantidade de revendedores da Base Total que estão bloqueados sistemicamente."
          mainValue={setDisplayedValue(data?.baseProfile?.blocked?.value, decimalNoFraction)}
          isLoading={isLoading}
          gridArea="blocked"
        />
        <IndicatorCard
          title="Base ativa"
          tooltip="Quantidade de revendedores que fizeram, pelo menos, um  pedido em até 6 ciclos, excluindo os Inicios e Reinícios."
          mainValue={setDisplayedValue(data?.baseProfile?.activeBase?.value, decimalNoFraction)}
          icon={<TwoPeopleIcon />}
          isLoading={isLoading}
          gridArea="active-base"
        />
        <IndicatorCard
          title="Base multimarca"
          tooltip="Quantidade de revendedores que fizeram, pelo menos, um pedido com itens de mais de uma marca, em até 6 ciclos, excluindo os Inicios e Reinícios."
          mainValue={setDisplayedValue(data?.baseProfile?.multiBrandBase?.value, decimalNoFraction)}
          icon={<TwoPeopleIcon />}
          isLoading={isLoading}
          gridArea="multi-brand-base"
        />
        <IndicatorCard
          title="Adições líquidas"
          tooltip="Aumento ou redução na quantidade de revendedores da Base Ativa."
          mainValue={setDisplayedValue(data?.baseProfile?.netAdditions?.value, decimalNoFraction)}
          icon={<SquarePlusMinusIcon />}
          isLoading={isLoading}
          gridArea="net-additions"
        />
        <IndicatorCard
          title="Perda"
          tooltip="Quantidade de revendedores que sairão da base ativa no próximo ciclo, se não realizarem, pelo menos, um pedido no ciclo corrente."
          mainValue={setDisplayedValue(data?.baseProfile?.loss?.value, decimalNoFraction)}
          icon={<CrossCircleIcon />}
          isLoading={isLoading}
          gridArea="loss"
        />
        <IndicatorCard
          title="Percentual de churn"
          tooltip="Percentual de revendedores que sairão da base ativa no próximo ciclo, se não realizarem, pelo menos, um pedido no ciclo corrente."
          mainValue={setDisplayedValue(data?.baseProfile?.churn?.percentage, percent)}
          icon={<ArrowDownIcon />}
          isLoading={isLoading}
          gridArea="churn"
        />
        <Table
          title="Base indisponível por nível de inatividade"
          tooltipText="Quantidade de revendedores indisponíveis, classificados nos diferentes níveis de inatividade."
          format={decimalNoFraction.format}
          isLoading={isLoading}
          isInactivityTable
          data={data?.baseProfile?.basePerInactivityLevel}
        />
        <Table
          title="Base indisponível por Meu Clube Multi"
          tooltipText="Quantidade de revendedores indisponíveis, classificados nas diferentes faixa do programa de fidelidade Meu CLube Multi."
          format={decimalNoFraction.format}
          isLoading={isLoading}
          data={data?.baseProfile?.basePerMeuClubeGBLevel}
        />
      </DashboardGrid>
      <DashboardSectionTitle
        title="Digitalização da Base"
        subtitle="Detalhamento do nível de digitalização dos revendedores"
      />
      <DashboardGrid css={gridSettingsDigitalBase}>
        <IndicatorCard
          title="Ativos da base total"
          tooltip="Quantidade de revendedores da Base Total que acessou os canais digitais pelo menos uma vez."
          mainValue={setDisplayedValue(data?.digitalBase?.totalBaseActives?.value, decimalNoFraction)}
          icon={<TwoPeopleIcon />}
          isLoading={isLoading}
          gridArea="total-base-actives"
        />
        <IndicatorCard
          title="Ativos nos canais digitais"
          tooltip="Quantidade de revendedores que possuem pelo menos um pedido realizado nos canais digitais, considerando inícios e reinícios."
          mainValue={setDisplayedValue(data?.digitalBase?.digitalChannelsActives?.value, decimalNoFraction)}
          isLoading={isLoading}
          gridArea="digital-channel-actives"
        />
        <IndicatorCard
          title="Penetração nos canais digitais"
          tooltip="Percentual de revendedores da Base Total que acessou os canais digitais pelo menos uma vez."
          mainValue={setDisplayedValue(data?.digitalBase?.digitalChannelsPenetration?.percentage, percent)}
          isLoading={isLoading}
          gridArea="digital-channel-penetration"
        />
        <Table
          title="Digitalização da base por nível de inatividade"
          tooltipText="Percentual de revendedores da Base Ativa que ativaram nos canais digitais, classificados nos diferentes níveis de inatividade."
          format={decimalNoFraction.format}
          isLoading={isLoading}
          isInactivityTable
          data={data?.digitalBase?.digitalPerInactivityLevel}
        />
        <Table
          title="Digitalização da base por Meu Clube Multi"
          tooltipText="Percentual de revendedores da Base Ativa que ativaram nos canais digitais, classificados nas diferentes faixa do programa de fidelidade Meu Clube Multi."
          format={decimalNoFraction.format}
          isLoading={isLoading}
          data={data?.digitalBase?.digitalPerMeuClubeGBLevel}
        />
      </DashboardGrid>
    </>
  );
}
