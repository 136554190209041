import { trackerFn } from '../functions';
import { InteractionDetail, EventName } from '../types';

const event_name = EventName.INTERACTION_CENTRAIS_DE_SERVICO;

export const createChecklistEvents = () => {
  return {
    createChecklist: () =>
      trackerFn({
        event_name,
        cd_interaction_detail: `${InteractionDetail.click}:adicionar-central-de-servico'`,
      }),

    editChecklist: () =>
      trackerFn({
        event_name,
        cd_interaction_detail: `${InteractionDetail.click}:editar-central-de-servico`,
      }),
  };
};
