import { Fragment, useCallback, useState } from 'react'

import {
  FloraButton as Button,
  Flex,
  FloraInput as Input,
  FloraTypography as Typography,
} from '@grupoboticario/flora-react'

import {
  AmphoraOutlineIcon,
  MagnifyingGlassIcon,
  PencilIcon,
} from '@grupoboticario/flora-react-icons'

import {
  EmptyState,
  ErrorState,
  LoadingState,
  NotFoundState,
  UnavailableState,
} from '@shared/components'

import { useApiData } from '@shared/api'
import { env } from '@shared/env'
import { useEvents } from '@shared/events'
import { useTranslation } from '@shared/i18n'

import { useCurrentAttendanceContext } from '@register-attendance/providers'

import { machine } from '../consult-similar-drawer'

import { Products } from './products'

import {
  emptyStateFixCSS,
  Form,
  Header,
  inputNumberFixCSS,
  MIN_HEIGHT_SEARCH_CONTENT,
  SelectedAddress,
} from './search-step.styles'

import { ProductDTO, SearchStepProps } from './search-step.types'
import { getCapitalizedText } from '@shared/utils'

const INPUT_NAME = 'search-product-input'

const SearchStep = ({ changeStep, selectedAddress, show }: SearchStepProps): JSX.Element | null => {
  const { t } = useTranslation(['searchStep', 'addressStep'])
  const isDeliveryAddress = selectedAddress.type === t('addressStep:receiveDeliveryAddress')
  const { currentAttendance } = useCurrentAttendanceContext()
  const events = useEvents()

  const [showInitialState, setShowInitialState] = useState<boolean>(true)

  const handleClick = (): void => {
    events.clickChangeDeliveryMethod()
    changeStep(machine.search.on.back)
  }

  const getApiParams = useCallback(
    () => ({ resellerId: currentAttendance ? currentAttendance.resellerId : '' }),
    [currentAttendance],
  )

  const { data, loading, filled, error, refetch, getData, unavailable, notFound } =
    useApiData<ProductDTO>({
      dataSource: 'getSearchProduct',
      fetchOnMount: false,
      getApiParams,
      pollingOnError: true,
    })

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    const form = new FormData(event.currentTarget)
    event.preventDefault()
    setShowInitialState(false)

    getData({
      resellerId: currentAttendance!.resellerId,
      sku: form.get(INPUT_NAME) as string,
      distributionCenterCode: selectedAddress.id as number,
    })

    events.submitSearchProduct()
  }

  if (!show) {
    return null
  }

  return (
    <Fragment>
      <Header as="header" data-testid="search-step">
        <SelectedAddress>
          <Typography
            as="h4"
            color="$nonInteractivePredominant"
            fontSize="exceptionsAuxiliar"
            fontWeight="regular"
          >
            {t('title', { deliveryType: selectedAddress.type })}
            <Typography
              as="span"
              fontSize="bodySmallShort"
              fontWeight="medium"
              css={{ display: 'block' }}
            >
              {isDeliveryAddress ? '' : getCapitalizedText(selectedAddress.name, true)}
            </Typography>
          </Typography>

          <Button
            hierarchy="tertiary"
            has="iconLeft"
            icon={<PencilIcon color="$nonPrimaryButtonContent" size={16} />}
            onClick={handleClick}
          >
            {t('editAddress')}
          </Button>
        </SelectedAddress>

        <Form onSubmit={onSubmit} aria-label={t('formSearch.ariaLabel')} role="form">
          <Input
            buttonProps={{
              'aria-label': t('formSearch.ariaLabel'),
              has: 'iconOnly',
              icon: <MagnifyingGlassIcon />,
            }}
            id="product-search"
            name={INPUT_NAME}
            label={t('formSearch.label')}
            labelPosition="external"
            placeholder={t('formSearch.placeholder')}
            type="number"
            autoComplete="off"
            css={inputNumberFixCSS}
          />
        </Form>
      </Header>

      {showInitialState && (
        <EmptyState
          icon={<AmphoraOutlineIcon color="$nonInteractivePredominant" size="40px" />}
          title={t('emptyState.title')}
          description={t('emptyState.description')}
          css={emptyStateFixCSS}
        />
      )}

      <Flex direction="column" gap="$4" css={{ padding: '$4' }}>
        {filled && (
          <Products
            products={[data]}
            title={t('productTitle')}
            emptyDescription={t('productNotFound')}
          />
        )}

        {filled && data.similar && (
          <Products
            products={data.similar}
            title={t('similarProductsTitle')}
            emptyDescription={t('similarProductsNotFound')}
          />
        )}

        {loading && <LoadingState containerHeight={MIN_HEIGHT_SEARCH_CONTENT} />}

        {notFound && <NotFoundState text={t('notFoundMessage')} />}

        {error && <ErrorState onButtonClick={refetch} title={t('errorMessage')} description="" />}

        {unavailable && <UnavailableState retryDelayMs={env.ERROR_POLLING_TIMEOUT} />}
      </Flex>
    </Fragment>
  )
}

export { SearchStep }
