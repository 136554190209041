import { LockAndLimitFormData, LockAndLimit, SaveLockAndLimitRequestType } from '@/types';
import { getValueByCondition } from '@/utils';

const blockAfterFirstOrderEnabledPayload = (formData: LockAndLimitFormData): LockAndLimit => {
  const { hasBlockAfterFirstOrder } = formData;
  return {
    hasBlockAfterFirstOrder,
    unlockAfterPayingFirstOrder: getValueByCondition(hasBlockAfterFirstOrder, formData.unlockAfterPayingFirstOrder),
    allowToInformAvailableLimit: formData.allowToInformAvailableLimit,
    note: formData.note,
  };
};

export const formatLockAndLimitData = (formData: LockAndLimitFormData, id: string): SaveLockAndLimitRequestType => ({
  _id: id,
  step: '6',
  lockAndLimit: blockAfterFirstOrderEnabledPayload(formData),
});
