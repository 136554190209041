import React from 'react'

import {
  FloraTypographyProps,
  Tag,
  FloraTypography as Typography,
} from '@grupoboticario/flora-react'

import { Alert } from '@grupoboticario/flora-team-react-alert'
import { toCurrency } from '@grupoboticario/vdi-mfe-utils'

import { useTranslation } from '@shared/i18n'

import {
  ContainerSKU,
  ProductContainer,
  ProductsContainer,
  ProductsList,
  Title,
} from './products.styles'

import { ProductProps, ProductsProps } from './products.types'

const Products: React.FC<ProductsProps> = ({ products, title, emptyDescription }) => (
  <ProductsContainer>
    <Title as="h6">{title}</Title>

    {products.length <= 0 && (
      <Alert hideCloseButton description={emptyDescription} status="info" size="small" />
    )}

    {products.length > 0 && (
      <ProductsList>
        {products.map((p) => (
          <Product key={p.sku} product={p} />
        ))}
      </ProductsList>
    )}
  </ProductsContainer>
)

const Product: React.FC<ProductProps> = ({ product }) => {
  const { t } = useTranslation('product')

  const isAvailable = product.available

  const TypographyProps: FloraTypographyProps = {
    color: 'nonInteractivePredominant',
    fontSize: 'exceptionsAuxiliar',
    fontWeight: 'regular',
  }

  return (
    <ProductContainer as="li">
      <ContainerSKU>
        {t('sku', { sku: product.sku })}

        <Tag
          size="small"
          variant={isAvailable ? 'success' : 'disabled'}
          css={{ fontWeight: '$medium' }}
        >
          {t(isAvailable ? 'available' : 'unavailable')}
        </Tag>
      </ContainerSKU>

      <Typography {...TypographyProps}>{product.name}</Typography>
      <Typography {...TypographyProps}>{toCurrency(Number(product.price))}</Typography>
    </ProductContainer>
  )
}

export { Products }
