import { ServiceCenterFormData } from '@/types';

export const getDefaultServiceCenterFormValue = (): ServiceCenterFormData => {
  return {
    registrationData: undefined,
    businessHours: undefined,
    documentation: undefined,
    orderIncrement: undefined,
    pickUpInStore: undefined,
    delivery: undefined,
    lockAndLimit: undefined,
    paymentMethods: undefined,
    status: 'draft',
  };
};
