import { fetcher } from '@/shared/services/api/fetcher';

export type TagData = {
  name: string;
  description?: string;
  color: string;
};

export type TagResponse = {
  data: {
    id: string;
    userId: string;
    name: string;
    description: string;
    color: string;
  };
};

export async function addTag(data: TagData): Promise<TagResponse> {
  const res = await fetcher.post(`v1/tags`, {
    json: data,
  });

  return res.json() as Promise<TagResponse>;
}
