import React, { PropsWithChildren } from 'react'

import { FloraButton as Button, Spinner } from '@grupoboticario/flora-react'

import { ArrowDownIcon } from '@grupoboticario/flora-react-icons'
import { AsyncState } from '@shared/api'

import { EmptyState, EmptyStateProps } from '@shared/components/empty-state'
import { CardListContainer, ListContainer, StateContainer } from './card-list.styles'

interface CardListProps
  extends PropsWithChildren,
    Pick<AsyncState, 'error' | 'loading'>,
    EmptyStateProps {
  onShowMore: () => void
  showMoreLabel: string
  hasMore: boolean
  empty?: boolean
  emptyTitle?: string
  emptyDescription?: string
}

const CardList: React.FC<CardListProps> = ({
  showMoreLabel,
  onShowMore,
  children,
  hasMore,
  loading,
  error,
  empty,
  emptyTitle,
  emptyDescription,
}) => {
  return (
    <CardListContainer>
      <ListContainer>{children}</ListContainer>

      {loading && (
        <StateContainer>
          <Spinner size="md" color="$actionableDefault" />
        </StateContainer>
      )}

      {empty && !loading && (
        <StateContainer>
          <EmptyState title={emptyTitle} description={emptyDescription} />
        </StateContainer>
      )}

      {hasMore && !loading && (
        <Button
          size="small"
          has="iconLeft"
          hierarchy="secondary"
          styleSemantic={error ? 'warning' : 'neutral'}
          icon={<ArrowDownIcon />}
          onClick={onShowMore}
        >
          {error ? 'Tentar novamente' : showMoreLabel}
        </Button>
      )}
    </CardListContainer>
  )
}

export { CardList, CardListProps }
