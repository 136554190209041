import { Delivery, DeliveryCondition, DeliveryFormData, SaveDeliveryRequestType } from '@/types';
import { formatCurrencyStringToNumber, getValueByCondition } from '@/utils';

const allowDeliveryDisabledPayload = (): Delivery => ({
  hasDelivery: false,
  hasFreeDelivery: false,
  hasFreeDeliveryOnFirstOrder: false,
  hasSpecialConditions: false,
  hasFreeDeliveryDuringPandemic: false,
  hasNotAllowedDeliveryRegions: false,
  allowDeliveryToThirdParties: false,
});

const formatSpecialConditions = (conditions: DeliveryCondition[]) => {
  return !conditions
    ? []
    : conditions.map(condition => ({
        ...condition,
        deliveryCost: formatCurrencyStringToNumber(condition.deliveryCost),
        minimumPurchaseValue: formatCurrencyStringToNumber(condition.minimumPurchaseValue),
      }));
};

const allowDeliveryEnabledPayload = (formData: DeliveryFormData): Delivery => {
  const {
    hasDelivery,
    hasSpecialConditions,
    hasFreeDeliveryDuringPandemic,
    hasNotAllowedDeliveryRegions,
    allowDeliveryToThirdParties,
  } = formData;
  return {
    hasDelivery,
    deliveryCost: getValueByCondition(hasDelivery, formatCurrencyStringToNumber(formData.deliveryCost)),
    hasFreeDelivery: getValueByCondition(hasDelivery, formData.hasFreeDelivery),
    hasFreeDeliveryOnFirstOrder: getValueByCondition(hasDelivery, formData.hasFreeDeliveryOnFirstOrder),
    hasSpecialConditions,
    specialConditions: getValueByCondition(hasSpecialConditions, formatSpecialConditions(formData?.specialConditions)),
    hasFreeDeliveryDuringPandemic,
    hasNotAllowedDeliveryRegions,
    notAllowedDeliveryRegions: getValueByCondition(hasNotAllowedDeliveryRegions, formData.notAllowedDeliveryRegions),
    allowDeliveryToThirdParties,
    deliveryToThirdPartiesRules: getValueByCondition(allowDeliveryToThirdParties, formData.deliveryToThirdPartiesRules),
    note: formData.note,
  };
};

export const formatDeliveryFormData = (formData: DeliveryFormData, id: string): SaveDeliveryRequestType => ({
  _id: id,
  step: '5',
  delivery: formData.hasDelivery ? allowDeliveryEnabledPayload(formData) : allowDeliveryDisabledPayload(),
});
