import { useQuery } from "@tanstack/react-query";
import { fetchTeam } from "@api/Teams";
import { Team } from "@shared/types";

export function useTeam(id: string) {
  return useQuery<Team>({
    queryKey: ["team", id],
    queryFn: () => fetchTeam(id),
  });
}
