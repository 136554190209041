import { useEffect } from 'react';
import { styled, Container } from '@grupoboticario/flora-react';

import { useFetchContact } from '../../hooks/useFetchContact';
import { useUrlParams } from '../../hooks/useUrlParams';
import { useFetchAccounts } from '../../hooks/useFetchAccounts';
import { useAccountStore, useContactStore } from '../../state';

import { ContactSearch } from '@/features/ContactSearch';
import { ChatList } from '@/features/ChatList';
import { MessagesList } from '@/features/MessagesList';

import { Notify } from '../Notifications';
import { ChatWrapper } from '../ChatWrapper';
import { ChatsListWrapper } from '../ChatsListWrapper';
import { ContactSearchWrapper } from '../ContactSearchWrapper';
import { MessagesListWrapper } from '../MessagesListWrapper';
import { Copilot } from '@/features/Copilot';
import { NoAccountSet } from '../NoAccountSet';
import { NO_ACCOUNT_SET } from '@/shared/constants';

const AppContainerWrapper = styled(Container, {
  maxWidth: '100%',
  minHeight: '100dvh',
  backgroundColor: '$backgroundPrimary',
  padding: 0,
}) as typeof Container;

export function AppContainer() {
  const { account } = useAccountStore();
  const { contact } = useContactStore();
  const urlParams = useUrlParams();
  const contactVdId = urlParams.get('contact');

  useFetchAccounts({ enabled: !account });

  const isAccountConfigured = account?.id && account?.id !== NO_ACCOUNT_SET;
  const isDifferentContact = contact?.vdId !== contactVdId;
  const { data: dataContact, error: errorContact } = useFetchContact({
    vdId: contactVdId,
    enabled: Boolean(isAccountConfigured && contactVdId && isDifferentContact),
  });

  useEffect(() => {
    /**
     * Espera receber dados ou erro para limpar o endereço da URL
     */
    if (dataContact || errorContact) {
      history.replaceState('', '', '/chat');
    }
  }, [dataContact, errorContact]);

  const RENDER_COPILOT: boolean = Boolean(account?.type === 'reseller' && account?.copilots?.includes('ruptura'));
  /**
   * Se useFetchAccounts retornar um array vazio, mostramos a tela de conta não configurada
   * neste caso, enviaremos um evento para o New Relic com o identificador 'no-account-set'
   */
  if (account?.id === NO_ACCOUNT_SET) {
    return <NoAccountSet />;
  }

  return (
    <AppContainerWrapper as="main">
      <Notify />
      <ChatWrapper copilotEnabled={RENDER_COPILOT}>
        <ChatsListWrapper data-testid="left-column">
          <ContactSearchWrapper>
            <ContactSearch />
          </ContactSearchWrapper>
          <ChatList />
        </ChatsListWrapper>
        <MessagesListWrapper data-testid="right-column">
          <MessagesList />
        </MessagesListWrapper>
        {RENDER_COPILOT && <Copilot />}
      </ChatWrapper>
      <div id="image-gallery"></div>
      <div id="image-upload-preview"></div>
    </AppContainerWrapper>
  );
}
