import { Fragment } from 'react'

import {
  FloraButton as Button,
  FloraTypography as Typography,
  Tag,
} from '@grupoboticario/flora-react'

import { ChevronRightIcon } from '@grupoboticario/flora-react-icons'

import { useTranslation } from '@shared/i18n'

import { Card, CardList } from '@shared/components'

import { CardDescription, CardHeader, cardStyle } from './promotions.styles'

import { mockList } from './promotions.mock'
import { PromotionsSlug } from './promotions.types'

const Promotions = (): JSX.Element => {
  const { t } = useTranslation('promotions')

  const cardList = mockList.map((item, index) => {
    const renderHeaderColor = () => {
      if (item.isMultiOrganization) {
        return 'mlt' as PromotionsSlug
      }

      return item.slug.toLowerCase() as PromotionsSlug
    }

    return (
      <Card
        key={`${index}-${item.campaignId}`}
        css={cardStyle}
        ariaLabel={t('cardAriaLabel', { name: item.name })}
      >
        <CardHeader as="header" type={renderHeaderColor()}>
          <Typography
            as="h5"
            color="$nonInteractivePredominant"
            fontSize="bodySmallShort"
            fontWeight="medium"
          >
            {item.isMultiOrganization ? t('multibrand') : item.name}
          </Typography>

          {item.classificationName === 'CRM' && (
            <Tag size="small" shape="ghost" variant="assorted9" css={{ width: 'fit-content' }}>
              {t('exclusiveOffer')}
            </Tag>
          )}

          <Button
            has="iconOnly"
            hierarchy="secondary"
            icon={<ChevronRightIcon size="8px" color="$actionableDefault" />}
            size="small"
          />
        </CardHeader>

        <CardDescription as="p">{item.description}</CardDescription>
      </Card>
    )
  })

  return (
    <Fragment>
      <Typography
        as="h4"
        fontSize="bodyLargeStandard"
        fontWeight="medium"
        color="$nonInteractivePredominant"
        css={{ marginBottom: '$7 !important' }}
      >
        {t('title')}
      </Typography>

      <CardList
        empty={false}
        showMoreLabel={t('seeMore')}
        emptyTitle="Está vazio"
        emptyDescription="Descrição quando está vazio"
        loading={false}
        error={false}
        hasMore={false}
        onShowMore={() => {}}
      >
        {cardList}
      </CardList>
    </Fragment>
  )
}

export { Promotions }
