import { saveAndContinueAction, toggleInteraction, trackerFn } from '../functions';
import { EventName } from '../types';


const event_name = EventName.INTERACTION_CENTRAIS_SERVICO_DOCUMENTACAO;

export const documentationEvents = () => {
  return {
    toggleAdditionalDocumentation: (isEnable: boolean) =>
      trackerFn({
        event_name,
        cd_interaction_detail: toggleInteraction(isEnable, 'solicitar-documentacao-adicional'),
      }),

    documentationSaveAndContinue: () => saveAndContinueAction({ event_name }),
  };
};
