import { saveAndContinueAction, toggleInteraction, trackerFn } from '../functions';
import { EventName, InteractionDetail } from '../types';

const event_name = EventName.INTERACTION_CENTRAIS_SERVICO_RETIRADA;

export enum PickUpStoreInteraction {
  authorizeWithdrawal = 'autoriza-retirada',
  requiresDocumentForWithdrawal = 'exige-documento-para-retirada',
  originalDocumentWithPhoto = 'documento-original-com-foto',
  copyOfDocumentWithPhoto = 'copia-documento-com-foto',
  specificationsOnProofOfAddress = 'especificacoes-sobre-comprovantes-endereco',
  requiresSchedulingForWithdrawal = 'exige-agendamento-para-retirada',
  authorizeWithdrawalByThirdParties = 'autoriza-retirada-por-terceiros',
  minimumPickUpPeriodQty = 'prazo-minimo-para-retirada',
  maximumPickUpPeriodQty = 'prazo-maximo-para-retirada',
}

export const pickUpStoreEvents = () => {
  return {
    togglePickUpStoreEvents: ({ isEnable, value }: { isEnable: boolean; value: string }) => {
      trackerFn({
        event_name,
        cd_interaction_detail: toggleInteraction(isEnable, value),
      });
    },
    proofOfAddress: (value: string) => {
      trackerFn({
        event_name,
        cd_interaction_detail: `${InteractionDetail.click}:especificacoes-comprovantes-endereco-${value}`,
      });
    },
    thirdPartySupport: (value: string) => {
      trackerFn({
        event_name,
        cd_interaction_detail: `${InteractionDetail.click}:${value}`,
      });
    },
    minimumPickUpPeriodQty: (value: string) => {
      trackerFn({
        event_name,
        cd_interaction_detail: `${InteractionDetail.input}:quantidade-prazo-minimo-para-retirada-${value}`,
      });
    },
    minimumPickUpPeriodInfo: (value: string) => {
      trackerFn({
        event_name,
        cd_interaction_detail: `${InteractionDetail.input}:input:periodo-prazo-minimo-para-retirada-${value}`,
      });
    },
    maximumPickUpPeriodQty: (value: string) => {
      trackerFn({
        event_name,
        cd_interaction_detail: `${InteractionDetail.input}:quantidade-prazo-maximo-para-retirada-${value}`,
      });
    },
    maximumPickUpPeriodInfo: (value: string) => {
      trackerFn({
        event_name,
        cd_interaction_detail: `${InteractionDetail.input}:input:periodo-prazo-maximo-para-retirada-${value}`,
      });
    },
    pickUpStoreEventsSaveAndContinue: () => saveAndContinueAction({ event_name }),
  };
};
