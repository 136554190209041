import { saveAndContinueAction, toggleInteraction, trackerFn } from '../functions';
import { EventName, InteractionDetail } from '../types';

const event_name = EventName.INTERACTION_CENTRAIS_SERVICO_FORMAS_DE_PAGAMENTO;

const prefixBankSlipPayment = 'opcao-boleto';
const prefixCreditCardPayment = 'opcao-cartao-credito';
const prefixPixPayment = 'opcao-pix';

export enum BankSlipPaymentInteraction {
  consumesAvailableCredit = `${prefixBankSlipPayment}-consumir-credito-disponivel`,
  note = `${prefixBankSlipPayment}-outras-formas-parcelamento`,
  minimumInstallmentValue = `${prefixBankSlipPayment}-valor-minimo-parcela`,
}

export enum CreditCardPaymentInteraction {
  paymentNote = `${prefixCreditCardPayment}-aceita-parcelamento`,
  minimumInstallmentValue = `${prefixCreditCardPayment}-valor-minimo-parcela`,
}

export enum PaymentMethodsInteraction {
  bankSlipPayment = prefixBankSlipPayment,
  cashPayment = 'opcao-dinheiro',
  creditCardPayment = prefixCreditCardPayment,
  debitCardPayment = 'opcao-cartao-debito',
  depositAndTransferPayment = 'opcao-deposito-transferencia',
  onlineCardPayment = 'opcao-cartao-online',
  pixPaymentSelect = prefixPixPayment,
}

export const paymentMethodsEvents = () => {
  return {
    togglePaymentMethodsEvents: ({ isEnable, value }: { isEnable: boolean; value: string }) => {
      trackerFn({
        event_name,
        cd_interaction_detail: toggleInteraction(isEnable, value),
      });
    },
    pixPaymentKeyType: (value: string) => {
      trackerFn({
        event_name,
        cd_interaction_detail: `${InteractionDetail.click}:${prefixPixPayment}-${value}`,
      });
    },

    paymentMethodsSaveAndContinue: () => saveAndContinueAction({ event_name }),
  };
};
