import React, { PropsWithChildren } from 'react'
import { CSS } from '@grupoboticario/flora'

import { CardContainer } from './card.styles'

interface CardProps extends PropsWithChildren {
  css?: CSS
  ariaLabel?: string
}

const Card: React.FC<CardProps> = ({ children, css, ariaLabel }) => (
  <CardContainer css={css} aria-label={ariaLabel} tab-index="0">
    {children}
  </CardContainer>
)

export { Card }
