import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "./api/queryClient";
import { NotificationMenuWrapper } from "./NotificationMenuWrapper";
import { MenuEnabler } from "@shared/components/menu-enabler";

export function NotificationMenu() {
  return (
    <BrowserRouter basename="/">
      <QueryClientProvider client={queryClient}>
        <MenuEnabler featuresName={["view-chat"]}>
          <NotificationMenuWrapper />
        </MenuEnabler>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  );
}
