import { OrderIncrement, OrderIncrementFormData, SaveOrderIncrementRequestType } from '@/types';
import { getValueByCondition } from '@/utils';

const dataFormatter = (formData: OrderIncrementFormData): OrderIncrement => {
  const { hasCategories, hasOrderProductConditions } = formData;

  return {
    hasOrderProductConditions: formData.hasOrderProductConditions,
    orderProductConditions: getValueByCondition(hasOrderProductConditions, formData.orderProductConditions),
    hasCategories: formData.hasCategories,
    categories: getValueByCondition(hasCategories, formData.categories),
    note: formData.note,
  };
};

export const formatOrderIncrementFormData = (
  formData: OrderIncrementFormData,
  id: string,
): SaveOrderIncrementRequestType => ({
  _id: id,
  step: '3',
  orderIncrement: dataFormatter(formData),
});
