import { changedFieldTextFormatterDict } from './changedFieldTextFormatterDict';

type ChangedLhsRhsTextFormatterType = string | number | boolean;

const formatBooleanField = (field: boolean): string => (field ? 'Sim' : 'Não');

const formatField = (field: ChangedLhsRhsTextFormatterType): ChangedLhsRhsTextFormatterType =>
  typeof field === 'boolean'
    ? formatBooleanField(field)
    : (changedFieldTextFormatterDict[field] ?? field);

export const changedLhsRhsTextFormatter = (
  fieldText: ChangedLhsRhsTextFormatterType | ChangedLhsRhsTextFormatterType[]
): ChangedLhsRhsTextFormatterType | ChangedLhsRhsTextFormatterType[] => {
  return Array.isArray(fieldText) ? fieldText.map(formatField) : formatField(fieldText);
};

export const changedFieldTextFormatter = (path: (string | number)[]) => {
  const translatedPath = path.map((entry) => {
    return typeof entry === 'string' ? changedFieldTextFormatterDict[entry] : `(${entry + 1})`;
  });

  return translatedPath.join(' > ').replaceAll('> (', '(');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapToStringLhsRhsData = (object: any | any[]): string | string[] => {
  if (Array.isArray(object)) {
    return object.map(mapToStringLhsRhsData).join(', ');
  }
  if (typeof object === 'object' && object !== null) {
    return Object.entries(object).map(([key, value]) => {
      const formattedKey = changedLhsRhsTextFormatter([key]);
      return ` ${mapToStringLhsRhsData(formattedKey)} : ${mapToStringLhsRhsData(value)}`;
    });
  }
  return changedLhsRhsTextFormatter(object) as string;
};
