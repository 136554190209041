import { saveAndContinueAction, toggleInteraction, trackerFn } from '../functions';
import { EventName } from '../types';

const event_name = EventName.INTERACTION_CENTRAIS_SERVICO_ENTREGA;

export enum DeliveryInteraction {
  hasDelivery = 'interaction_centrais_servico_entrega',
  hasSpecialConditions = 'condicoes-especiais-por-valor',
  hasFreeDelivery = 'frete-gratuito',
  hasFreeDeliveryOnFirstOrder = 'frete-cortesia',
  hasFreeDeliveryDuringPandemic = 'frete-gratuito-pandemia',
  hasNotAllowedDeliveryRegions = 'nao-permite-entrega',
  allowDeliveryToThirdParties = 'permite-entrega-terceiros',
}

export const deliveryFormEvents = () => {
  return {
    toggleDeliveryEvents: ({ isEnable, value }: { isEnable: boolean; value: string }) => {
      trackerFn({
        event_name,
        cd_interaction_detail: toggleInteraction(isEnable, value),
      });
    },
    deliveryFormEventsSaveAndContinue: () => saveAndContinueAction({ event_name }),
  };
};
