import { CSS, Flex, Spinner, FloraTypography as Typography } from '@grupoboticario/flora-react'

interface LoadingStateProps {
  isLoading?: boolean
  title?: string
  description?: string
  containerHeight?: string
  containerWidth?: string
  css?: CSS
}

const LoadingState = ({
  containerWidth,
  containerHeight,
  description,
  isLoading = true,
  title,
  css,
}: LoadingStateProps): JSX.Element | null => {
  const renderTitle = title && (
    <Typography
      as="h5"
      fontSize="subtitle"
      fontWeight="medium"
      css={{ color: '$nonInteractivePredominant' }}
    >
      {title}
    </Typography>
  )

  const renderDescription = description && (
    <Typography
      fontSize="bodySmallShort"
      fontWeight="medium"
      css={{ color: '$nonInteractiveAuxiliar' }}
    >
      {description}
    </Typography>
  )

  if (!isLoading) {
    return null
  }

  return (
    <Flex
      align="center"
      direction="column"
      justify="center"
      gap="$2"
      data-testid="loading"
      css={{ width: containerWidth ?? '100%', height: containerHeight ?? 'auto', ...css }}
    >
      {renderTitle}
      {renderDescription}
      <Spinner color="$actionableDefault" size="md" />
    </Flex>
  )
}

export { LoadingState, type LoadingStateProps }
