import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../services/reactQuery";
import { createTeam } from "@api/Teams";

interface CreateTeamProps {
  groupName: string;
  owners: string[];
}

export function useCreateTeam() {
  return useMutation({
    mutationFn: ({ groupName, owners }: CreateTeamProps) =>
      createTeam(groupName, owners),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["teams"] });
    },
  });
}
