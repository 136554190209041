import { CSS } from '@grupoboticario/flora'
import { Container, Description, IconWrapper, Title } from './empty-state.styles'

interface EmptyStateProps {
  size?: 'sm' | 'md' | 'lg'
  title?: string
  description?: string
  icon?: React.ReactElement
  css?: CSS
}

const EmptyState = ({
  size = 'sm',
  title,
  description,
  icon,
  css,
}: EmptyStateProps): JSX.Element => {
  const renderIcon = !!icon && <IconWrapper size={size}>{icon}</IconWrapper>

  return (
    <Container size={size} direction="column" align="center" justify="center" css={css}>
      {renderIcon}

      <Title as="h4" size={size}>
        {title}
      </Title>

      <Description as="p" size={size}>
        {description}
      </Description>
    </Container>
  )
}

export { EmptyState, type EmptyStateProps }
