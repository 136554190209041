import { authorizationService, UserFeatures } from "@vdi/auth-service";
import { ReactNode } from "react";

export function MenuEnabler({
  children,
  featuresName,
}: {
  children: ReactNode;
  featuresName: UserFeatures[];
}) {
  const canViewMenuItem = featuresName.some((featureName) =>
    authorizationService.authorizationHelper.canAccessFeature({
      featureName,
    }),
  );

  if (canViewMenuItem) return <>{children}</>;

  return null;
}
