import { OperationEnum, RiskAnalysis, SituationStatus, SituationStatusValue } from '@/types';

const translationMap = new Map<string, string>([
  ['label', 'Campanha'],
  ['riskAnalysis', 'Risco'],
  ['situation', 'Situação'],
  ['operation', 'Operação'],
  ['recadastro', 'Recadastro'],
  ['cpCode', 'CS'],
]);

const valueTranslationMap = new Map<string, string>([
  [RiskAnalysis.LOW_RISK, 'Baixo'],
  [RiskAnalysis.MEDIUM_RISK, 'Médio'],
  [RiskAnalysis.HIGH_RISK, 'Alto'],
  [RiskAnalysis.NOT_PROCESSED, 'Sem risco'],
  [SituationStatusValue.DENIED_CALL, SituationStatus.DENIED_CALL],
  [SituationStatusValue.IN_SERVICE, SituationStatus.IN_SERVICE],
  [SituationStatusValue.NOT_STARTED, SituationStatus.NOT_STARTED],
  [SituationStatusValue.SALE_COMPLETED, SituationStatus.SALE_COMPLETED],
  [SituationStatusValue.WITHOUT_CALL, SituationStatus.WITHOUT_CALL],
  [SituationStatusValue.WRONG_NUMBER, SituationStatus.WRONG_NUMBER],
  [SituationStatusValue.WRONG_REGISTER, SituationStatus.WRONG_REGISTER],
  [OperationEnum.BOTI, 'Boticário'],
  [OperationEnum.VDF, 'VDF'],
  [OperationEnum.CII, 'CII'],
  ['true', 'Sim'],
  ['false', 'Não'],
]);

export const formatAssistantFilterTag = (filterList: string[]) => {
  if (!filterList) {
    return undefined;
  }

  return filterList?.map((filter) => {
    const [key, value] = filter.split(':');

    return {
      name: `${translationMap.get(key)}: ${valueTranslationMap.get(value) ?? value}`,
      key: filter,
    };
  });
};
