import { Box, Button, Flex } from "@grupoboticario/flora-react";
import { PlusIcon } from "@grupoboticario/flora-react-icons";
import { useFetchAccount } from "@shared/hooks/useFetchAccount";
import { authService } from "@vdi/auth-service";
import { Link } from "react-router-dom";

export function ActionBar() {
  const { id: userId } = authService.userData;
  const { data: account } = useFetchAccount(userId);

  return (
    <Flex justify={"flex-end"} gap={"$4"} css={{ flexWrap: "wrap" }}>
      <Box>
        {account && (
          <Button
            has="iconLeft"
            icon={<PlusIcon />}
            as={Link}
            to={"/equipes/cadastrar"}
          >
            Criar equipe
          </Button>
        )}
      </Box>
    </Flex>
  );
}
