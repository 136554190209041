import { clsx } from 'clsx';
import { Flex, Hidden, Text } from '@grupoboticario/flora-react';
import { Card, CardTitle, GoalAndRealized } from '@/shared/components';

interface IndicatorCardProps {
  isLoading: boolean;
  gridArea?: string;
  title: string;
  tooltip?: string;
  icon?: JSX.Element;
  auxiliarText?: string;
  mainValue: string;
  goal?: string;
  achievement?: number;
}

export function IndicatorCard({
  icon,
  title,
  tooltip,
  isLoading,
  gridArea,
  mainValue,
  goal,
  achievement,
  auxiliarText,
}: IndicatorCardProps) {
  return (
    <Card css={{ gridArea }}>
      <Flex direction="row" align="center" css={{ height: '100%' }}>
        {icon && (
          <Hidden only={['mobile', 'tablet']}>
            {{
              ...icon,
              props: {
                size: 36,
                css: {
                  backgroundColor: '$backgroundTertiary',
                  borderRadius: '8px',
                  color: '$nonInteractiveEmphasis',
                  padding: '16px',
                },
              },
            }}
          </Hidden>
        )}
        <Flex
          direction="column"
          justify="center"
          css={{ flex: '1 1 auto', '@desktop': { marginLeft: icon ? '$4' : undefined } }}
        >
          <CardTitle title={title} tooltip={tooltip} />
          <Text
            className={clsx(isLoading && 'loading-placeholder')}
            size="subTitleDeskBold"
            weight="heavy"
            css={{
              display: 'inline-block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {mainValue}
          </Text>
          {auxiliarText && (
            <Text
              className={clsx(isLoading && 'loading-placeholder loading-placeholder-subtitle')}
              size="exceptionsAuxiliarMedium"
              color="$nonInteractiveAuxiliar"
            >
              {auxiliarText}
            </Text>
          )}
          {goal && <GoalAndRealized goal={goal} achievement={achievement} isLoading={isLoading} />}
        </Flex>
      </Flex>
    </Card>
  );
}
