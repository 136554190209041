import { FloraInput, Box, FloraButton } from '@grupoboticario/flora-react';
import { TagColorsContainer } from './TagColorsContainer';
import CreateModal from '@/shared/components/Modal';
import { PlusIcon } from '@grupoboticario/flora-react-icons';
import { addTag } from '@/shared/services/api/instant-messenger/service/postTagOnList';

export function CreateTagForm({
  colors,
  setNewTag,
  newTag,
  setTagList,
}: Readonly<{
  colors: string[];
  setNewTag: any;
  newTag: any;
  setTagList: any;
}>) {
  //não está selecionando a cor de forma automática
  const handleNameChange = (name: string) => {
    setNewTag({ ...newTag, name });
  };

  const handleColorChange = (color: string) => {
    setNewTag({ ...newTag, color });
  };

  const handleAddTag = async () => {
    try {
      const res = await addTag(newTag);
      setNewTag({ name: '', color: '', description: '' });
      setTagList(prevTagList => [...prevTagList, { selected: false, ...res.data }]);
    } catch (error) {
      setNewTag({ name: '', color: '', description: '' });
      console.error(error);
    }
  };

  return (
    <CreateModal
      trigger={
        <FloraButton
          size="small"
          isFull="true"
          hierarchy="tertiary"
          has="iconLeft"
          icon={<PlusIcon />}
          disabled={!colors.length}
        >
          Adicionar nova etiqueta
        </FloraButton>
      }
      submit={() => handleAddTag()}
      submitLabel="Incluir"
      header="Adicionar nova etiqueta"
      darkButton="submit"
      body={
        <Box>
          <FloraInput
            id="new-tag-name"
            label="Nome da etiqueta:"
            value={newTag.name}
            labelPosition="external"
            onChange={e => handleNameChange(e.target.value)}
          />
          <TagColorsContainer color={newTag.color} colors={colors} setColor={handleColorChange} />
        </Box>
      }
    />
  );
}
