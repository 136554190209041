import { type IEventTracker } from '@vdi/event-tracker'
import { EventName, type Event } from './events.types'

import { DeliveryType } from '@reseller-data/modules/attendance-actions/address-step'

interface ConsultSimilarEvents {
  clickConsultSimilarOpenDrawer: () => void
  clickDeliveryMethod: (deliveryMethod: DeliveryType) => void
  clickChangeDeliveryMethod: () => void
  submitSearchProduct: () => void
}

const createConsultSimilarEvents = (
  tracker: IEventTracker<Event, string>,
): ConsultSimilarEvents => ({
  clickConsultSimilarOpenDrawer: function () {
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: EventName.interactionActivation,
        cd_interaction_detail: `click:consultar-similares`,
      },
    })
  },

  clickDeliveryMethod: function (deliveryMethod) {
    const address = deliveryMethod === DeliveryType.ADDRESS
    const addressString = 'receber-no-endereco-de-entrega'
    const erString = 'retirar-no-er'

    const deliveryMethodType = address ? addressString : erString

    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: EventName.interactionActivation,
        cd_interaction_detail: `click:${deliveryMethodType}`,
      },
    })
  },

  clickChangeDeliveryMethod: function () {
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: EventName.interactionActivation,
        cd_interaction_detail: `click:alterar-endereco`,
      },
    })
  },

  submitSearchProduct: function () {
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: EventName.interactionActivation,
        cd_interaction_detail: `click:buscar-produto`,
      },
    })
  },
})

export { createConsultSimilarEvents, type ConsultSimilarEvents }
