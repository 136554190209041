import { useServiceCenterForm } from '@/context';
import { ServiceCenterFormData } from '@/types';
import { Tab, TabList } from '@grupoboticario/flora-react';
import { CheckCircleIcon } from '@grupoboticario/flora-react-icons';

export interface WizardStep {
  label: string;
  stepNumber: number;
}

const stepToKeyMap: {
  [key: number]: keyof ServiceCenterFormData;
} = {
  1: 'registrationData',
  2: 'documentation',
  3: 'businessHours',
  4: 'orderIncrement',
  5: 'pickUpInStore',
  6: 'delivery',
  7: 'lockAndLimit',
  8: 'paymentMethods',
};

const checkStepCompleted = (stepNumber: number, data: ServiceCenterFormData) => {
  if (data.status === 'done') {
    return true;
  }

  return !!data[stepToKeyMap[stepNumber]];
};

const steps: WizardStep[] = [
  {
    label: 'Dados Cadastrais',
    stepNumber: 1,
  },
  {
    label: 'Documentação',
    stepNumber: 2,
  },
  {
    label: 'Horário de Funcionamento',
    stepNumber: 3,
  },
  {
    label: 'Inicia com produtos',
    stepNumber: 4,
  },
  {
    label: 'Retirada',
    stepNumber: 5,
  },
  {
    label: 'Entrega',
    stepNumber: 6,
  },
  {
    label: 'Bloqueio e limite',
    stepNumber: 7,
  },
  {
    label: 'Formas de pagamento',
    stepNumber: 8,
  },
];

const Wizard = (): JSX.Element => {
  const { state, goToStep } = useServiceCenterForm();

  return (
    <TabList css={{ padding: '$4' }}>
      {steps.map((step, index) => {
        const isStepCompleted = checkStepCompleted(step.stepNumber, state.formData);

        const isDisabled = state.currentStep < step.stepNumber && state.formData.status === 'draft' && !isStepCompleted;

        const onTabClick = () => {
          if (isStepCompleted) {
            goToStep(step.stepNumber);
          }
        };

        return (
          <Tab
            key={step.label}
            value={`${step.stepNumber}`}
            disabled={isDisabled}
            css={{
              cursor: isDisabled ? 'not-allowed' : 'pointer',
            }}
            data-testid={`wizard-step-${index}`}
            onClick={onTabClick}
            icon={isStepCompleted ? <CheckCircleIcon data-testid={`wizard-step-icon-${index}`} size="16" /> : undefined}
          >
            {step.label}
          </Tab>
        );
      })}
    </TabList>
  );
};

export { Wizard };
