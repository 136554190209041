import { Flex } from '@grupoboticario/flora-react';
import { TagPlusIcon } from '@grupoboticario/flora-react-icons';
import { Menu, MenuGroup } from '@szhsin/react-menu';
import { Tag } from '@/shared/types/interfaces';
import { useState } from 'react';
import { CreateTagForm } from './CreateTag';
import { TagItem } from './TagItem';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { useAvailableColors } from '../../hooks/useAvaliableColors';

export type ChatTagType = { selected: boolean } & Tag;

type ChatTagsProps = {
  handleSelected: (tag: ChatTagType) => void;
  tagList: ChatTagType[];
  setTagList: any;
};

export function ChatTagsMenu({ handleSelected, tagList, setTagList }: Readonly<ChatTagsProps>) {
  const [newTag, setNewTag] = useState({ name: '', color: '', description: '' });
  const avaliableColors = useAvailableColors(tagList);

  return (
    <Menu
      align="center"
      direction="bottom"
      menuButton={
        <Flex
          className="btn-add-tag"
          css={{ marginLeft: 'auto' }}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <TagPlusIcon size="20" color="$9" css={{ cursor: 'pointer' }} />
        </Flex>
      }
      onItemClick={e => {
        e.syntheticEvent.stopPropagation();
        e.keepOpen = true;
      }}
      overflow="auto"
      setDownOverflow={true}
      position="auto"
      portal={true}
      className="menu-contact-tags"
      menuStyle={{ padding: '10px' }}
    >
      <MenuGroup takeOverflow={true}>
        {tagList?.map(tag => (
          <TagItem
            key={tag.id}
            tag={tag}
            setNewTag={setNewTag}
            setTagList={setTagList}
            availableColors={avaliableColors}
            handleSelected={handleSelected}
          />
        ))}
      </MenuGroup>
      <CreateTagForm colors={avaliableColors} newTag={newTag} setNewTag={setNewTag} setTagList={setTagList} />
    </Menu>
  );
}
