import { getAccounts } from '../services/api/instant-messenger/service/getAccounts';
import { useQuery } from '@tanstack/react-query';
import { useAccountStore } from '../state';
import { Account } from '../types/interfaces';
import { NO_ACCOUNT_SET } from '@/shared/constants';

export const useFetchAccounts = ({ enabled }: { enabled: boolean }) => {
  const { account, setAccount } = useAccountStore();

  return useQuery({
    queryKey: ['accounts'],
    queryFn: ({ signal }) => getAccounts({ signal }),
    enabled,
    select(data) {
      const noAccount: Account = { id: NO_ACCOUNT_SET, type: NO_ACCOUNT_SET, copilots: [] };

      if (!account) {
        setAccount(data?.data?.[0] ?? noAccount);
      }

      return data.data[0];
    },
  });
};
