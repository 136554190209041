import { CrossHexagonFillIcon, InformationCircleFillIcon } from '@grupoboticario/flora-react-icons'

import { Alert } from './not-found-state.styles'

import { useTranslation } from '@shared/i18n'

interface NotFoundStateProps {
  text?: string
  status?: 'info' | 'error'
}

enum NotFoundStatus {
  info = '$statusInfoNonInteractiveEmphasis',
  error = '$statusErrorNonInteractiveEmphasis',
}

const NotFoundState = ({ text, status = 'info' }: NotFoundStateProps): JSX.Element => {
  const { t } = useTranslation('notFountState')

  const iconProps = {
    size: '24px',
    color: NotFoundStatus[status],
  }

  const renderInfoIcon = status === 'info' && <InformationCircleFillIcon {...iconProps} />
  const renderErrorIcon = status === 'error' && <CrossHexagonFillIcon {...iconProps} />

  return (
    <Alert status={status}>
      {renderInfoIcon}
      {renderErrorIcon}

      <p>{text ?? t('defaultMessage')}</p>
    </Alert>
  )
}

export { NotFoundState, NotFoundStateProps }
