import { useCallback, useEffect, useRef, useState } from 'react'

import { HttpResult, QueryParams, type IHttpResult } from '@grupoboticario/vdi-mfe-utils'

import { env } from '@shared/env'
import { useIsMounted } from '@shared/hooks'

import { useApi } from './api.provider'
import { MetaState, type API, type ApiParams, type ApiResError } from './api.types'

interface UseApiDataInput {
  dataSource: keyof API
  fetchOnMount?: boolean
  getApiParams?: (() => Promise<ApiParams>) | (() => ApiParams)
  pollingOnError?: boolean
}

type UseApiDataOutput<TData> = UseApiDataFilled<TData> | UseApiDataUnfilled

interface UseApiDataFilled<TData> extends UseApiDataCommon<TData> {
  filled: true
  data: TData
}

interface UseApiDataUnfilled extends UseApiDataCommon {
  filled: false
  data: undefined
}

interface UseApiDataCommon<TData = undefined> extends AsyncState {
  refetch: () => void
  getData: (params?: QueryParams) => Promise<IHttpResult<TData, ApiResError>>
}

interface State<TData> {
  data?: TData
  status: MetaState
}

const useApiData = <TData>({
  fetchOnMount = true,
  getApiParams,
  dataSource,
  pollingOnError,
}: UseApiDataInput): UseApiDataOutput<TData> => {
  const [stateResult, setStateResult] = useState<State<TData>>({ status: MetaState.IDLE })
  const timeout = useRef<NodeJS.Timeout | null>(null)
  const state = useAsyncState(stateResult.status)
  const isMounted = useIsMounted()
  const api = useApi()

  const getApiResult = useCallback(
    async (apiParams?: QueryParams) => {
      setStateResult((r) => ({ ...r, status: MetaState.LOADING }))
      const params = await getApiParams?.()
      const result = await api[dataSource]<TData>({ ...params, ...apiParams })
      if (!isMounted()) return HttpResult.ok({} as unknown as TData, 200)
      return result
    },
    [getApiParams, isMounted, api, dataSource],
  )

  const getApiData = useCallback(
    async (params?: QueryParams) => {
      if (timeout.current) clearTimeout(timeout.current)
      const result = await getApiResult(params)

      if (result.ok) {
        setStateResult({ data: result.value, status: MetaState.FILLED })
      } else {
        if (result.code === 404) {
          setStateResult((r) => ({ ...r, status: MetaState.NOT_FOUND }))
        } else if (pollingOnError && result.code === 503) {
          timeout.current = setTimeout(getApiData, env.ERROR_POLLING_TIMEOUT)
          setStateResult((r) => ({ ...r, status: MetaState.UNAVAILABLE }))
        } else {
          setStateResult((r) => ({ ...r, status: MetaState.ERROR }))
        }
      }

      return result
    },
    [getApiResult, pollingOnError],
  )

  if (fetchOnMount) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      void getApiData()
    }, [getApiData])
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return {
    refetch: getApiData,
    getData: getApiData,
    data: stateResult.data,
    ...state,
  }
}

interface AsyncState {
  filled: boolean
  error: boolean
  loading: boolean
  idle: boolean
  unavailable: boolean
  notFound: boolean
}

const useAsyncState = (state: MetaState): AsyncState => ({
  error: state === MetaState.ERROR,
  filled: state === MetaState.FILLED,
  loading: state === MetaState.LOADING,
  idle: state === MetaState.IDLE,
  unavailable: state === MetaState.UNAVAILABLE,
  notFound: state === MetaState.NOT_FOUND,
})

export { useApiData, useAsyncState, type AsyncState, type UseApiDataOutput }
