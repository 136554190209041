import {
  HandshakeIcon,
  FinanceModeIcon,
  DollarSignArrowIcon,
  ChatBubbleIcon,
  ThreePeopleIcon,
} from "@grupoboticario/flora-react-icons";

import { type MenuOptions } from "./types";

export const options: MenuOptions = [
  {
    features: [
      "view-attendance",
      "create-checklist",
      "view-logs",
      "view-mailing",
      "can-segmentation-base",
    ],
    label: "Inícios",
    icon: <HandshakeIcon />,
    url: "/inicios/",
    options: [
      {
        label: "Segmentação de base - IRs",
        url: "/inicios/mailing/atendente/base-de-irs",
      },
      {
        label: "Distribuição de base - IRs",
        url: "/inicios/mailing/control/distribuicao-irs",
      },
      {
        label: "Atendimento",
        url: "/inicios/atendimento",
      },
      {
        label: "Centrais de serviço",
        url: "/inicios/centrais-de-servico",
      },
      {
        label: "Histórico de alteracões",
        url: "/inicios/logs",
      },
    ],
  },
  {
    features: ["can-view-segmentation", "read-all-res"],
    label: "Ativação",
    icon: <DollarSignArrowIcon />,
    url: "/ativacao/",
    options: [
      {
        label: "Atendimento",
        url: "/ativacao/atendimento",
      },
      {
        label: "Segmentação de base",
        url: "/ativacao/segmentacao",
      },
    ],
  },
  {
    features: [
      "gr-dashboards-activity",
      "gr-dashboards-attendance",
      "gr-dashboards-base-profile",
      "gr-dashboards-base-profile",
      "gr-dashboards-full",
      "gr-dashboards-overview",
      "gr-dashboards-revenue",
    ],
    label: "Resultados",
    icon: <FinanceModeIcon />,
    url: "/dashboards/",
    options: [
      {
        label: "Central de Ativação",
        options: [
          {
            label: "Visão Geral",
            url: "/dashboards/visao-geral",
          },
          {
            label: "Receita",
            url: "/dashboards/receita",
          },
          {
            label: "Perfil de Base",
            url: "/dashboards/perfil-de-base",
          },
          {
            label: "Atividade",
            url: "/dashboards/atividade",
          },
          {
            label: "Relacionamento",
            url: "/dashboards/relacionamento",
          },
        ],
      },
      {
        label: "Ajuda e documentação",
        options: [
          {
            label: "Memória de cálculo",
            url: "/dashboards/memoria-de-calculo",
          },
        ],
      },
    ],
  },
  {
    features: ["view-chat"],
    label: "Conversas",
    icon: <ChatBubbleIcon />,
    url: "/chat/",
  },
  {
    features: ["view-people-management"],
    label: "Gestão de Pessoas",
    icon: <ThreePeopleIcon />,
    url: "/admin/",
    options: [
      {
        label: "Pessoas",
        url: "/admin/usuarios",
      },
      {
        label: "Equipes",
        url: "/admin/equipes",
      },
    ],
  },
];
