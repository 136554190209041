import { Text, Flex } from "@grupoboticario/flora-react";
import { ArrowLeftIcon } from "@grupoboticario/flora-react-icons";
import { TeamInfo } from "@features/TeamInfo";
import { Link } from "react-router-dom";
import { PageTitle, TeamContainer } from "./Team.styles";
import { TeamMembers } from "@features/TeamMembers";

export function Team() {
  return (
    <Flex direction={"column"} css={{ padding: "$6", height: "100%" }}>
      <PageTitle>
        <Link to="/equipes">
          <ArrowLeftIcon color="$nonInteractivePredominant" />
        </Link>
        <Text>Detalhes da equipe</Text>
      </PageTitle>
      <TeamContainer>
        <TeamInfo />
        <TeamMembers />
      </TeamContainer>
    </Flex>
  );
}
