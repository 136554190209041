import {
  businessHoursEvents,
  createChecklistEvents,
  deliveryFormEvents,
  documentationEvents,
  franchiseEvents,
  lockAndLimitEvents,
  orderIncrementEvents,
  paymentMethodsEvents,
  pickUpStoreEvents,
} from '../events';

const useEvents = () => {
  return {
    ...businessHoursEvents(),
    ...createChecklistEvents(),
    ...deliveryFormEvents(),
    ...documentationEvents(),
    ...franchiseEvents(),
    ...lockAndLimitEvents(),
    ...orderIncrementEvents(),
    ...paymentMethodsEvents(),
    ...pickUpStoreEvents(),
  };
};

export { useEvents };
