import { saveAndContinueAction, toggleInteraction, trackerFn } from '../functions';
import { EventName } from '../types';

const event_name = EventName.INTERACTION_CENTRAIS_SERVICO_BLOQUEIO_E_LIMITE;

export enum LockAndLimitInteraction {
  hasBlockAfterFirstOrder = 'bloquear-cadastro-apos-primeiro-pedido',
  unlockAfterPayingFirstOrder = 'desbloquear-cadastro-apos-pagamento-primeiro-pedido',
  allowToInformAvailableLimit = 'permite-informar-limite-credito-disponivel',
}

export const lockAndLimitEvents = () => {
  return {
    togglePickUpStoreEvents: ({ isEnable, value }: { isEnable: boolean; value: string }) => {
      trackerFn({
        event_name,
        cd_interaction_detail: toggleInteraction(isEnable, value),
      });
    },
    lockAndLimitFormEventsSaveAndContinue: () => saveAndContinueAction({ event_name }),
  };
};
