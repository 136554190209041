import { GroupCheckbox } from '@/components/shared';
import { useEvents } from '@/event-tracker';
import { PaymentLocationInfo, paymentLocationValueLabelMap, PaymentMethodsFormData } from '@/types';
import { Flex } from '@grupoboticario/flora-react';

interface CheckboxData {
  label: string;
  id: string;
  value: PaymentLocationInfo;
}

const checkboxes: CheckboxData[] = [
  {
    label: paymentLocationValueLabelMap[PaymentLocationInfo.IN_PERSON],
    id: 'pagamento-presencial',
    value: PaymentLocationInfo.IN_PERSON,
  },
  {
    label: paymentLocationValueLabelMap[PaymentLocationInfo.ON_DELIVERY],
    id: 'pagamento-entrega',
    value: PaymentLocationInfo.ON_DELIVERY,
  },
];

interface PaymentLocationsProps {
  trackerEventName?: string;
  parentRegisterName: keyof Pick<PaymentMethodsFormData, 'cashPayment' | 'creditCardPayment' | 'debitCardPayment'>;
}

const PaymentLocations = ({ parentRegisterName, trackerEventName }: PaymentLocationsProps) => {
  const eventTracker = useEvents();
  return (
    <Flex direction="column" gap="$4">
      <GroupCheckbox
        checkboxes={checkboxes}
        registerName={`${parentRegisterName}.paymentLocations`}
        checkboxesEventTracker={(value, isEnable) => {
          eventTracker.togglePaymentMethodsEvents({
            isEnable,
            value: trackerEventName ? `${trackerEventName}-${value}` : value,
          });
        }}
      />
    </Flex>
  );
};

export { PaymentLocations };
