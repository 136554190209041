import { CSS, Flex, Spinner, Text } from '@grupoboticario/flora-react';
import React from 'react';

type LoadingSizeType = 'lg' | 'sm' | 'xl' | 'xs' | 'xxs' | 'md' | undefined;
interface LoadingProps {
  isLoading?: boolean;
  message?: string;
  subtitle?: string;
  size?: LoadingSizeType;
  thickness?: string;
  containerCss?: CSS;
  spinnerCss?: CSS;
}

const Loading = ({
  isLoading = true,
  message,
  subtitle,
  size = 'md',
  thickness,
  containerCss = {},
  spinnerCss = {},
}: LoadingProps): React.ReactElement | null => {
  if (isLoading) {
    return (
      <Flex
        align='center'
        justify='center'
        direction='column'
        data-testid='loading'
        css={{
          height: '100%',
          ...containerCss,
        }}
      >
        <Spinner size={size} thickness={thickness} css={{ color: '$linkDefault', ...spinnerCss }} />
        {message && <Text css={{ marginTop: '$6', fontSize: '$2xl' }}>{message}</Text>}
        {subtitle && (
          <Text size='bodyMediumShort' css={{ marginTop: '$4', fontSize: '$lg' }}>
            {subtitle}
          </Text>
        )}
      </Flex>
    );
  }

  return null;
};

export { Loading };
