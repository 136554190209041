import { ServiceCenterOrderIncrement } from '@/components/reseller-details';
import { Flex } from '@grupoboticario/flora-react';

const ServiceCenterProductIncrement = (): JSX.Element => (
  <Flex direction="column" gap="$6" data-testid="product-increment-tab">
    <ServiceCenterOrderIncrement />
  </Flex>
);

export { ServiceCenterProductIncrement };
