import { events, useEventTracker } from '@/api';
import { getPhoneNumberByType, getResellerEmail } from '@/components/reseller-details/utils';
import { DataWrapper, TextInfo } from '@/components/shared';
import { EditResellerContact, EditResellerDrawerFooter } from '@/components/reseller-details';
import { useResellerDetails } from '@/contexts';
import { ResellerPhoneType } from '@/types';
import { cleanupSpecialCharacters, formatPhoneNumber } from '@/utils';

// ver memo
const ResellerContact = (): JSX.Element => {
  const { data } = useResellerDetails().resellerRegistry;
  const mobilePhoneNumber = getPhoneNumberByType(ResellerPhoneType.MOBILE, data?.phones);

  const { clickedResellerInfoButtons } = useEventTracker();

  const attendanceInfoData = {
    geraId: data?.geraId,
    socialName: data?.socialName,
    cpCs: data?.commercialStructure?.parentStructure.name,
  };

  const email = getResellerEmail(data?.emails)?.toLocaleLowerCase();

  const resellerInitialContactData = {
    phones: data?.phones ?? [],
    emails: data?.emails ?? [],
  };

  const openEditResellerPhoneAndEmail = () => {
    clickedResellerInfoButtons({
      section: 'alterar-dados-contato',
    });

    events.emit('OPEN_DRAWER_INFO', {
      size: 'medium',
      title: 'Altere os dados de contato:',
      content: (
        <EditResellerContact
          attendanceInfo={attendanceInfoData}
          resellerInitialContactData={resellerInitialContactData}
        />
      ),
      footer: <EditResellerDrawerFooter />,
    });
  };

  const handleEditResellerPhone = () => {
    openEditResellerPhoneAndEmail();
  };

  const handleEditResellerEmail = () => {
    openEditResellerPhoneAndEmail();
  };

  return (
    <DataWrapper title="Contato" data-testid="reseller-contact">
      <TextInfo
        canCopyToClipboard
        isFullWidth
        textToCopy={cleanupSpecialCharacters(mobilePhoneNumber?.phone ?? '')}
        title="Celular"
        text={formatPhoneNumber(mobilePhoneNumber?.phone)}
        onEdit={handleEditResellerPhone}
      />
      <TextInfo canCopyToClipboard isFullWidth title="E-Mail" text={email} onEdit={handleEditResellerEmail} />
    </DataWrapper>
  );
};

export { ResellerContact };
