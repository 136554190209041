import { Grid, FloraButton, Container, FloraTypography } from '@grupoboticario/flora-react';

export function TagColorsContainer({
  colors,
  color,
  setColor,
}: Readonly<{
  colors: string[];
  color: string;
  setColor: any;
}>) {
  if (!color) {
    color = colors[0];
    setColor(colors[0]);
  }
  return (
    <>
      <FloraTypography fontSize="bodySmallStandard" fontWeight="medium" css={{ marginTop: '$7' }}>
        Selecione uma cor:
      </FloraTypography>
      <Grid
        css={{
          backgroundColor: '$backgroundPrimary',
          padding: '$10 $10',
        }}
        direction="row"
        gap="$4"
        templateColumns="repeat(4, 1fr)"
        aria-label="Cores disponíveis"
      >
        {colors?.map(c => (
          <Container key={c} centerContent>
            <FloraButton
              css={{
                backgroundColor: c,
                borderRadius: '50%',
                height: '$sizes$10',
                width: '$sizes$10',
                border: c === color ? '3px solid #ccc' : 'none',
                opacity: c === color ? '1 !important' : '0.8',
                '&:hover': {
                  backgroundColor: c,
                  opacity: '1 !important',
                },
              }}
              onClick={() => setColor(c)}
            />
          </Container>
        ))}
      </Grid>
      {colors.length === 0 && <p>Ops! Você já está usando todas as cores disponíveis.</p>}
    </>
  );
}
