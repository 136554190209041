const mockList = [
  {
    name: 'O Boticário',
    slug: 'BOT',
    description:
      'Nativa SPA Cabelos das linhas  Karité, Rose, Quinoa, Caviar, Ameixa, Castanha e Jasmin com 25% de lucro extra progressivo ',
    classificationName: 'CRM',
    classificationId: 2,
    campaignId: '8787',
    isMultiOrganization: false,
  },
  {
    name: 'O.U.i Paris',
    slug: 'OUI',
    description:
      'Nativa SPA Cabelos das linhas  Karité, Rose, Quinoa, Caviar e Jasmin com 25% de lucro extra progressivo ',
    classificationName: 'CRM',
    classificationId: 2,
    campaignId: '8786',
    isMultiOrganization: false,
  },
  {
    name: 'Quem disse, Berenice?',
    slug: 'QDB',
    description:
      'Nativa SPA Cabelos das linhas  Karité, Rose, Quinoa, Caviar e Jasmin com 25% de lucro extra progressivo ',
    classificationName: 'CRM',
    classificationId: 1,
    campaignId: '876',
    isMultiOrganization: false,
  },
  {
    name: 'Eudora',
    slug: 'EUD',
    description:
      'Nativa SPA Cabelos das linhas  Karité, Rose, Quinoa, Caviar e Jasmin com 25% de lucro extra progressivo ',
    classificationName: 'CRM',
    classificationId: 1,
    campaignId: '8996',
    isMultiOrganization: false,
  },
  {
    name: 'O Boticário',
    slug: 'BOT',
    description:
      'Nativa SPA Cabelos das linhas  Karité, Rose, Quinoa, Caviar e Jasmin com 25% de lucro extra progressivo ',
    classificationName: 'CRM',
    classificationId: 1,
    campaignId: '89433',
    isMultiOrganization: true,
  },
]

export { mockList }
