import { authService } from "@vdi/auth-service";

export async function fetchAccount(userId: string) {
  const basUrlWithoutAdminPrefix = process.env.API_BASE_URL.split("/admin")[0];

  const { token } = await authService.getTokenOrRedirect();
  const url = `${basUrlWithoutAdminPrefix}/accounts/users/${userId}`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(`request response status error: ${response.status}`);
  }
  return response.json();
}
