import { FormFieldset, InputNote } from '@/components/';
import { Flex } from '@grupoboticario/flora-react';
import { PaymentHeader, PaymentLocations } from '@/components/payment-methods-form/';
import { PaymentMethodsInteraction } from '@/event-tracker/events';

const DebitCardPayment = () => (
  <Flex direction="column">
    <PaymentHeader />
    <PaymentLocations
      parentRegisterName="debitCardPayment"
      trackerEventName={PaymentMethodsInteraction.debitCardPayment}
    />
    <FormFieldset>
      <InputNote registerName="debitCardPayment.note" id="debitCardPaymentNote" />
    </FormFieldset>
  </Flex>
);

export { DebitCardPayment };
