import { OrderIncrement, OrderIncrementFormData } from '@/types';

export const normalizeOrderIncrementFormData = (
  orderIncrement: OrderIncrement | undefined,
): OrderIncrementFormData | undefined => {
  if (!orderIncrement) {
    return undefined;
  }

  return {
    ...orderIncrement,
  };
};
