import { TextInfo } from '@/components';

interface NoteFieldProps {
  text: string;
  title?: string;
}

export const NoteField = ({ text, title = 'Observações' }: NoteFieldProps) => {
  return <TextInfo isFullWidth title={title} text={text} wrapperCss={{ whiteSpace: 'pre-wrap' }} />;
};
