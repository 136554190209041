import { useServiceCenterFormActions } from '@/actions';
import { getServiceCenterFormInitialState, serviceCenterFormReducer } from '@/reducer';
import {
  RegistrationFormData,
  BusinessHoursFormData,
  OrderIncrementFormData,
  PickUpStoreFormData,
  DeliveryFormData,
  LockAndLimitFormData,
  PaymentMethodsFormData,
  ServiceCenterFormState,
  DocumentationFormData,
} from '@/types';
import React, { useMemo, useReducer } from 'react';

import { useLocation } from 'react-router-dom';

interface ServiceCenterFormProviderProps {
  initialState?: ServiceCenterFormState;
}

export interface ServiceCenterFormValue {
  state: ServiceCenterFormState;
  goToStep: (stepNumber: number) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  setRegistrationData: (id: string, payload: RegistrationFormData | undefined) => void;
  setBusinessHours: (payload: BusinessHoursFormData | undefined) => void;
  setDocumentationData: (payload: DocumentationFormData | undefined) => void;
  setOrderIncrement: (payload: OrderIncrementFormData | undefined) => void;
  setPickUpStore: (payload: PickUpStoreFormData | undefined) => void;
  setDelivery: (payload: DeliveryFormData | undefined) => void;
  setLockAndLimit: (payload: LockAndLimitFormData | undefined) => void;
  setPaymentMethods: (payload: PaymentMethodsFormData | undefined) => void;
  isEditMode: boolean;
}

const ServiceCenterFormContext = React.createContext<ServiceCenterFormValue | undefined>(undefined);

const ServiceCenterFormProvider = ({
  children,
  initialState,
}: React.PropsWithChildren<ServiceCenterFormProviderProps>) => {
  const location = useLocation();

  const [serviceCenterFormState, dispatch] = useReducer(
    serviceCenterFormReducer,
    initialState ?? getServiceCenterFormInitialState(),
  );

  const actions = useServiceCenterFormActions(dispatch);

  const contextValue: ServiceCenterFormValue = useMemo(
    () => ({
      state: serviceCenterFormState,
      isEditMode: location.pathname.includes('editar'),
      ...actions,
    }),
    [serviceCenterFormState, actions, location],
  );

  return <ServiceCenterFormContext.Provider value={contextValue}>{children}</ServiceCenterFormContext.Provider>;
};

const useServiceCenterForm = (): ServiceCenterFormValue => {
  const context = React.useContext(ServiceCenterFormContext);

  if (context === undefined) {
    throw new Error('useServiceCenterForm deve ser utilizado dentro de um ServiceCenterFormContext');
  }

  return context;
};

export { ServiceCenterFormProvider, useServiceCenterForm, ServiceCenterFormContext };
