import { getChangeHistory } from '@/api';
import { ChangeHistoryData } from '@/types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface ChangeHistoryState {
  data: ChangeHistoryData[];
  page: number;
  limit: number;
  total: number | undefined;
  filter: string | undefined;
  hasError?: boolean;
  fetchByAll: () => void;
  isLoading: boolean;
  fetchByPage: (page: number) => void;
  fetchByCs: (cs?: string | undefined) => void;
}

const errorState = {
  isLoading: false,
  hasError: true,
};

export const useChangeHistoryStore = create<ChangeHistoryState>()(
  devtools((set, get) => ({
    data: [] as ChangeHistoryData[],
    page: 0,
    limit: 5,
    total: null as number,
    isLoading: true,
    filter: null as string,
    hasError: false,
    fetchByAll: async () => {
      try {
        const response = await getChangeHistory({
          limit: get().limit,
          page: 1,
        });
        set({
          data: response.data,
          isLoading: false,
          total: response.totalDocs,
          hasError: false,
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        set({
          ...errorState,
        });
      }
    },

    fetchByPage: async (page: number) => {
      try {
        const response = await getChangeHistory({
          page: page + 1,
          limit: get().limit,
          filter: get().filter ?? '',
        });
        set({
          data: response.data,
          page: page || 0,
          total: response.totalDocs,
          hasError: false,
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        set({
          ...errorState,
        });
      }
    },
    fetchByCs: async (filter?: string | undefined) => {
      try {
        const response = await getChangeHistory({
          limit: get().limit,
          filter: filter,
          page: 1,
        });
        set({
          data: response.data,
          total: response.totalDocs,
          filter: filter,
          page: 0,
          hasError: false,
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        set({
          ...errorState,
        });
      }
    },
  }))
);
