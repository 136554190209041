import { DayOfTheWeek, FederativeUnit, ServiceCenter } from '@/types';
export const serviceCenterDetail: ServiceCenter = {
  _id: '62d5bc49a19c537bc1bf017c',
  documentation: {
    documentationNote: 'RG',
    hasAdditionalDocumentation: true,
  },
  franchise: {
    contacts: {
      phones: [
        {
          phone: '12312313213',
          primary: true,
          whatsapp: true,
        },
        {
          phone: '23243423423',
          primary: false,
          whatsapp: true,
        },
        {
          phone: '12121212121',
          primary: false,
          whatsapp: false,
        },
        {
          phone: '32232131231',
          primary: false,
          whatsapp: false,
        },
        {
          phone: '43243242342',
          primary: false,
          whatsapp: true,
        },
      ],
      email: 'person@email.com',
    },
    cpCode: '82810630',
    address: {
      city: 'Curitiba',
      csCode: '13768',
      referencePoint: '',
      number: '321',
      landmark: 'capao da imbuia',
      state: FederativeUnit.PR,
      street: 'Rua Silva Rocha',
      zipCode: '80080390',
      complement: '',
    },
    name: 'Pessoa Silva Brito',
    owner: 'Silva Brito',
  },
  businessHours: {
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris metus massa, ullamcorper vel luctus non.',
    days: [
      {
        closed: false,
        hours: [
          {
            opensAt: '08:00',
            closesAt: '12:00',
          },
          {
            opensAt: '13:00',
            closesAt: '20:00',
          },
        ],
        day: [DayOfTheWeek.MONDAY, DayOfTheWeek.THURSDAY, DayOfTheWeek.TUESDAY, DayOfTheWeek.FRIDAY],
      },
      {
        closed: true,
        hours: [],
        day: [DayOfTheWeek.WEDNESDAY, DayOfTheWeek.SATURDAY, DayOfTheWeek.SUNDAY],
      },
    ],
    holidays: {
      openOnHolidays: true,
      hours: [
        {
          opensAt: '08:00',
          closesAt: '12:00',
        },
        {
          opensAt: '13:00',
          closesAt: '20:00',
        },
      ],
    },
  },
  orderIncrement: {
    hasOrderProductConditions: true,
    hasCategories: false,
  },
  pickUpStore: {
    allowPickUpStore: false,
    hasIdentificationRequirements: false,
    hasProofOfAddress: false,
    hasScheduleForPickUp: false,
    hasThirdPartySupport: false,
    hasMinimumPickUpPeriod: false,
    hasMaximumPickUpPeriod: false,
  },
  delivery: {
    hasDelivery: false,
    hasFreeDelivery: false,
    hasFreeDeliveryOnFirstOrder: false,
    hasSpecialConditions: false,
    hasFreeDeliveryDuringPandemic: false,
    hasNotAllowedDeliveryRegions: false,
    allowDeliveryToThirdParties: false,
  },
  lockAndLimit: {
    hasBlockAfterFirstOrder: false,
    allowToInformAvailableLimit: false,
  },
  paymentMethods: {
    bankSlipPayment: {
      isEnabled: false,
      hasInstallmentOptions: false,
      hasMinimumInstallmentValue: false,
    },
    cashPayment: {
      isEnabled: false,
    },
    creditCardPayment: {
      isEnabled: false,
      hasInstallmentOptions: false,
      hasMinimumInstallmentValue: false,
    },
    debitCardPayment: {
      isEnabled: false,
    },
    depositAndTransferPayment: {
      isEnabled: false,
      requestsPaymentVoucher: false,
    },
    onlineCardPayment: {
      isEnabled: true,
    },
    pixPayment: {
      isEnabled: false,
      requestsPaymentVoucher: false,
    },
  },
  region: 'Sul',
  status: 'done',
  createdAt: '2022-07-18T20:02:17.335Z',
  updatedAt: '2022-07-18T20:02:17.335Z',
};
