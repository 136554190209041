import { FloraTypography as Typography, styled } from '@grupoboticario/flora-react'

const ContainerSKU = styled(Typography, {
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
  color: '$nonInteractivePredominant',
  fontSize: '$auxiliar !important',
  fontWeight: '$regular',
  lineHeight: '$short !important',
})

const ProductContainer = styled('li', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$1',
  backgroundColor: '$backgroundPrimary',
  borderRadius: '$medium',
  padding: '$4',
})

const ProductsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
})

const ProductsList = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
})

const Title = styled(Typography, {
  position: 'relative',
  color: '$nonInteractiveAuxiliar',
  fontSize: '$exceptionsRestricted',
  fontWeight: '$regular',
  lineHeight: '$base',
  textAlign: 'center',
  textTransform: 'uppercase',

  '&::before, &::after': {
    content: '',
    position: 'absolute',
    top: 'calc(50% - 1px / 2)',
    width: 'calc(50% - 100px)',
    height: '1px',
    backgroundColor: '$nonInteractiveAuxiliar',

    '@tablet': {
      width: '95px',
    },
  },

  '&::before': {
    left: 0,
  },

  '&::after': {
    right: 0,
  },
})

export { ContainerSKU, ProductContainer, ProductsContainer, ProductsList, Title }
