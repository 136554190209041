import { Flex, styled } from '@grupoboticario/flora-react'

const Alert = styled(Flex, {
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '$3',
  width: '100%',
  minHeight: '$14',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '$medium',
  padding: '$3',
  marginTop: '$3',

  variants: {
    status: {
      info: {
        backgroundColor: '$statusInfoBackgroundDefault',
        borderColor: '$statusInfoNonInteractiveEmphasis',
      },
      error: {
        backgroundColor: '$statusErrorBackgroundDefault',
        borderColor: '$statusErrorNonInteractiveEmphasis',
      },
    },
  },

  '> p': {
    color: '$statusInfoNonInteractiveEmphasis',
    fontSize: '$bodyLarge',
    fontWeight: '$medium',
    lineHeight: '$base',
  },
})

export { Alert }
