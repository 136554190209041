import { DashboardGrid, DashboardSectionTitle, IndicatorCard } from '@/shared/components';
import { useGetDashboardData } from '@/shared/hooks';
import { gridSettings, revenueGridSettings } from './grid-settings';
import { percent, setDisplayedValue, decimalNoFraction, currencyNoFraction } from '@/shared/format';
import {
  ArrowDownIcon,
  CheckSingleIcon,
  CheckDoubleIcon,
  CrossCircleIcon,
  SmartphoneStrokeIcon,
  StoreIcon,
  SquarePlusMinusIcon,
  DollarSignCircleIcon,
  LineChartIcon,
  TwoPeopleIcon,
} from '@grupoboticario/flora-react-icons';
export function OverviewDashboard() {
  const { data, isLoading } = useGetDashboardData({ queryKey: 'overview', path: '/overview/dashboard' });

  return (
    <>
      <DashboardSectionTitle title="Saúde de base e atividade" subtitle="" />
      <DashboardGrid css={gridSettings}>
        <IndicatorCard
          title="Base total"
          tooltip="Quantidade de revendedores que fizeram, pelo menos, um pedido em até 6 ciclos, incluindo Inícios e Reinícios."
          mainValue={setDisplayedValue(data?.activity?.totalBase?.value, decimalNoFraction)}
          icon={<TwoPeopleIcon />}
          isLoading={isLoading}
          gridArea="total-base"
        />
        <IndicatorCard
          title="Ativos da base total"
          tooltip="Quantidade de revendedores que possuem pedido no ciclo corrente, considerando inícios e reinícios."
          mainValue={setDisplayedValue(data?.activity?.totalBaseActives?.value, decimalNoFraction)}
          icon={<CheckSingleIcon />}
          isLoading={isLoading}
          gridArea="total-base-actives"
        />
        <IndicatorCard
          title="Atividade da base total"
          tooltip="Percentual de revendores únicos da Base Total que fizeram pedido no ciclo corrente, considerando Inícios e Reinícios."
          mainValue={setDisplayedValue(data?.activity?.totalBaseActivity?.percentage, percent)}
          icon={<CheckDoubleIcon />}
          isLoading={isLoading}
          gridArea="total-base-activity"
        />
        <IndicatorCard
          title="Base ativa"
          tooltip="Quantidade de revendedores que fizeram pedido em até 6 ciclos, excluindo os Inicios e Reinícios."
          mainValue={setDisplayedValue(data?.activity?.activeBase?.value, decimalNoFraction)}
          icon={<TwoPeopleIcon />}
          isLoading={isLoading}
          gridArea="active-base"
        />
        <IndicatorCard
          title="Ativos da base ativa"
          tooltip="Quantidade de revendedores que possuem pedido no ciclo corrente, desconsiderando Inícios e Reinícios."
          mainValue={setDisplayedValue(data?.activity?.activeBaseActives?.value, decimalNoFraction)}
          icon={<CheckSingleIcon />}
          isLoading={isLoading}
          gridArea="active-base-actives"
        />
        <IndicatorCard
          title="Atividade da base ativa"
          tooltip="Percentual de revendores únicos da Base Ativa que fizeram pedido no ciclo corrente, desconsiderando Inícios e Reinícios."
          mainValue={setDisplayedValue(data?.activity?.activeBaseActivity?.percentage, percent)}
          icon={<CheckDoubleIcon />}
          isLoading={isLoading}
          gridArea="active-base-activity"
        />
        <IndicatorCard
          title="Base multimarca"
          tooltip="Quantidade de revendedores que fizeram, pelo menos, um pedido com itens de mais de uma marca, em até 6 ciclos, excluindo os Inicios e Reinícios."
          mainValue={setDisplayedValue(data?.activity?.multiBrandBase?.value, decimalNoFraction)}
          icon={<TwoPeopleIcon />}
          isLoading={isLoading}
          gridArea="multi-brand-base"
        />
        <IndicatorCard
          title="Ativas multimarcas"
          tooltip="Quantidade de revendedores que tiveram pedido com itens de mais de uma marca."
          mainValue={setDisplayedValue(data?.activity?.multiBrandActives?.value, decimalNoFraction)}
          icon={<CheckSingleIcon />}
          isLoading={isLoading}
          gridArea="multi-brand-actives"
        />
        <IndicatorCard
          title="Penetração nos canais digitais"
          tooltip="Percentual de revendedores ativos que tiveram pelo menos um pedido realizado nos canais digitais."
          mainValue={setDisplayedValue(data?.activity?.digitalChannelsPenetration?.percentage, percent)}
          icon={<SmartphoneStrokeIcon />}
          isLoading={isLoading}
          gridArea="digital-channel-penetration"
        />
        <IndicatorCard
          title="Penetração nos ER"
          tooltip="Percentual de revendedores ativos que tiveram pelo menos um pedido realizado no Espaço do Revendedor."
          mainValue={setDisplayedValue(data?.activity?.erChannelsPenetration?.percentage, percent)}
          icon={<StoreIcon />}
          isLoading={isLoading}
          gridArea="er-penetration"
        />
        <IndicatorCard
          title="Adições líquidas"
          tooltip="Aumento ou redução na quantidade de revendedores da Base Ativa."
          mainValue={setDisplayedValue(data?.activity?.netAdditions?.value, decimalNoFraction)}
          icon={<SquarePlusMinusIcon />}
          isLoading={isLoading}
          gridArea="net-additions"
        />
        <IndicatorCard
          title="Perda"
          tooltip="Quantidade de revendedores que sairão da base ativa no próximo ciclo, se não realizarem, pelo menos, um pedido no ciclo corrente."
          mainValue={setDisplayedValue(data?.activity?.loss?.value, decimalNoFraction)}
          icon={<CrossCircleIcon />}
          isLoading={isLoading}
          gridArea="loss"
        />
        <IndicatorCard
          title="Percentual de churn"
          tooltip="Percentual de revendedores que sairão da base ativa no próximo ciclo, se não realizarem, pelo menos, um pedido no ciclo corrente."
          mainValue={setDisplayedValue(data?.activity?.churn?.percentage, percent)}
          icon={<ArrowDownIcon />}
          isLoading={isLoading}
          gridArea="churn"
        />
      </DashboardGrid>
      <DashboardSectionTitle title="Receita" subtitle="" />
      <DashboardGrid css={revenueGridSettings}>
        <IndicatorCard
          title="GMV"
          tooltip="Valor total dos pedidos realizados pelos revendedores. O valor é composto pela Receita GMV."
          mainValue={setDisplayedValue(data?.revenue?.revenueGmv?.realized, currencyNoFraction)}
          icon={<DollarSignCircleIcon />}
          isLoading={isLoading}
          gridArea="revenue-gmv"
        />
        <IndicatorCard
          title="Receita bruta do varejo"
          tooltip="Valor total dos pedidos realizados pelos revendedores. O valor é composto pela Receita Bruta de Vendas (RBV)."
          mainValue={setDisplayedValue(data?.revenue?.revenueRbv?.realized, currencyNoFraction)}
          icon={<DollarSignCircleIcon />}
          isLoading={isLoading}
          gridArea="revenue-rbv"
        />
        <IndicatorCard
          title="Receita liquida"
          tooltip="Valor total pago pelos pedidos realizados pelos revendedores. O valor é composto pelo Preço de Venda ao Revendedor (PVR), desconsiderando valor de comissão e créditos residuais."
          mainValue={setDisplayedValue(data?.revenue?.revenuePvr?.realized, currencyNoFraction)}
          icon={<DollarSignCircleIcon />}
          isLoading={isLoading}
          gridArea="revenue-pvr"
        />
        <IndicatorCard
          title="Pedido médio"
          tooltip="Valor Médio da Receita GMV gerada por pedido válido."
          mainValue={setDisplayedValue(data?.revenue?.averageTicket?.average, currencyNoFraction)}
          auxiliarText={
            data?.revenue?.averageTicket?.totalOrders !== undefined
              ? `referente à ${decimalNoFraction.format(data?.revenue?.averageTicket?.totalOrders)} pedidos`
              : '-'
          }
          isLoading={isLoading}
          gridArea="average-order"
        />
        <IndicatorCard
          title="Unidades por ativo (UPA)"
          tooltip="Quantidade média de itens comprados por Revendedor ativo no ciclo."
          mainValue={setDisplayedValue(data?.revenue?.unitiesPerActive?.realized, decimalNoFraction)}
          isLoading={isLoading}
          gridArea="upa"
        />
        <IndicatorCard
          title="Reais por ativo"
          tooltip="Valor Médio da Receita GMV gerada por revendedor ativo."
          mainValue={setDisplayedValue(data?.revenue?.productivity?.realized, currencyNoFraction)}
          icon={<LineChartIcon />}
          auxiliarText={
            data?.revenue?.productivity.actives !== undefined
              ? `referente à ${decimalNoFraction.format(data?.revenue?.productivity.actives)} REs ativos`
              : '-'
          }
          isLoading={isLoading}
          gridArea="productivity"
        />
      </DashboardGrid>
    </>
  );
}
