import { Box, CSS } from '@grupoboticario/flora-react';

interface DividerProps {
  css?: CSS;
}

const Divider = ({ css }: DividerProps) => (
  <Box
    data-testid='divider-id'
    css={{
      height: '1px',
      width: '100%',
      background: '$nonInteractiveOutline',
      margin: '$2 0',
      ...css,
    }}
  />
);

export { Divider };
