import {
  normalizeBusinessHoursFormData,
  normalizeDeliveryFormData,
  normalizeDocumentationFormData,
  normalizeLockAndLimitFormData,
  normalizeOrderIncrementFormData,
  normalizePaymentMethodsFormData,
  normalizeRegistrationData,
} from '@/components/';
import { normalizePickUpStoreFormData } from '@/components/pick-up-store-form/utils';
import { ServiceCenter, ServiceCenterFormState } from '@/types';

export const serviceCenterToServiceCenterFormState = (
  cs: ServiceCenter | undefined,
): ServiceCenterFormState | undefined => {
  if (cs !== undefined) {
    return {
      currentStep: 1,
      formData: {
        businessHours: normalizeBusinessHoursFormData(cs.businessHours),
        delivery: normalizeDeliveryFormData(cs.delivery),
        documentation: normalizeDocumentationFormData(cs.documentation),
        lockAndLimit: normalizeLockAndLimitFormData(cs.lockAndLimit),
        orderIncrement: normalizeOrderIncrementFormData(cs.orderIncrement),
        paymentMethods: normalizePaymentMethodsFormData(cs.paymentMethods),
        pickUpInStore: normalizePickUpStoreFormData(cs.pickUpStore),
        registrationData: normalizeRegistrationData(cs.franchise),
        status: cs.status,
        id: cs._id,
      },
    };
  }
  return undefined;
};
