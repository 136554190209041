import { Flex, Text } from "@grupoboticario/flora-react";
import { PageTitle } from "../Team/Team.styles";
import { TeamForm } from "@features/TeamForm";
import { SearchDropdownUserProvider } from "@features/SearchDropdownUser";

export function NewTeam() {
  return (
    <Flex
      direction={"column"}
      gap={"$6"}
      css={{ padding: "$6", height: "100%" }}
    >
      <Flex direction={"column"} justify={"center"} align={"center"}>
        <PageTitle>
          <Text>Criar equipe</Text>
        </PageTitle>
      </Flex>
      <Flex direction={"column"} justify={"center"} align={"center"}>
        <SearchDropdownUserProvider>
          <TeamForm />
        </SearchDropdownUserProvider>
      </Flex>
    </Flex>
  );
}
