import { CSS, Flex, FloraTextarea, FloraTextareaProps } from '@grupoboticario/flora-react';
import { useFormContext } from 'react-hook-form';

interface InputNoteProps extends Omit<FloraTextareaProps, 'label'> {
  css?: CSS;
  label?: string;
  helperText?: string;
  registerName: string;
  disabled?: boolean;
  id: string;
}

const InputNote = ({
  label = 'Observação:',
  helperText = 'Escreva a observação',
  registerName,
  disabled = false,
  id,
  ...rest
}: InputNoteProps): JSX.Element => {
  const { register, formState, getFieldState } = useFormContext();
  const fieldState = getFieldState(registerName, formState);

  return (
    <Flex direction="column">
      <FloraTextarea
        helperText={fieldState.invalid ? fieldState.error?.message : helperText}
        label={label}
        disabled={disabled}
        id={id}
        status={fieldState.invalid ? 'error' : 'neutral'}
        {...rest}
        {...register(registerName)}
      />
    </Flex>
  );
};

export { InputNote };
