import { DataWrapper, NoteField } from '@/components';
import { AllowedCategories, InstructionBox } from '@/components/reseller-details';
import { productCategoryValueLabelMap } from '@/components/reseller-details/labels';
import { useResellerServiceCenter } from '@/contexts';
import { TagProps } from '@grupoboticario/flora-react';
import { CheckCircleIcon, CrossCircleIcon } from '@grupoboticario/flora-react-icons';

const hasOrderProductConditions = {
  icon: <CheckCircleIcon css={{ color: '$8' }} size="32" />,
  title: 'Possui restrições ou obrigatoriedade de produtos específicos para pedido inicial.',
  variant: 'success',
};

const notOrderProductConditions = {
  icon: <CrossCircleIcon css={{ color: '$alert-standard' }} size="32" />,
  title: 'Não possui restrições ou obrigatoriedade de produtos específicos para pedido inicial.',
  variant: 'error',
};

const ServiceCenterOrderIncrement = () => {
  const { csData } = useResellerServiceCenter();

  const orderProductConditionsInstructions = csData?.orderIncrement?.hasOrderProductConditions
    ? hasOrderProductConditions
    : notOrderProductConditions;

  return (
    <>
      <DataWrapper>
        <InstructionBox
          title={orderProductConditionsInstructions.title}
          icon={orderProductConditionsInstructions.icon}
          tagVariant={orderProductConditionsInstructions.variant as TagProps['variant']}
          isFullWidth
        />

        {csData?.orderIncrement?.hasOrderProductConditions ? (
          <NoteField
            title="Restrições ou obrigatoriedade de produtos específicos para pedido inicial"
            text={csData?.orderIncrement?.orderProductConditions}
          />
        ) : null}
      </DataWrapper>
      <DataWrapper>
        <AllowedCategories
          tagsValue={
            csData?.orderIncrement?.hasCategories
              ? (csData.orderIncrement.categories ?? []).map(category => productCategoryValueLabelMap[category])
              : undefined
          }
        />
        {csData?.orderIncrement?.note && <NoteField text={csData?.orderIncrement?.note} />}
      </DataWrapper>
    </>
  );
};

export { ServiceCenterOrderIncrement };
