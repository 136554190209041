import { Box, Flex } from "@grupoboticario/flora-react";
import { Search } from "@shared/components/Search";

export function ActionBar() {
  return (
    <Flex justify={"space-between"} gap={"$4"} css={{ flexWrap: "wrap" }}>
      <Box css={{ width: "22.5rem" }}>
        <Search placeholder="buscar usuários" isLoading={false} />
      </Box>
    </Flex>
  );
}
