import { classificationLevelLabelMap, percentNoFraction } from '@/shared/format';
import { Flex } from '@grupoboticario/flora-react';
import { StyledPercentage } from '@/shared/components';

export function TableCellText({ value, format, isActivityDashboard, percentage, auxiliarText, props }) {
  const formattedPercent = percentNoFraction.format(percentage / 100);

  const shouldHidePercentage =
    (props.column.parent.id === 'togglableColumn' && isActivityDashboard) ||
    props.row.original.classification === 'Total';

  const displayedMainText = () => {
    if (value === undefined) return '-';
    if (isActivityDashboard && props.column.parent.id === 'togglableColumn') {
      return formattedPercent;
    }
    return format(value);
  };

  const displayedAuxiliarText = () => {
    if (!percentage || shouldHidePercentage) return;

    if (auxiliarText === 'novoRevendedor' || auxiliarText === 'indefinido') {
      return formattedPercent;
    }

    const isTotalColumn =
      props.column.id === 'base' ||
      props.column.id === 'activeBase' ||
      props.column.id === 'revenue' ||
      props.column.id === 'totalBase';

    if (isTotalColumn) {
      return `${formattedPercent} do total`;
    }

    return `${formattedPercent} dos ${classificationLevelLabelMap[auxiliarText] || auxiliarText}`;
  };

  return (
    <Flex css={{ textAlign: 'right' }} direction="column">
      {displayedMainText()}
      <StyledPercentage>{displayedAuxiliarText()}</StyledPercentage>
    </Flex>
  );
}
