import { InteractionDetail } from '../types';
import { trackerFn } from './tracker';

interface SaveAndContinueActionProps {
  event_name: string;
}

export const saveAndContinueAction = ({ event_name }: SaveAndContinueActionProps): void => {
  trackerFn({
    event_name,
    cd_interaction_detail: `${InteractionDetail.click}:salvar-continuar`,
  });
};
