import { AttendanceStatus } from '@/api/services/mailing';
import { AssistantModalOpenType, MailingData, MailingStoreAssistantStateProps } from '@/types';
import { Table } from '@tanstack/react-table';
import { StateCreator } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

const setAssistantGlobalFilter = (table: Table<MailingData>, filters: string[]) => {
  table?.setGlobalFilter(filters);
};

type TableStateType = Table<MailingData>;

export const assistantMailingSlice: StateCreator<
  MailingStoreAssistantStateProps,
  [['zustand/devtools', never]],
  [['zustand/persist', assistantFilter: { assistantFilter: string[] }]],
  MailingStoreAssistantStateProps
> = persist(
  (set) => ({
    assistantModalOpen: false,
    assistantFilterOpen: false,
    attendanceStatus: AttendanceStatus.IN_ATTENDANCE,
    tableState: undefined as TableStateType,
    assistantFilter: [] as string[],
    tableMailing: {
      tableState: undefined as TableStateType,
      setTableState: (table: TableStateType) => {
        set((state) => ({
          tableMailing: {
            ...state.tableMailing,
            tableState: table,
          },
        }));
      },
    },
    setAssistantModalOpen: (status: boolean, attendanceStatus: AssistantModalOpenType) => {
      set({ assistantModalOpen: status, attendanceStatus });
    },
    setAssistantFilterOpen: (status: boolean) => {
      set({ assistantFilterOpen: status });
    },
    setAssistantFilter: (filters: string[]) => {
      set((state) => {
        setAssistantGlobalFilter(state.tableMailing.tableState, filters);
        return { assistantFilter: filters };
      });
    },
  }),
  {
    name: 'assistant-filters',
    storage: createJSONStorage(() => sessionStorage),
    partialize: (state) => {
      setAssistantGlobalFilter(state.tableMailing.tableState, state.assistantFilter);

      return {
        assistantFilter: state.assistantFilter,
      };
    },
  }
);
